import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private showMapSubject = new BehaviorSubject<boolean>(false);
  showMap$ = this.showMapSubject.asObservable();

  constructor(private snackBar: MatSnackBar) {
    // do nothing
  }

  toggleToMap(value: boolean) {
    this.showMapSubject.next(value);
  }
}
