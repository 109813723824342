<div>
    <ul class="flex space-x-2 rtl:space-x-reverse">
        <li>
            <a href="javascript:;" class="text-primary hover:underline">Components</a>
        </li>
        <li class="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
            <span>Modals</span>
        </li>
    </ul>

    <div class="space-y-8 pt-5">
        <div class="panel flex items-center overflow-x-auto whitespace-nowrap p-3 text-primary">
            <div class="rounded-full bg-primary p-1.5 text-white ring-2 ring-primary/30 ltr:mr-3 rtl:ml-3">
                <icon-bell />
            </div>
            <span class="ltr:mr-3 rtl:ml-3">Documentation: </span>
            <a href="https://www.npmjs.com/package/ngx-custom-modal" target="_blank" class="block hover:underline">
                https://www.npmjs.com/package/ngx-custom-modal
            </a>
        </div>

        <div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
            <!-- basic -->
            <div class="panel">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Basic</h5>
                    <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code1')">
                        <span class="flex items-center">
                            <icon-code class="me-2" />
                            Code
                        </span>
                    </a>
                </div>

                <div class="mb-5">
                    <!-- Trigger -->
                    <div class="flex items-center justify-center">
                        <button type="button" class="btn btn-primary" (click)="modal1.open()">Launch modal</button>
                    </div>

                    <!-- Modal -->
                    <ngx-custom-modal #modal1 customClass="modal-top">
                        <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                        <ng-template #modalBody>
                            <p>
                                Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci varius
                                natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices sed urna ac
                                pulvinar. Ut sit amet ullamcorper mi.
                            </p>
                        </ng-template>
                        <ng-template #modalFooter>
                            <button type="button" (click)="modal1.close()" class="btn btn-outline-danger">Discard</button>
                            <button type="button" (click)="modal1.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                        </ng-template>
                    </ngx-custom-modal>
                </div>

            </div>

            <!-- Vertically Centered -->
            <div class="panel">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Vertically Centered</h5>
                    <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code2')">
                        <span class="flex items-center">
                            <icon-code class="me-2" />
                            Code
                        </span>
                    </a>
                </div>
                <div class="mb-5">
                    <!-- Trigger -->
                    <div class="flex items-center justify-center">
                        <button type="button" class="btn btn-info" (click)="modal2.open()">Launch modal</button>
                    </div>

                    <!-- Modal -->
                    <ngx-custom-modal #modal2>
                        <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                        <ng-template #modalBody>
                            <p>
                                Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci varius
                                natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices sed urna ac
                                pulvinar. Ut sit amet ullamcorper mi.
                            </p>
                        </ng-template>
                        <ng-template #modalFooter>
                            <button type="button" (click)="modal2.close()" class="btn btn-outline-danger">Discard</button>
                            <button type="button" (click)="modal2.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                        </ng-template>
                    </ngx-custom-modal>
                </div>
            </div>

            <!-- static -->
            <div class="panel">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Static</h5>
                    <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code3')">
                        <span class="flex items-center">
                            <icon-code class="me-2" />
                            Code
                        </span>
                    </a>
                </div>
                <div class="mb-5">
                    <!-- Trigger -->
                    <div class="flex items-center justify-center">
                        <button type="button" class="btn btn-secondary" (click)="modal3.open()">Static modal</button>
                    </div>

                    <!-- Modal -->
                    <ngx-custom-modal #modal3 customClass="modal-top" [closeOnOutsideClick]="false">
                        <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                        <ng-template #modalBody>
                            <p>
                                Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci varius
                                natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices sed urna ac
                                pulvinar. Ut sit amet ullamcorper mi.
                            </p>
                        </ng-template>
                        <ng-template #modalFooter>
                            <button type="button" (click)="modal3.close()" class="btn btn-outline-danger">Discard</button>
                            <button type="button" (click)="modal3.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                        </ng-template>
                    </ngx-custom-modal>
                </div>
            </div>

            <!-- Remove animation -->
            <div class="panel">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Remove animation</h5>
                    <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code4')">
                        <span class="flex items-center">
                            <icon-code class="me-2" />
                            Code
                        </span>
                    </a>
                </div>
                <div class="mb-5">
                    <!-- Trigger -->
                    <div class="flex items-center justify-center">
                        <button type="button" class="btn btn-success" (click)="modal4.open()">Launch modal</button>
                    </div>

                    <!-- Modal -->
                    <ngx-custom-modal #modal4 customClass="modal-top no-animation">
                        <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                        <ng-template #modalBody>
                            <p>
                                Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci varius
                                natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices sed urna ac
                                pulvinar. Ut sit amet ullamcorper mi.
                            </p>
                        </ng-template>
                        <ng-template #modalFooter>
                            <button type="button" (click)="modal4.close()" class="btn btn-outline-danger">Discard</button>
                            <button type="button" (click)="modal4.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                        </ng-template>
                    </ngx-custom-modal>
                </div>
            </div>

            <!-- Optional sizes -->
            <div class="panel">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Optional sizes</h5>
                    <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code5')">
                        <span class="flex items-center">
                            <icon-code class="me-2" />
                            Code
                        </span>
                    </a>
                </div>
                <div class="mb-5">
                    <div class="flex items-center justify-center gap-2">
                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-warning" (click)="modal5.open()">Extra large</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal5 customClass="modal-top extra-large-modal">
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal5.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal5.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-danger" (click)="modal6.open()">Large</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal6 customClass="modal-top large-modal">
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal6.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal6.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-secondary" (click)="modal7.open()">Small</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal7 customClass="modal-top small-modal">
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal7.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal7.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Video -->
            <div class="panel">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Video</h5>
                    <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code6')">
                        <span class="flex items-center">
                            <icon-code class="me-2" />
                            Code
                        </span>
                    </a>
                </div>

                <div class="mb-5">
                    <!-- Trigger -->
                    <div class="flex items-center justify-center">
                        <button type="button" class="btn btn-primary" (click)="modal8.open()">Play Youtube</button>
                    </div>

                    <!-- Modal -->
                    <ngx-custom-modal #modal8 customClass="modal-top video-modal">
                        <ng-template #modalBody>
                            <div class="pt-8">
                                <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY" class="h-[250px] w-full md:h-[550px]"></iframe>
                            </div>
                        </ng-template>
                    </ngx-custom-modal>
                </div>

            </div>

            <!-- Animation Style Modal -->
            <div class="panel lg:col-span-2">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Animation Style Modal</h5>
                    <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code7')">
                        <span class="flex items-center">
                            <icon-code class="me-2" />
                            Code
                        </span>
                    </a>
                </div>
                <div class="mb-5">
                    <div class="flex flex-wrap items-center justify-center gap-2">
                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-primary" (click)="modal9.open()">FadeIn</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal9 customClass="modal-top animate animate-fade-in">
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal9.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal9.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-info" (click)="modal10.open()">SlideIn Down</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal10 customClass="modal-top animate animate-slide-in-down">
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal10.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal10.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-success" (click)="modal11.open()">FadeIn Up</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal11 customClass="modal-top animate animate-fade-in-up">
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal11.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal11.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-warning" (click)="modal12.open()">SlideIn Up</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal12 customClass="modal-top animate animate-slide-in-up">
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal12.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal12.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-danger" (click)="modal13.open()">FadeIn Left</button>

                            <!-- Modal -->
                            <ngx-custom-modal
                                #modal13
                                [customClass]="store.rtlClass === 'rtl' ? 'modal-top animate animate-fade-in-right' : 'modal-top animate animate-fade-in-left'"
                            >
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal13.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal13.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-secondary" (click)="modal14.open()">RotateIn Left</button>

                            <!-- Modal -->
                            <ngx-custom-modal
                                #modal14
                                [customClass]="store.rtlClass === 'rtl' ? 'modal-top animate animate-rotate-in-right' : 'modal-top animate animate-rotate-in-left'"
                            >
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal14.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal14.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-dark" (click)="modal15.open()">FadeIn Right</button>

                            <!-- Modal -->
                            <ngx-custom-modal
                                #modal15
                                [customClass]="store.rtlClass === 'rtl' ? 'modal-top animate animate-fade-in-left' : 'modal-top animate animate-fade-in-right'"
                            >
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal15.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal15.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-primary" (click)="modal16.open()">ZoomIn Up</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal16 customClass="modal-top animate animate-zoom-in-up">
                                <ng-template #modalHeader> <div>Modal Title</div> </ng-template>
                                <ng-template #modalBody>
                                    <p>
                                        Mauris mi tellus, pharetra vel mattis sed, tempus ultrices eros. Phasellus egestas sit amet velit sed luctus. Orci
                                        varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Vivamus ultrices
                                        sed urna ac pulvinar. Ut sit amet ullamcorper mi.
                                    </p>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" (click)="modal16.close()" class="btn btn-outline-danger">Discard</button>
                                    <button type="button" (click)="modal16.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Custom -->
            <div class="panel lg:col-span-2">
                <div class="mb-5 flex items-center justify-between">
                    <h5 class="text-lg font-semibold dark:text-white-light">Custom</h5>
                    <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code8')">
                        <span class="flex items-center">
                            <icon-code class="me-2" />
                            Code
                        </span>
                    </a>
                </div>
                <div class="mb-5">
                    <p class="mb-4 text-center">More Custom Modals.</p>
                    <div class="flex flex-wrap items-center justify-center gap-2">
                        <!-- standard  -->
                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-primary" (click)="modal17.open()">Standard</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal17 customClass="modal-top custom-modal">
                                <ng-template #modalBody>
                                    <div class="flex items-center justify-center pb-10 text-base font-medium text-[#1f2937] dark:text-white-dark/70">
                                        <div class="flex h-16 w-16 items-center justify-center rounded-full bg-[#f1f2f3] dark:bg-white/10">
                                            <icon-bell class="h-7 w-7" />
                                        </div>
                                    </div>
                                    <div class="text-center text-sm text-white-dark">
                                        <p>
                                            Vivamus vitae hendrerit neque. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                                            mus. Morbi consequat auctor turpis, vitae dictum augue efficitur vitae. Vestibulum a risus ipsum. Quisque nec lacus
                                            dolor. Quisque ornare tempor orci id rutrum.
                                        </p>
                                    </div>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" class="btn btn-outline-danger" (click)="modal17.close()">Discard</button>
                                    <button type="button" class="btn btn-primary ltr:ml-4 rtl:mr-4" (click)="modal17.close()">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <!-- tabs -->
                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-info" (click)="modal18.open()">Tabs</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal18 customClass="modal-top">
                                <ng-template #modalHeader> <div>Tabs</div> </ng-template>
                                <ng-template #modalBody>
                                    <div class="mt-3 flex flex-wrap border-b border-white-light dark:border-[#191e3a]">
                                        <a
                                            href="javascript:;"
                                            class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
                                            [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'home' }"
                                            (click)="tab1 = 'home'"
                                        >
                                            Home
                                        </a>
                                        <a
                                            href="javascript:;"
                                            class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
                                            [ngClass]="{ '!border-white-light !border-b-white text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'profile' }"
                                            (click)="tab1 = 'profile'"
                                        >
                                            Profile
                                        </a>
                                        <a
                                            href="javascript:;"
                                            class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
                                            [ngClass]="{ '!border-white-light !border-b-white text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'contact' }"
                                            (click)="tab1 = 'contact'"
                                        >
                                            Contact
                                        </a>
                                        <a href="javascript:;" class="pointer-events-none -mb-[1px] block p-3.5 py-2 text-white-light dark:text-dark"
                                            >Disabled</a
                                        >
                                    </div>
                                    <div class="flex-1 pt-5 text-sm">
                                        <div *ngIf="tab1.toLowerCase() === 'home'">
                                            <h4 class="mb-4 text-2xl font-semibold">We move your world!</h4>
                                            <p class="mb-4">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat.
                                            </p>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat.
                                            </p>
                                        </div>
                                        <div *ngIf="tab1.toLowerCase() === 'profile'">
                                            <div class="flex items-start">
                                                <div class="h-20 w-20 flex-none ltr:mr-4 rtl:ml-4">
                                                    <img
                                                        src="/assets/images/profile-34.jpeg"
                                                        alt=""
                                                        class="m-0 h-20 w-20 rounded-full object-cover ring-2 ring-[#ebedf2] dark:ring-white-dark"
                                                    />
                                                </div>
                                                <div class="flex-auto">
                                                    <h5 class="mb-4 text-xl font-medium">Media heading</h5>
                                                    <p class="text-white-dark">
                                                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus
                                                        odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate
                                                        fringilla. Donec lacinia congue felis in faucibus.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="tab1.toLowerCase() === 'contact'">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                                                laborum.
                                            </p>
                                        </div>
                                        <div *ngIf="tab1.toLowerCase() === 'disabled'">Disabled</div>
                                    </div>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" class="btn btn-outline-danger" (click)="modal18.close()">Discard</button>
                                    <button type="button" class="btn btn-primary ltr:ml-4 rtl:mr-4" (click)="modal18.close()">Save</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <!-- profile -->
                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-success" (click)="modal19.open()">Profile</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal19 customClass="modal-top profile-modal">
                                <ng-template #modalBody>
                                    <div class="pb-2 pt-10 text-center text-white dark:text-white-light">
                                        <div class="mx-auto mb-7 h-20 w-20 overflow-hidden rounded-full">
                                            <img src="/assets/images/profile-16.jpeg" alt="" class="h-full w-full object-cover" />
                                        </div>
                                        <p class="text-sm font-semibold">Click on view to access <br />your profile.</p>
                                    </div>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <button type="button" class="btn dark:btn-dark bg-white text-black">View</button>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <!-- login -->
                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-warning" (click)="modal20.open()">Login</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal20 customClass="modal-top auth-modal">
                                <ng-template #modalHeader> <div class="!py-5">Login</div> </ng-template>
                                <ng-template #modalBody>
                                    <form>
                                        <div class="relative mb-4">
                                            <span class="absolute top-1/2 -translate-y-1/2 dark:text-white-dark ltr:left-3 rtl:right-3">
                                                <icon-user class="h-5 w-5" />
                                            </span>
                                            <input type="email" placeholder="Email" class="form-input ltr:pl-10 rtl:pr-10" />
                                        </div>
                                        <div class="relative mb-4">
                                            <span class="absolute top-1/2 -translate-y-1/2 dark:text-white-dark ltr:left-3 rtl:right-3">
                                                <icon-lock class="h-5 w-5" />
                                            </span>
                                            <input type="password" placeholder="Password" class="form-input ltr:pl-10 rtl:pr-10" />
                                        </div>
                                        <button type="button" class="btn btn-primary w-full">Login</button>
                                    </form>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <div class="w-full">
                                        <div class="mb-4 mt-3.5 text-center text-xs text-white-dark dark:text-white-dark/70">OR</div>
                                        <div class="mb-5 flex items-center justify-center gap-3">
                                            <a href="javascript:void(0);" class="btn btn-outline-primary flex gap-1">
                                                <icon-facebook class="h-5 w-5 shrink-0" />
                                                <span>Facebook</span>
                                            </a>
                                            <a href="javascript:void(0);" class="btn btn-outline-danger flex gap-1">
                                                <icon-github class="shrink-0" />
                                                <span>Github</span>
                                            </a>
                                        </div>
                                        <div class="border-t border-[#ebe9f1] p-5 pb-0 text-sm dark:border-white/10">
                                            <p class="text-center text-white-dark dark:text-white-dark/70">
                                                Looking to
                                                <a href="javascript:;" class="text-[#515365] hover:underline dark:text-white-dark">create an account?</a>
                                            </p>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <!-- Register -->
                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-danger" (click)="modal21.open()">Register</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal21 customClass="modal-top auth-modal">
                                <ng-template #modalHeader> <div class="!py-5">Register</div> </ng-template>
                                <ng-template #modalBody>
                                    <form>
                                        <div class="relative mb-4">
                                            <span class="absolute top-1/2 -translate-y-1/2 dark:text-white-dark ltr:left-3 rtl:right-3">
                                                <icon-user class="h-5 w-5" />
                                            </span>
                                            <input type="text" placeholder="Name" class="form-input ltr:pl-10 rtl:pr-10" />
                                        </div>
                                        <div class="relative mb-4">
                                            <span class="absolute top-1/2 -translate-y-1/2 dark:text-white-dark ltr:left-3 rtl:right-3">
                                                <icon-at />
                                            </span>
                                            <input type="email" placeholder="Email" class="form-input ltr:pl-10 rtl:pr-10" />
                                        </div>
                                        <div class="relative mb-4">
                                            <span class="absolute top-1/2 -translate-y-1/2 dark:text-white-dark ltr:left-3 rtl:right-3">
                                                <icon-lock class="h-5 w-5" />
                                            </span>
                                            <input type="password" placeholder="Password" class="form-input ltr:pl-10 rtl:pr-10" />
                                        </div>
                                        <button type="button" class="btn btn-primary w-full">Submit</button>
                                    </form>
                                </ng-template>
                                <ng-template #modalFooter>
                                    <div class="w-full">
                                        <div class="mb-4 mt-3.5 text-center text-xs text-white-dark dark:text-white-dark/70">OR</div>
                                        <div class="mb-5 flex items-center justify-center gap-3">
                                            <a href="javascript:void(0);" class="btn btn-outline-primary flex gap-1">
                                                <icon-facebook class="h-5 w-5 shrink-0" />
                                                <span>Facebook</span>
                                            </a>
                                            <a href="javascript:void(0);" class="btn btn-outline-danger flex gap-1">
                                                <icon-github class="shrink-0" />
                                                <span>Github</span>
                                            </a>
                                        </div>
                                        <div class="border-t border-[#ebe9f1] p-5 pb-0 text-sm dark:border-white/10">
                                            <p class="text-center text-white-dark dark:text-white-dark/70">
                                                Already have
                                                <a href="javascript:;" class="text-[#515365] hover:underline dark:text-white-dark">Login?</a>
                                            </p>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>

                        <!-- slider -->
                        <div>
                            <!-- Trigger -->
                            <button type="button" class="btn btn-secondary" (click)="modal22.open();initSwiper();">Slider</button>

                            <!-- Modal -->
                            <ngx-custom-modal #modal22 customClass="modal-top large-modal slider-modal">
                                <ng-template #modalHeader> <div class="!bg-transparent !px-0 !py-5 !font-semibold">Slider</div> </ng-template>
                                <ng-template #modalBody>
                                    <div class="pb-5">
                                        <div class="swiper mx-auto max-w-3xl" id="slider1">
                                            <div class="swiper-wrapper">
                                                <div *ngFor="let item of items; index as i" class="swiper-slide">
                                                    <img [src]="'/assets/images/' + item" class="w-full object-cover" alt="" />
                                                </div>
                                            </div>
                                            <a
                                                href="javascript:;"
                                                class="swiper-button-prev-ex1 absolute top-1/2 z-[999] grid -translate-y-1/2 place-content-center rounded-full border border-primary p-1 text-primary transition hover:border-primary hover:bg-primary hover:text-white ltr:left-2 rtl:right-2"
                                            >
                                                <icon-caret-down class="h-5 w-5 rotate-90 rtl:-rotate-90" />
                                            </a>
                                            <a
                                                href="javascript:;"
                                                class="swiper-button-next-ex1 absolute top-1/2 z-[999] grid -translate-y-1/2 place-content-center rounded-full border border-primary p-1 text-primary transition hover:border-primary hover:bg-primary hover:text-white ltr:right-2 rtl:left-2"
                                            >
                                                <icon-caret-down class="h-5 w-5 -rotate-90 rtl:rotate-90" />
                                            </a>

                                            <div class="swiper-pagination"></div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-custom-modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
