import { Routes } from '@angular/router';
import { AssistantComponent } from './components/assistant/assistant.component';
import { CoverLoginComponent } from './core/auth/login/login.component';
import { CoverRegisterComponent } from './core/auth/register/register.component';
import { ProfileComponent } from './shared/Components/profile/profile.component';
import { EditProfileComponent } from './shared/Components/edit-profile/edit-profile.component';
import { Error404Component } from './components/error404/error404.component';
import { HomeComponent } from './components/home/home.component';
import { HomeLayout } from './layouts/home-layout';
import { CalendarComponent } from './shared/Components/calendar/calendar';
export const routes: Routes = [
  {
    path: '',
    component: HomeLayout,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'assistant',
        component: AssistantComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'edit-profile',
        component: EditProfileComponent,
      },
      {
        path: 'calendar',
        component: CalendarComponent,
      },
    ],
  },
  {
    path: 'login',
    component: CoverLoginComponent,
  },
  {
    path: 'register',
    component: CoverRegisterComponent,
  },

  {
    path: '**',
    component: Error404Component,
  },
];
// {
// path: '**',
// redirectTo: '/login',
// }
