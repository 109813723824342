import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { GoogleMapsModule } from '@angular/google-maps';
import { GlobalErrorHandler } from './core/global-error-handler';
import { ErrorHandler } from '@angular/core';
// Routes
import { routes } from './app.route';

import { AppComponent } from './app.component';

// Store
import { StoreModule } from '@ngrx/store';
import { indexReducer } from './store/index.reducer';

// Shared module
import { SharedModule } from 'src/shared.module';
// i18n
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AOT compilation support
export function HttpLoaderFactory(httpHandler: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpHandler));
}

// Dashboard
import { IndexComponent } from './index';

// Layouts
import { HomeLayout } from './layouts/home-layout';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { HeaderComponent } from './layouts/header';
import { FooterComponent } from './layouts/footer';
import { SidebarComponent } from './layouts/sidebar';
import { ThemeCustomizerComponent } from './layouts/theme-customizer';

// Components module
import { ComponentsModule } from './components/components.module';

//error 404
import { Error404Component } from './components/error404/error404.component';

// Services
import { GeolocationService } from './shared/service/geolocation/geolocation.service';
import { AuthService } from './shared/service/auth/auth.service';
import { CommonService } from './shared/service/common/common.service';
import { CoverLoginComponent } from './core/auth/login/login.component';
import { AssistantComponent } from './components/assistant/assistant.component';
import { environment } from '../environments/environment';
import { CoverRegisterComponent } from './core/auth/register/register.component';
import { ConfirmationmessageComponent } from './shared/Components/comfirmation-message/comfirmation-message.component';
const config: SocketIoConfig = { url: environment.socketUrl, options: {} };

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
    HttpClientModule,
    NgScrollbarModule,
    GoogleMapsModule,
    SocketIoModule.forRoot(config),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    StoreModule.forRoot({ index: indexReducer }),
    SharedModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ThemeCustomizerComponent,
    IndexComponent,
    HomeLayout,
    CoverLoginComponent,
    CoverRegisterComponent,
    AssistantComponent,
    Error404Component,
    ConfirmationmessageComponent,
  ],
  providers: [Title, GeolocationService, AuthService, CommonService, { provide: ErrorHandler, useClass: GlobalErrorHandler }],
  bootstrap: [AppComponent],
  exports: [SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
