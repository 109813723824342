import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../shared/service/app/app.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-comfirmation-message',
  templateUrl: './comfirmation-message.component.html',
  styleUrl: './comfirmation-message.component.css',
})
export class ConfirmationmessageComponent {
  showmodal: boolean = true;
  countdown: number = 5;
  verifycode: string = '';
  code1: string = 'x';
  code2: string = 'x';
  code3: string = 'x';
  code4: string = 'x';
  interval: any;
  store: any;
  failedAttempts: number = 0;
  maxAttempts: number = 5;
  currYear: number = new Date().getFullYear();

  constructor(
    public translate: TranslateService,
    public storeData: Store<any>,
    public router: Router,
    private appSetting: AppService,
  ) {
    this.initStore();
  }
  async initStore() {
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }
  verifyCode() {
    // console.log('Verify code: ', this.code1, this.code2, this.code3, this.code4);
    const enteredCode = `${this.code1}${this.code2}${this.code3}${this.code4}`;
    if (enteredCode === '1234') {
      swal
        .fire({
          title: '¡Éxito!',
          text: 'Código correcto. Bienvenido.',
          icon: 'success',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#3085d6',
          position: 'top',
          timer: 3000,
          timerProgressBar: true,
          toast: true,
        })
        .then(() => {
          this.router.navigate(['/']); // Ejemplo de redirección
        });
    } else {
      this.failedAttempts++;
      swal.fire({
        title: 'Error',
        text: 'Código incorrecto. Inténtalo de nuevo.',
        icon: 'error',
        confirmButtonText: 'Reintentar',
        confirmButtonColor: '#d33',
        position: 'top',
        timer: 3000,
        timerProgressBar: true,
        toast: true,
      });
    }
  }
  onRegister() {
    // Muestra el modal
    this.showmodal = true;
    this.startCountdown();
  }
  ngOnInit() {
    this.startCountdown(); // Inicia el contador al abrir el modal
  }

  startCountdown() {
    this.countdown = 5;
    this.interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  resendCode() {
    if (this.failedAttempts >= this.maxAttempts) {
      // Si el usuario ha alcanzado el número máximo de intentos fallidos
      swal.fire({
        title: 'No se puede reenviar',
        text: 'Has superado el límite de intentos fallidos. No puedes solicitar más códigos.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#d33',
        position: 'top',
        timer: 3000,
        timerProgressBar: true,
        toast: true,
      });
    } else {
      // Si aún no ha superado el límite de intentos, se inicia el contador y se puede reenviar
      this.startCountdown();
      // Aquí puedes agregar la lógica para reenviar el código al usuario
      console.log('Código reenviado');
    }
  }

  closeModal() {
    swal
      .fire({
        title: '¿Estás seguro?',
        text: 'El código aún no ha sido verificado. ¿Deseas cerrar el modal?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, cerrar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.showmodal = false;
          clearInterval(this.interval);
        }
      });
  }

  changeLanguage(item: any) {
    this.translate.use(item.code);
    this.appSetting.toggleLanguage(item);
    if (this.store.locale?.toLowerCase() === 'ae') {
      this.storeData.dispatch({ type: 'toggleRTL', payload: 'rtl' });
    } else {
      this.storeData.dispatch({ type: 'toggleRTL', payload: 'ltr' });
    }
    window.location.reload();
  }
}
