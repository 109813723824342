export const environment = {
  production: false,
  apiUrl: 'https://api.pitzon.io/api-core/v1/',
  socketUrl: 'https://api.pitzon.io',
  secrets: {
    key: 'o4pSi0Ko1SHToD9geVmSTC5bdqQZIg6XuFjILdeBEEw=', // Debe ser exactamente 32 caracteres
    ivLength: 16, // Longitud del IV en bytes
    appCode: '9b83dc5f-e7c2-4616-8766-792f7d662096',
  },
};
