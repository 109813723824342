import { Component, OnInit } from '@angular/core';
import { toggleAnimation } from 'src/app/shared/animations';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../shared/service/app/app.service';
import { AnalitycsService } from 'src/app/shared/service/analitycs.service';
import moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './../../../shared/service/common/common.service';
import { UserSendCodeDTO } from './../../../shared/dto/user.dto';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.scss'],
  animations: [toggleAnimation],
})
export class CoverRegisterComponent implements OnInit {
  user: UserSendCodeDTO | null = null;
  registerForm: FormGroup;
  isModalVisible: boolean = false;
  isDarkMode: boolean = false;
  interval: any;
  spanish: boolean = true;
  store: any;
  currYear: number = new Date().getFullYear();
  currentImage: string = '';
  formGroup: FormGroup;
  constructor(
    public translate: TranslateService,
    public storeData: Store<any>,
    public router: Router,
    private appSetting: AppService,
    private analitycs: AnalitycsService,
    private fb: FormBuilder,
    private commonService: CommonService,
  ) {
    this.formGroup = this.fb.group({
      vehiclePlate: [''],
    });
    this.initStore();
    //Reactive-Form
    this.registerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      id: ['', Validators.required],
      city: ['', Validators.required],
      typeOfId: ['', Validators.required],
      documentType: ['', Validators.required],
      documentNumber: ['', [Validators.required, Validators.pattern(/^\d+$/)]], //only numbers
      code: ['', Validators.required],
      phone: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      vehiclePlate: ['', [Validators.required, Validators.maxLength(6), Validators.pattern('^[A-Za-z0-9]+$')]],
      vehicleModel: ['', Validators.required],
      termsAccepted: [false, Validators.requiredTrue],
    });
  }
  async initStore() {
    this.analitycs.logEvent('init register', {
      view: 'register',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }

  ngOnInit() {
    this.storeData
      .select((state) => state.index.locale)
      .subscribe((locale) => {
        this.spanish = locale === 'es';

        // Actualizar la imagen según el idioma
        this.currentImage = locale === 'en' ? '../../assets/images/pit1inglish.jpg' : '../../assets/images/pit.jpg';
      });
    localStorage.setItem('light', 'dark');
    this.storeData
      .select((state) => state.index.theme)
      .subscribe((theme) => {
        this.isDarkMode = theme === 'dark';
      });
  }

  async openModal() {
    this.analitycs.logEvent('openModal', {
      view: 'register',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    console.log('this.registerForm.valid', this.registerForm.valid);
    if (this.registerForm.valid) {
      this.user = {
        email: this.registerForm.value.email,
        phone: {
          code: this.registerForm.value.code,
          number: this.registerForm.value.phone,
        },
      };
      const rsp = await this.commonService.sendSmsCode(this.user);
      console.log(rsp);
      this.isModalVisible = true;
      console.log('Formulario enviado:', this.registerForm.value);
    } else {
      console.log('Formulario inválido');
    }
  }
  closedModal() {
    this.analitycs.logEvent('closedModal', {
      view: 'register',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.isModalVisible = false;
  }
  networks() {
    this.analitycs.logEvent('networks', {
      view: 'register',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
  }
  GoToLogin() {
    this.analitycs.logEvent('GoToLogin', {
      view: 'register',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['../login']);
  }
  forgotPassword() {
    this.analitycs.logEvent('forgotPassword', {
      view: 'register',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['../forgot-password']);
  }
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
    this.formGroup.get('vehiclePlate')?.setValue(input.value, { emitEvent: false });
  }
}
