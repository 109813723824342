<!-- Modal para seleccionar avatares -->
<div *ngIf="isModalOpen" class="modal-overlay w-full" (click)="closeAvatarModal()">
    <div class="modal-content" (click)="$event.stopPropagation()">
        <h3>Elige un Avatar</h3>
        <div class="avatar-selection">
            <img *ngFor="let avatar of avatars; let i = index" 
                 [src]="avatar" 
                 [alt]="'Avatar ' + (i + 1)" 
                 class="avatar-thumbnail" 
                 (click)="selectAvatar(avatar)" />
          

            @for (avatar of avatares; track $index) {
                <img [src]="avatar" [alt]="'Avatar ' + ($index + 1)" class="avatar-thumbnail" (click)="selectAvatar(avatar)" />
            }

        </div>
        <div class="selected-avatar">
            <h3 class="mb-2 text-primary text-3xl">Tu avatar seleccionado:</h3>
            <img *ngIf="selectedAvatar" [src]="selectedAvatar" alt="Selected Avatar" />
            <button (click)="closeAvatarModal()" class="botoo">X</button>
    </div>
    </div>
</div>

<form class="mb-5 rounded-md border border-primary bg-white p-4 dark:border-white  text-black dark:text-white dark:bg-[#0e1726] animate__animated animate__zoomInUp">
    <h6 class="mb-5 text-lg font-bold">{{ 'HOME-PROFILE.INFO' | translate }}</h6>
    <div class="flex flex-col sm:flex-row">
        <div class="mb-5 w-full sm:w-2/12 ltr:sm:mr-4 rtl:sm:ml-4 relative">
            <!-- Imagen del avatar (Juan) -->
             <div>
                 <img [src]="selectedAvatar" alt="Avatar seleccionado" class="avatar-image" />            
                 <button 
                 class="add-avatar-btn absolute animate-pulse"
                 (click)="openAvatarModal()">
                 <icon-pencil-paper />
                </button>
            </div>
        </div>
        <div class="grid flex-1 grid-cols-1 gap-5 sm:grid-cols-2">
            <div>
                <label for="name">
                    {{ 'HOME-PROFILE.NAME' | translate }}
                </label>
                <input id="name" type="text" placeholder="Juan" class="form-input border-black dark:border-white" />
            </div>
            <div>
                <label for="profession">{{ 'HOME-PROFILE.LASTNAME' | translate }}</label>
                <input id="profession" type="text" placeholder="Mecánico" class="form-input border-black dark:border-white" />
            </div>
            <div>
                <label for="country">{{ 'HOME-PROFILE.Pais' | translate }}</label>
                <select id="country" class="form-select text-white-dark border-black dark:border-white">
                    <option selected>Colombia</option>
                    <option *ngFor="let option of options">{{ option }}</option> 
                </select>
            </div>
            <div>
                <label for="address">{{ 'HOME-PROFILE.CITY' | translate }}</label>
                <input id="address" type="text" placeholder="Pereira" class="form-input border-black dark:border-white" />
            </div>
            <div>
                <label for="location">{{ 'HOME-PROFILE.ADRESS' | translate }}</label>
                <input id="location" type="text" placeholder="Juan@gmail.com" class="form-input border-black dark:border-white" />
            </div>
            <div>
                <label for="phone">{{ 'HOME-PROFILE.PHONE' | translate }}</label>
                <input id="phone" type="number" placeholder="(57) 300-3201543" class="form-input border-black dark:border-white" />
            </div>
            <div>
                <label for="email">{{ 'HOME-PROFILE.PASSWORD' | translate }}</label>
                <input id="email" type="email" placeholder="********" class="form-input border-black dark:border-white" />
            </div>
            <div>
                <label for="web">{{ 'HOME-PROFILE.CONFIRMPASSWORD' | translate }}</label>
                <input id="web" type="text" placeholder="********" class="form-input border-black dark:border-white" />
            </div>
            <div>
            </div>
            <div class="mt-3 sm:col-span-2">
                <button type="button" class="btn btn-primary">{{ 'HOME-PROFILE.BUTTON' | translate }}</button>
            </div>
        </div>
    </div>
</form>
<form class="rounded-md border border-primary bg-white p-6 dark:border-[#191e3a] dark:bg-[#0e1726]">
    <h2 class="mb-5 text-lg font-bold text-center text-black dark:text-white">
        {{ 'HOME-PROFILE.PAY' | translate }}
    </h2>

    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <!-- Campo SOAT -->
        <div>
            <label for="soat" class="block mb-2 text-sm font-bold text-primary  text-center">
                SOAT
            </label>
            <div class="flex items-center">
                <div class="flex items-center justify-center rounded bg-primary px-3 font-semibold dark:bg-[#1b2e4b] ltr:mr-2 rtl:ml-2">
                    <icon-user-plus class="h-8 w-5 text-white border-black dark:border-white" />
                </div>
                <input 
                    id="soat" 
                    type="date" 
                    class="form-input flex-1 rounded-md  border-black dark:border-white p-2  dark:bg-[#1b2e4b] dark:text-white"
                />
            </div>
            <div>
                <label for="file">Sube la evidencia</label>
                <input type="file">
            </div>
        </div>

        <!-- Campo Técnico-Mecánica -->
        <div>
            <label for="tecnico-mecanica" class="block mb-2 text-sm font-bold text-primary text-center">
                Técnico-Mecánica
            </label>
            <div class="flex items-center">
                <div class="flex items-center justify-center rounded bg-primary px-3 font-semibold dark:bg-[#1b2e4b] ltr:mr-2 rtl:ml-2">
                    <icon-user-plus class="h-8 w-5 text-white border-black dark:border-white" />
                </div>
                <input 
                    id="tecnico-mecanica" 
                    type="date" 
                    class="form-input flex-1 rounded-md border  border-black dark:border-white dark:bg-[#1b2e4b] dark:text-white"
                />
            </div>
            <div>
                <label for="file">Sube la evidencia</label>
                <input type="file">
            </div>
        </div>
    </div>
</form>





  