import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment-timezone';
@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private showMapSubject = new BehaviorSubject<boolean>(false);
  showMap$ = this.showMapSubject.asObservable();

  constructor(private snackBar: MatSnackBar) {
    // do nothing
  }

  // toast
  toast(message: string, action?: string, duration?: number) {
    this.snackBar.open(message, action, {
      duration: duration || 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }

  // Generate alphanumeric code
  generateCode(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // Get the time ago with momentjs
  getAgoTime(dateUTC: string) {
    // Convert the UTC date to America/Bogota timezone
    const dateInBogota = moment(dateUTC).tz('America/Bogota');

    // Returns the time from now in America/Bogota timezone
    return dateInBogota.fromNow();
  }

  // Toggle map
  toggleToMap(value: boolean) {
    this.showMapSubject.next(value);
  }

  formatChatTimestamp(timestamp: number): string {
    const time = moment.unix(timestamp);
    const now = moment();
    if (time.isSame(now, 'minute')) {
      return 'Ahora';
    }
    if (time.isAfter(now.subtract(1, 'minutes'))) {
      return 'Hace más de un minuto';
    }
    return time.format('DD [de] MMM - HH:mm:ss');
  }
}
