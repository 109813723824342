import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MapService } from './service/map.service';
import { slideDownUp } from '../../shared/animations';
import { GeolocationService } from '../../shared/service/geolocation/geolocation.service';
import { AuthService } from './../../shared/service/auth/auth.service';
import { WorkShopDTO } from './../../shared/dto/work-shop.dto';
import { CoordinatesDTO } from './../../shared/dto/common.dto';
import { CommonService } from './../../shared/service/common/common.service';
import { UserIconPositionConst, WorkShopIconPositionConst, MapOptionsConstants } from './../../shared/constants/common.constants';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrl: './map.component.css',
  animations: [slideDownUp],
})
export class MapComponent implements OnInit {
  @ViewChild('scrollable') scrollable!: NgScrollbar;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined;
  

  @Output() assistantSchedule = new EventEmitter<any>();
  @Input() mapWidth: string = '200%'; // Ancho predeterminado
  @Input() mapHeight: string = '100%'; // Alto predeterminado
  @Input() cordenadas: CoordinatesDTO = { lat: 0, lng: 0 };

  coords: CoordinatesDTO = { lat: 0, lng: 0 };
  loadingMap: boolean = false;
  workshops: WorkShopDTO[] = [];
  selectedWorkshop: WorkShopDTO | undefined;
  cordenadasUser: CoordinatesDTO = { lat: 0, lng: 0 };
  userIconPosition = UserIconPositionConst;
  workShopIconPosition = WorkShopIconPositionConst;
  mapOptions = MapOptionsConstants;
  isMobile: boolean = false;
  showMap: boolean = true;
  textMessage: string = '';
  loadingConversation: boolean = false;
  store: any;
  assistantSection: boolean = false;
  items = [
    'https://rmservicosautomotivos.com.br/imagens/noticias/56_1_1.jpg',
    'https://tse3.mm.bing.net/th?id=OIP.oevHw_XbzeC1xgO1vQqbggHaE6&pid=Api&P=0&h=18.jpg',
    'https://tse4.mm.bing.net/th?id=OIP.ooynGjMXNga_mZuw9QoMdgHaEK&pid=Api&P=0&h=180',
  ];

  constructor(
    private router: Router,
    private geolocationService: GeolocationService,
    public mapService: MapService,
    private auth: AuthService,
    private readonly commonService: CommonService,
    private route: ActivatedRoute,
  ) {
    this.workshops = [];
    if (window.innerWidth < 768) {
      this.isMobile = false;
      this.mapWidth = '100%';
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const lat = Number(params['lat']); // Convertir directamente a número
      const lng = Number(params['lng']);

      if (!isNaN(lat) && !isNaN(lng)) {
        this.cordenadas = { lat, lng };
        console.log('DESDE EL ASISTENTE Posición obtenida:', this.cordenadas);
      } else {
        console.error('Los parámetros no son números válidos.');
      }
    });
    this.getAvailableCustomers();
    this.getLocation();
    this.mapService.showMap$.subscribe((value) => {
      this.showMap = !value;
    });
    this.initStore();
  }

  async initStore() {
    // todo: prepare the store
  }

  // Get the workshops
  async getAvailableCustomers() {
    const response = await this.commonService.getAvailableCustomers();
    if (response && response.length > 0) {
      this.workshops = response;
      for (const i in this.workshops) {
        this.workshops[i].coords = {
          lat: this.workshops[i].gpsLocation.coordinates[1],
          lng: this.workshops[i].gpsLocation.coordinates[0],
        };
      }
    }
  }

  async getLocation() {
    this.loadingMap = true;
    const location = await this.geolocationService.getCurrentLocation();
    if (location) {
      this.coords = {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      };
      this.loadingMap = false;
      console.log('Coords de la propia angular services', this.coords);
    } else {
      this.coords = {
        lat: this.cordenadas.lat,
        lng: this.cordenadas.lng,
      };
      console.log('Coords de la propia IONIC CON CAPACITOR', this.coords);
    }
  }

  async getLocationByBrowser() {
    this.loadingMap = true;
    await this.geolocationService.getLocationByIp();
  }

  openWorkshopInfo(marker: MapMarker, workshop: WorkShopDTO) {
    this.selectedWorkshop = workshop;
    if (this.infoWindow) {
      this.infoWindow.open(marker);
    }
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.scrollable.scrollTo({ bottom: 0, duration: 200 });
    }, 300);
  }

  goToAssistant() {
    // this.router.navigate(['/assistant']);
  }

  schedule(workShop: WorkShopDTO) {
    if (this.cordenadas.lat === 0 && this.cordenadas.lng === 0) {
      this.router.navigate(['/assistant']);
    } else {
      this.router.navigate(['/assistant'], { queryParams: { lat: this.cordenadas.lat, lng: this.cordenadas.lng } });
    }
    console.log('SELECTED', workShop);
    this.assistantSchedule.emit(workShop);
  }
}
