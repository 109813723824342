<div class="pt-5 bg-azulOscuro">
  <div class="mb-5 grid grid-cols-1 gap-5 lg:grid-cols-3 xl:grid-cols-4">
    <div class="panel">
      <div class="mb-5 flex items-center justify-between">
        <h5 class="text-lg font-semibold dark:text-white-light animate__animated animate__jackInTheBox animate__delay-1s">
          {{ 'PROFILE.PROFILE' | translate }}
        </h5>
        <a (click)="goToEditProfile()" class="btn btn-primary rounded-full p-2 cursor-pointer ltr:ml-auto rtl:mr-auto animate-pulse">
          <icon-pencil-paper />
        </a>
      </div>
      <div class="mb-5">
        <div class="flex flex-col items-center justify-center">
          <img [src]="selectedAvatar" alt="" class="mb-5 h-24 w-24 rounded-full object-cover animate__animated animate__jackInTheBox animate__delay-1s" />
          <p class="text-xl font-semibold text-primary">Juan Mecanico</p>
        </div>
        <ul class="m-auto mt-5 flex max-w-[160px] flex-col space-y-4 font-semibold text-black dark:text-white-dark">
          <li class="flex items-center gap-2">
            <icon-coffee class="shrink-0" />
            {{ 'PROFILE.PORFESSION' | translate }}
          </li>
          <li class="flex items-center gap-2">
            <icon-calendar class="shrink-0" />
            {{ 'PROFILE.BIRTHDAY' | translate }}
          </li>
          <li class="flex items-center gap-2">
            <icon-map-pin class="shrink-0" />
            Colombia, Pereira
          </li>
          <li>
            <a href="javascript:;" class="flex items-center gap-2">
              <icon-mail class="h-5 w-5 shrink-0" />
              <span class="truncate text-primary">{{ 'juan@gmail.com' }}</span></a
            >
          </li>
          <li class="flex items-center gap-2">
            <icon-phone />
            <span class="whitespace-nowrap" dir="ltr">(+57) 325-12121</span>
          </li>
        </ul>
        <ul class="mt-7 flex items-center justify-center gap-2">
          <li>
            <a target="_blank" class="btn btn-info flex h-10 w-10 items-center justify-center rounded-full p-0" href="https://x.com/Juancamoller">
              <icon-twitter class="h-5 w-5" />
            </a>
          </li>
          <li>
            <a target="_blank" href="https://www.facebook.com/Pitzon.io?locale=fo_FO" class="btn btn-danger flex h-10 w-10 items-center justify-center rounded-full p-0">
              <icon-facebook />
            </a>
          <li>
            <a target="_blank" class="btn btn-dark flex h-10 w-10 items-center justify-center rounded-full p-0" href="https://www.instagram.com/pitzon.io/">
              <icon-instagram />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="panel lg:col-span-2 xl:col-span-3">
      <div class="mb-5">
        <h5 class="text-lg font-semibold dark:text-white-light">Ordenes de Servicio</h5>
        
      </div>
      <div class="mb-5">
        <div class="table-responsive font-semibold text-[#655151] dark:text-white-light">
          <table class="whitespace-nowrap">
            <thead>
              <tr class="animate__animated animate__zoomIn animate__delay-1s">
                <th>Factura</th>
                <th>Fecha</th>
                <th># ODS</th>
                <th>Taller</th>
                <th>Vehiculo</th>
                <th>Tipo de venta</th>
                <th>Medio</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody class="dark:text-white-dark text-center">
              <tr class="animate__animated animate__zoomInLeft animate__delay-1s">
                <td>#00047</td>
                <td class="">2025-01-04</td>
                <td><a target="_blank" href="https://tuulapp.com/3/?i=65cd427fea4fa55251cd141a">#202402140002</a></td>
                <td>Motos Gp</td>
                <td>Ducati</td>
                <td>Contado</td>
                <td>Efectivo</td>
                <td>$ 1.060.182,81</td>
              </tr>
              <tr class="animate__animated animate__zoomInRight animate__delay-1s">
                <td>#00048</td>
                <td>2025-01-05</td>
                <td><a target="_blank" href="https://tuulapp.com/3/?i=65cd427fea4fa55251cd141a">#202402140002</a></td>
                <td>ZONA MOTORS SAS</td>
                <td>BMW</td>
                <td>Contado</td>
                <td>Efectivo</td>
                <td>$ 1.500.182,81</td>
              </tr>
              <tr class="animate__animated animate__zoomInLeft animate__delay-1s">
                <td>#00049</td>
                <td>2025-01-06</td>
                <td><a target="_blank" href="https://tuulapp.com/3/?i=65cd427fea4fa55251cd141a">#202402140002</a></td>
                <td>AUTOTECH SAS</td>
                <td>Roll Royce</td>
                <td>Contado</td>
                <td>Efectivo</td>
                <td>$ 3.500.182,81</td>
              </tr>
              <tr class="animate__animated animate__zoomInRight animate__delay-1s">
                <td>#00048</td>
                <td>2025-01-07</td>
                <td><a target="_blank" href="https://tuulapp.com/3/?i=65cd427fea4fa55251cd141a">#202402140002</a></td>
                <td>AUTOTECH SAS</td>
                <td>Chevrolet Spark</td>
                <td>Contado</td>
                <td>Efectivo</td>
                <td>$ 500.182,81</td>
              </tr>
              <tr class="animate__animated animate__zoomInLeft animate__delay-1s">
                <td>#00050</td>
                <td>2025-01-08</td>
                <td><a target="_blank" href="https://tuulapp.com/3/?i=65cd427fea4fa55251cd141a">#202402140002</a></td>
                <td>Silver Cars SAS</td>
                <td>Hyundai</td>
                <td>Contado</td>
                <td>Efectivo</td>
                <td>$ 700.182,81</td>
              </tr>
              <tr class="animate__animated animate__zoomInRight animate__delay-1s">
                <td>#00051</td>
                <td>2025-01-09</td>
                <td><a target="_blank" href="https://tuulapp.com/3/?i=65cd427fea4fa55251cd141a">#202402140002</a></td>
                <td>AUTOTECH SAS</td>
                <td>Toyota</td>
                <td>Contado</td>
                <td>Efectivo</td>
                <td>$ 100.182,81</td>
              </tr>
              <tr class="animate__animated animate__zoomInLeft animate__delay-1s">
                <td>#00052</td>
                <td>2025-01-10</td>
                <td><a target="_blank" href="https://tuulapp.com/3/?i=65cd427fea4fa55251cd141a">#202402140002</a></td>
                <td>Silver Cars SAS</td>
                <td>Toyota</td>
                <td>Contado</td>
                <td>Efectivo</td>
                <td>$ 300.182,81</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
