<body>
  <div class="bg-gradient-to-b from-white to-gray-300">
    <div class="flex flex-col justify-center items-center h-screen">
      
      <!-- Contenedor de la imagen de error 404 y el botón -->
      <div class="flex flex-col items-center">
        <img
          src="../../../assets/images/pitzon404.gif"
          alt="ERROR 404"
          class="rounded-lg shadow-lg mb-4"
        />
      </div>
  
      <!-- Botón responsive -->
      <button class="learn-more mt-4 sm:mt-8 px-6 py-2 sm:px-8 sm:py-3 bottom-24 sm:left-80 left-1/2 transform -translate-x-1/2 sm:translate-x-0" (click)="goToHome()">
        <span class="circle" aria-hidden="true">
          <span class="icon arrow"></span>
        </span>
        <span class="button-text">{{ 'ERROR.BUTTON' | translate }}</span>
      </button>      
    </div>
  </div>
</body>
