<div>
  <!-- <header class="relative"></header>

  <ul class="absolute pointers top-0 left-0 z-50 flex space-x-2 rtl:space-x-reverse p-4">
    <li>
      <a
        href="javascript:;"
        (click)="goBack()"
        class="text-primary cursor-pointer hover:underline animate__animated animate__bounceInLeft animate__delay-1s"
        >{{ 'PROFILE.START' | translate }}</a
      >
    </li>
    <li class="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-white dark:text-white">
      <span class="text-white dark:text-black z-50 animate__animated animate__bounceInRight animate__delay-1s">{{ 'PROFILE.PROFILE' | translate }}</span>
    </li>
  </ul> -->
  <div class="bg-center pt-5 bg-azulOscuro flex items-center justify-center animate__animated animate__fadeIn animate__delay-1s">
    <img
      *ngIf="isDarkMode === false"
      src="../../../../assets/images/logo-23.png"
      alt="img"
      class="w-40 flex justify-center items-center animate__animated animate__fadeIn animate__delay-1s"
    />
    <img
      *ngIf="isDarkMode === true"
      src="../../../../assets/images/logo-23.png"
      alt="img"
      class="w-40 flex justify-center items-center animate__animated animate__fadeIn animate__delay-1s"
    />
  </div>
  <div class="pt-5 bg-azulOscuro">
    <app-ods></app-ods>
    <!-- <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <app-fines></app-fines>
      <div class="panel">
        <div class="mb-10 flex items-center justify-between">
          <h5 class="text-lg font-semibold dark:text-white-light">{{ 'PROFILE.LICENSE' | translate }}</h5>
          <a href="javascript:;" class="btn btn-primary">{{ 'PROFILE.RENOVATION' | translate }}</a>
        </div>
        <div class="group">
          <ul class="mb-7 list-inside list-disc space-y-2 font-semibold dark:text-white-dark text-black">
            <li>A2</li>
            <li>B1</li>
            <li>C1</li>
          </ul>
          <div class="mb-4 flex items-center justify-between font-semibold">
            <p class="flex items-center rounded-full bg-dark px-2 py-1 text-xs font-semibold text-white-light">
              <icon-clock class="h-3 w-3 ltr:mr-1 rtl:ml-1" />
              {{ 'PROFILE.VIGENT' | translate }}
            </p>
          </div>
          <div class="mb-5 h-2.5 overflow-hidden rounded-full bg-dark-light p-0.5 dark:bg-dark-light/10">
            <div class="relative h-full w-full rounded-full bg-gradient-to-r from-[#f67062] to-[#fc5296]" style="width: 65%"></div>
          </div>
        </div>
      </div> -->
      <div class="grid grid-cols-1 gap-5 lg:grid-cols-2">
        <app-data-documents></app-data-documents>
        <app-data-vehicle></app-data-vehicle>
      </div>
    </div>
  </div>
    
