import { Component } from '@angular/core';

@Component({
  selector: 'app-data-documents',
  templateUrl: './data-documents.component.html',
  styleUrl: './data-documents.component.css',
})
export class DataDocumentsComponent {
  // Objeto que guarda el estado de apertura de cada menú
  isDropdownOpen: any = {
    soat: false,
    tecnomecanica: false,
  };

  // Función que cambia el estado de apertura del menú correspondiente
  toggleDropdown(menu: string): void {
    this.isDropdownOpen[menu] = !this.isDropdownOpen[menu];
  }
}
