<div class="swiper mx-auto mb-5 max-w-3xl" id="slider2">
    <div class="swiper-wrapper">
      <div *ngFor="let item of items; index as i" class="swiper-slide relative">
        <img [src]="item" class="h-60 w-full object-contain" alt="" />
        <div class="absolute inset-0 flex items-center justify-center z-[999] text-white">
        </div>
      </div>
    </div>
  </div>
  
    <a
        href="javascript:;"
        class="swiper-button-prev-ex2 absolute top-1/4 z-[999] grid -translate-y-1/2 place-content-center rounded-full border border-primary p-1 text-primary transition hover:border-primary hover:bg-primary hover:text-white ltr:left-2 rtl:right-2"
    >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 rtl:rotate-180">
            <path d="M15 5L9 12L15 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </a>
    <a
        href="javascript:;"
        class="swiper-button-next-ex2 absolute top-1/4 z-[999] grid -translate-y-1/2 place-content-center rounded-full border border-primary p-1 text-primary transition hover:border-primary hover:bg-primary hover:text-white ltr:right-2 rtl:left-2"
    >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ltr:rotate-180">
            <path d="M15 5L9 12L15 19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </a>
