import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './map/map.component';
import { SharedModule } from './../../shared.module';
import { WorkshopPhotosComponent } from './workshop-photos/workshop-photos.component';
import { NetworksComponent } from '../shared/Components/networks/networks.component';

@NgModule({
  declarations: [MapComponent, WorkshopPhotosComponent, NetworksComponent],
  providers: [],
  imports: [CommonModule, GoogleMapsModule, SharedModule],
  exports: [CommonModule, GoogleMapsModule, MapComponent, WorkshopPhotosComponent, NetworksComponent],
})
export class ComponentsModule {}
