<div [ngClass]="{ 'dark text-white-dark': store.semidark }">
    <nav class="sidebar fixed bottom-0 top-0 z-50 h-full min-h-screen w-[260px] shadow-[10px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300">
        <div class="h-full bg-white dark:bg-[#0e1726]">
            <div class="flex items-center justify-between px-4 py-3">
                <a routerLink="/" class="main-logo flex shrink-0 items-center">
                    <img class="ml-[5px] w-8 flex-none" src="../../assets/images/logop.png" alt="" />
                    <span class="block align-middle text-2xl font-bold font-sans transition-all duration-300 ltr:ml-1.5 rtl:mr-1.5 text-primary md:inline animate__animated animate__delay-1s animate__lightSpeedInRight">
                        PITZON.<span class="text-black dark:text-white">IO</span>
                    </span></a>
                <a
                    href="javascript:;"
                    class="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 hover:text-primary rtl:rotate-180 dark:text-white-light dark:hover:bg-dark-light/10 "
                    (click)="storeData.dispatch({ type: 'toggleSidebar'})"
                >
                    <icon-carets-down class="m-auto rotate-90 text-black dark:text-white" />
                </a>
            </div>
            <ng-scrollbar class="relative !h-[calc(100vh-80px)]" appearance="compact">
                <ul class="relative space-y-0.5 p-4 py-0 font-semibold">
                   
                    <h2 class="-mx-4 mb-1 flex items-center bg-primary px-7 py-3 font-extrabold uppercase ">
                        <icon-minus class="hidden h-5 w-4 flex-none" />
                        <span>{{ ('user_and_pages' | translate) || '&nbsp;' }}</span>
                    </h2>
                    <!-- <div class="dropdown shrink-0">
                        <div class="w-10 h-10 relative">
                          Imagen de usuario
                          <img
                            (click)="toggleDropdown()"
                            class="w-10 h-10 rounded-full cursor-pointer object-cover"
                            src="../../assets/images/juan2.png"
                            alt="image"
                          />
                          Indicador de estado
                          <span
                            class="absolute ltr:right-0 rtl:left-0 bottom-0 w-2 h-2 rounded-full ring-2 ring-white dark:ring-white-dark bg-success">
                          </span>
                      
                          Menú desplegable
                          <div
                            *ngIf="isDropdownOpen"
                            class="relative ltr:right-0 rtl:left-0 mt-2 w-64 bg-white dark:bg-gray-800 shadow-lg rounded-md z-10 transition-transform duration-200 ease-out transform origin-top scale-95"
                            [class.scale-100]="isDropdownOpen">
                      
                            Lista de opciones
                            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
                              Perfil
                              <li>
                                <a
                                  (click)="goToProfile()"
                                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                  <i class="fas fa-user mr-3"></i> Perfil
                                </a>
                              </li>
                      
                              Comunidad
                              <li>
                                <a
                                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                  <i class="fas fa-users mr-3"></i> Comunidad
                                </a>
                              </li>
                      
                              Configuración
                              <li>
                                <a
                                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                  <i class="fas fa-cog mr-3"></i> Configuración
                                </a>
                              </li>
                      
                              Notificaciones
                              <li>
                                <a
                                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                  <i class="fas fa-bell mr-3"></i> Notificaciones
                                </a>
                              </li>
                      
                              Conversaciones guardadas
                              <li>
                                <a
                                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                  <i class="fas fa-bookmark mr-3"></i> Conversaciones guardadas
                                </a>
                              </li>
                      
                              Asistencia Chats
                              <li>
                                <a
                                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                  <i class="fas fa-headset mr-3"></i> Asistencia Chats
                                </a>
                              </li>
                      
                              Soporte
                              <li>
                                <a
                                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                  <i class="fas fa-life-ring mr-3"></i> Soporte
                                </a>
                              </li>
                      
                              WhatsApp
                              <li>
                                <a
                                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                  <i class="fab fa-whatsapp mr-3 text-green-500"></i> WhatsApp
                                </a>
                              </li>
                      
                              Agregar vehículo
                              <li>
                                <a
                                  class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">
                                  <i class="fas fa-car mr-3"></i> Agregar vehículo
                                </a>
                              </li>
                      
                              Separador
                              <li class="border-t border-gray-200 dark:border-gray-700 my-2"></li>
                      
                              Cerrar sesión
                              <li>
                                <a
                                  (click)="logout()"
                                  class="flex items-center px-4 py-2 text-red-500 hover:bg-red-100 dark:hover:bg-red-700 cursor-pointer">
                                  <i class="fas fa-sign-out-alt mr-3"></i> Cerrar sesión
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> -->                    
                </ul>
            </ng-scrollbar>
        </div>
    </nav>
</div>
