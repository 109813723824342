import { Component } from '@angular/core';

@Component({
  selector: 'app-testimony',
  templateUrl: './testimony.component.html',
  styleUrl: './testimony.component.css',
})
export class TestimonyComponent {
  isPaused: boolean = false;
  testimony: any[] = [
    {
      img: '../../../../assets/images/testimony/testi1.jpg',
      titl: 'Juan Camilo Chaverra',
      subtitle:
        'En Pitzon encontré la solución perfecta para gestionar mi vehículo. El proceso es rápido y sencillo, con una atención al cliente excepcional. Me siento seguro sabiendo que tengo todo en regla y actualizado. ¡Recomendaría Pitzon a cualquiera que busque eficiencia!',
    },
    {
      img: '../../../../assets/images/testimony/test5.jpg',
      titl: 'Luis Estrella',
      subtitle:
        'Pitzon me ha facilitado mucho la vida. Ahora puedo llevar el control de mis trámites sin complicaciones y desde la comodidad de mi hogar. ¡Es una plataforma confiable y muy fácil de usar!',
    },
    {
      img: '../../../../assets/images/testimony/testi3.jpg',
      titl: 'Diego Cortes',
      subtitle:
        'La experiencia con Pitzon ha sido excelente. Su sistema es claro y eficiente, y me ahorró mucho tiempo en comparación con los procesos tradicionales. ¡Muy recomendado!',
    },
    {
      img: '../../../../assets/images/testimony/testi4.jpg',
      titl: 'Yulian Basto',
      subtitle:
        'Gracias a Pitzon pude solucionar todo lo relacionado con mi vehículo sin estrés. La interfaz es intuitiva y el soporte siempre está disponible para ayudar. ¡Un servicio de primera!',
    },
    {
      img: '../../../../assets/images/testimony/testi6.jpg',
      titl: 'Bryhan Stif',
      subtitle:
        'Gracias a Pitzon pude solucionar todo lo relacionado con mi vehículo sin estrés. La interfaz es intuitiva y el soporte siempre está disponible para ayudar. ¡Un servicio de primera!',
    },
    {
      img: '../../../../assets/images/testimony/testi2.jpg',
      titl: 'Juan Esteban Bueno',
      subtitle:
        'Gracias a Pitzon pude solucionar todo lo relacionado con mi vehículo sin estrés. La interfaz es intuitiva y el soporte siempre está disponible para ayudar. ¡Un servicio de primera!',
    },
    {
      img: '../../../../assets/images/testimony/testi7.jpg',
      titl: 'Angelica Daniela',
      subtitle:
        'Gracias a Pitzon pude solucionar todo lo relacionado con mi vehículo sin estrés. La interfaz es intuitiva y el soporte siempre está disponible para ayudar. ¡Un servicio de primera!',
    },
    {
      img: '../../../../assets/images/testimony/testi8.jpg',
      titl: 'Sergio Ruben',
      subtitle:
        'Gracias a Pitzon pude solucionar todo lo relacionado con mi vehículo sin estrés. La interfaz es intuitiva y el soporte siempre está disponible para ayudar. ¡Un servicio de primera!',
    },
  ];

  pauseMarquee(): void {
    this.isPaused = true; // Pausa la animación
  }

  resumeMarquee(): void {
    this.isPaused = false; // Reanuda la animación
  }
}
