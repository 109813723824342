import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { HomeComponent } from './home/home.component';
import { MapComponent } from './map/map.component';
import { SharedModule } from './../../shared.module';
import { WorkshopPhotosComponent } from './workshop-photos/workshop-photos.component';

@NgModule({
  declarations: [HomeComponent, MapComponent, WorkshopPhotosComponent],
  providers: [],
  imports: [CommonModule, GoogleMapsModule, SharedModule],
  exports: [CommonModule, GoogleMapsModule, HomeComponent, MapComponent, WorkshopPhotosComponent],
})
export class ComponentsModule {}
