import { WorkShopDTO } from './../../shared/dto/work-shop.dto';
// import { AppConfig } from './../../config';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CoreService } from '../../shared/service/app/core.service';
import { MessageChatDTO } from './../../shared/dto/message-chat.dto';
import { v4 as uuidv4 } from 'uuid';
import { CommonService } from './../../shared/service/common/common.service';
import moment from 'moment';
import { AssistantService } from './service/assistant.service';
import { initialMessage } from './../../shared/constants/message.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CoordinatesDTO } from 'src/app/shared/dto/common.dto';

const slideDownUp = trigger('slideDownUp', [
  state('void', style({ opacity: 0, transform: 'translateY(-20px)' })),
  state('*', style({ opacity: 1, transform: 'translateY(0)' })),
  transition('void => *', animate('200ms ease-in')),
  transition('* => void', animate('200ms ease-out')),
]);

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.css'],
  animations: [slideDownUp],
})
export class AssistantComponent implements OnInit {
  @ViewChild('scrollable') scrollable!: NgScrollbar;
  @Input() selectedService: string = '';

  fechaActual: string = moment().format('YYYY-MM-DD');
  isMobile: boolean = false;
  cordenadas: CoordinatesDTO = { lat: 0, lng: 0 };
  isMapVisible: boolean = false;
  isSmallScreen: boolean = false;
  textMessage: string = '';
  loadingConversation: boolean = false;
  messages: MessageChatDTO[] = [];
  chatId: string = '';
  myIp: string = '';
  message: MessageChatDTO | undefined;
  ip: string = '';
  workShop: WorkShopDTO | null = null;
  openAiData = {
    id: '',
    assistant_id: '',
    run_id: '',
    thread_id: '',
  };

  constructor(
    public store: Store<any>,
    public core: CoreService,
    public commonService: CommonService,
    private router: Router,
    private assistantService: AssistantService,
    private route: ActivatedRoute,
  ) {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.selectedService = params['service'];
    });
    this.route.queryParams.subscribe((params) => {
      const lat = Number(params['lat']); // Convertir directamente a número
      const lng = Number(params['lng']);

      if (!isNaN(lat) && !isNaN(lng)) {
        this.cordenadas = { lat, lng };
        console.log('DESDE EL ASISTENTE Posición obtenida:', this.cordenadas);
      } else {
        console.error('Los parámetros no son números válidos.');
      }
    });
    this.checkScreenSize();
    this.startConversation();
    console.log('Servicio recibido:', this.selectedService);
  }
  // @HostListener('window:resize', [])
  // onResize(): void {
  //   this.checkScreenSize(); // Actualiza el estado si cambia el tamaño de la pantalla
  // }

  checkScreenSize(): void {
    // debugger;
    const width = localStorage.getItem('screenWidth') ?? 0;
    this.isSmallScreen = Number(width) <= 900; // Ajusta el valor según el tamaño deseado
    console.log('isSmallScreen:', this.isSmallScreen, width);
    // this.cdr.detectChanges();
  }

  toggleMap() {
    this.isMapVisible = !this.isMapVisible; // Alterna entre chat y mapa
  }
  async startConversation() {
    this.loadingConversation = true;
    try {
      const senderLocal = localStorage.getItem('sender');
      if (senderLocal) {
        this.openAiData = JSON.parse(senderLocal);
        this.chatId = localStorage.getItem('chat') || '';
      } else {
        this.chatId = uuidv4();
        this.ip = await this.commonService.getIPAddress();
        if (this.chatId && this.chatId !== '') {
          this.buildMessage('', this.ip);
          const sender = await this.assistantService.createThread(this.message!);
          this.openAiData!.id = sender.id;
          this.openAiData!.assistant_id = sender.assistant_id;
          this.openAiData!.run_id = sender.run_id;
          this.openAiData!.thread_id = sender.thread_id;
          // localStorage.setItem('sender', JSON.parse(JSON.stringify(this.openAiData)));
          // localStorage.setItem('chat', this.chatId);
          this.messages.push(sender);
          if (this.selectedService !== '' && this.selectedService !== undefined) {
            this.buildMessage(this.selectedService, this.ip);

            // Modificar el texto del mensaje antes de agregarlo
            this.message!.content[0].text.value = `Necesito un Servicio de ${this.message!.content[0].text.value}`;

            // Agregar el mensaje a la lista de mensajes antes de enviar la solicitud
            this.messages.push({ ...this.message! });

            // Enviar la solicitud al backend
            const resultSend = await this.assistantService.sendMessage(this.message!);

            // Agregar la respuesta del backend a la lista de mensajes
            this.messages.push(resultSend);

            // Desplazar el chat hacia abajo
            this.scrollToBottom();
          }
        }
      }
    } catch (error: any) {
      console.error('Error starting conversation:', error);
      this.buildMessage('Existe un error al contactar con el asistente, por favor intrentelo de nuevo mas tarde.', '');
      if (this.message) {
        this.messages.push(this.message);
      }
    }
    this.loadingConversation = false;
  }

  private buildMessage(message: string, ip: string) {
    this.message = JSON.parse(JSON.stringify(initialMessage));
    if (this.message) {
      this.message.session_id = this.chatId;
      this.message.created_at = moment().utc().unix();
      this.message.metadata!.source = 'fe-web';
      this.message.metadata!.ip = ip;
      this.message.metadata!.userAgent = navigator.userAgent;
      this.message.id = this.openAiData!.id;
      this.message.assistant_id = this.openAiData!.assistant_id;
      this.message.run_id = this.openAiData!.run_id;
      this.message.thread_id = this.openAiData!.thread_id;

      if (message !== '') {
        this.message.content[0].text.value = message;
      }
    }
  }

  async sendMessage() {
    try {
      if (this.textMessage.trim() === '') {
        return;
      }
      this.buildMessage(this.textMessage, this.ip);
      this.messages.push({ ...this.message! });
      const myMessage = JSON.parse(JSON.stringify(this.message));
      this.textMessage = '';
      this.scrollToBottom();
      this.loadingConversation = true;
      console.log(this.workShop);
      console.log('agregar', myMessage!.content[0].text.value.includes('Quiero agendar una cita'));
      if (myMessage!.content[0].text.value.includes('Quiero agendar una cita')) {
        myMessage!.content[0].text.value += ` (Ten en cuenta el workShopName=${this.workShop?.companyName}, workShopId=${this.workShop?._id}, workShopTuulAppId=${this.workShop?.id_tuulap})`;
        console.log('se envia ', myMessage!.content[0].text.value);
      }
      const resultSend = await this.assistantService.sendMessage(myMessage!);
      this.messages.push(resultSend);
      this.scrollToBottom();
    } catch (error) {
      console.error('Error starting conversation:', error);
      this.buildMessage('Existe un error al contactar con el asistente, por favor intrentelo de nuevo mas tarde.', '');
      if (this.message) {
        this.messages.push(this.message);
      }
    }
    this.loadingConversation = false;
  }

  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.scrollable.scrollTo({ bottom: 0, duration: 200 });
      } catch (error) {
        throw error;
      }
    }, 300);
  }

  assistantSchedule(workShop: WorkShopDTO | null) {
    this.workShop = workShop!;
    this.textMessage = `Quiero agendar una cita en ${workShop!.companyName}`;
    this.sendMessage();
    this.toggleMap();
    // Change to assistant
    this.core.toggleToMap(false);
  }
  backToHome() {
    if (this.cordenadas.lat === 0 && this.cordenadas.lng === 0) {
      this.router.navigate(['']);
    } else {
      this.router.navigate([''], { queryParams: { lat: this.cordenadas.lat, lng: this.cordenadas.lng } });
    }
  }
}
