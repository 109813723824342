import { Component } from '@angular/core';
import { AnalitycsService } from 'src/app/shared/service/analitycs.service';
import moment from 'moment';
import { AvataresService } from 'src/app/shared/service/avatares/avatares.service';
@Component({
  selector: 'app-home-profile',
  templateUrl: './home-profile.component.html',
  styleUrl: './home-profile.component.css',
})
export class HomeProfileComponent {
  selectedAvatar!: string; // Avatar predeterminado (Juan)
  isModalOpen: boolean = false;

  constructor(
    private analitycs: AnalitycsService,
    private avatareservices: AvataresService,
  ) {
    // do nothing
  }

  options: string[] = [
    'Argentina',
    'Bolivia',
    'Brasil',
    'Canadá',
    'Chile',
    'Costa Rica',
    'Cuba',
    'Ecuador',
    'El Salvador',
    'España',
    'Estados Unidos',
    'Guatemala',
    'Honduras',
    'México',
    'Nicaragua',
    'Panamá',
    'Paraguay',
    'Perú',
    'República Dominicana',
    'Uruguay',
    'Venezuela',
  ];
  avatars: string[] = [
    '../../../assets/images/avat/1.jpg',
    '../../../assets/images/avat/2.jpg',
    '../../../assets/images/avat/3.jpg',
    '../../../assets/images/avat/4.jpg',
    '../../../assets/images/avat/5.jpg',
    '../../../assets/images/avat/6.jpg',
    '../../../assets/images/avat/7.jpg',
    '../../../assets/images/avat/8.jpg',
    '../../../assets/images/avat/9.jpg',
    '../../../assets/images/avat/10.jpg',
    '../../../assets/images/avat/11.jpg',
    '../../../assets/images/avat/12.jpg',
    '../../../assets/images/avat/13.jpg',
  ];
  avatares: any[] = [];
  ngOnInit() {
    this.analitycs.logEvent('InitialHomeProfile', {
      view: 'Home-profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.selectedAvatar = localStorage.getItem('selectedAvatar') ?? 'assets/images/juan1.png';
    // this.selectAvatar = storedAvatar ? storedAvatar : 'assets/images/juan1.png';
    this.avatareservices.getAvatares().subscribe((avatares) => {
      for (const avatar of avatares) {
        this.avatares.push(avatar.img_avatar);
      }
      console.log(this.avatares);
    });
  }

  openAvatarModal() {
    this.analitycs.logEvent('OPEN_MODAL', {
      view: 'edit-profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.isModalOpen = true;
  }

  closeAvatarModal() {
    this.analitycs.logEvent('CLOSE_MODAL', {
      view: 'HOME-profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.isModalOpen = false;
  }

  selectAvatar(avatar: string) {
    this.analitycs.logEvent('SELECT_AVATAR', {
      view: 'HOME-profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.selectedAvatar = avatar;
    localStorage.setItem('selectedAvatar', avatar);
    // this.closeAvatarModal();
    // Cierra el modal después de seleccionar
  }
}
