<div>
    <div class="absolute inset-0">
        <img src="../../../../assets/images/notification-bg.png" alt="image" class="h-full w-full object-cover" />
    </div>
    <div
        class="relative flex min-h-screen items-center justify-center bg-[url(/assets/images/auth/map.png)] bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16"
    >
        <img src="../../../../assets/images/notification-bg.png" alt="image" class="absolute left-0 top-1/2 h-full max-h-[893px] -translate-y-1/2" />
        <img src="../../../../assets/images/notification-bg.png" alt="image" class="absolute left-24 top-0 h-40 md:left-[30%]" />
        <img src="../../../../assets/images/notification-bg.png" alt="image" class="absolute right-0 top-0 h-[300px]" />
        <img src="../../../../assets/images/notification-bg.png" alt="image" class="absolute bottom-0 end-[28%]" />
        <div
            class="relative flex w-full max-w-[1502px] flex-col justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[500px] lg:flex-row lg:gap-10 xl:gap-0"
        >
        <div
        class="relative hidden w-full items-center justify-center bg-[linear-gradient(225deg,#00022e_100%)]
        lg:inline-flex lg:max-w-[835px] xl:-ms-28 ltr:xl:skew-x-[14deg] rtl:xl:skew-x-[-14deg]"
    >
        <div
            class="absolute inset-y-0 w-8 from-primary/100 via-transparent to-transparent ltr:-right-10 ltr:bg-gradient-to-br rtl:-left-10 rtl:bg-gradient-to-l xl:w-16 ltr:xl:-right-20 rtl:xl:-left-20"
        ></div>
        <div class="ltr:xl:-skew-x-[13deg] rtl:xl:skew-x-[14deg] w-full h-full">
          <!-- <img src="../../../../assets/images/logo-22.png" alt="Logo" class="absolute z-10 w-6/12 pl-36 animate__animated animate__fadeIn animate__delay-1s" /> -->
          
          <div class="hidden w-full h-full lg:block pl-14">
              <img src="../../../../assets/images/pit.jpg" alt="Cover Image" class="w-full pl-16 trapezoid animate__animated animate__fadeIn animate__delay-1s" />
          </div>
      </div>
      
    </div>
    <div class="relative flex w-full flex-col items-center justify-center px-4 sm:px-6 lg:max-w-[667px]">
        <div class="flex w-full max-w-[440px] items-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
            <a routerLink="/" class="block w-8 lg:hidden">
                <img src="../../../assets/images/notification-bg.png" alt="Logo" class="mx-auto w-10" />
                    </a>
                    <div class="dropdown ms-auto w-max">
                        <div hlMenu>
                            <button
                                type="button"
                                hlMenuButton
                                class="flex items-center gap-2.5 rounded-lg border border-white-dark/30 bg-white px-2 py-1.5 text-white-dark hover:border-primary hover:text-primary dark:bg-black"
                            >
                                <div>
                                    <img
                                    [src]="store.locale === 'es' ? '/assets/images/flags/CO.svg' : '/assets/images/flags/' + store.locale.toUpperCase() + '.svg'"
                                    alt="image"
                                    class="h-5 w-5 rounded-full object-cover"
                                    />
                                </div>
                                <div class="text-base font-bold uppercase">{{ store.locale }}</div>
                                <span class="shrink-0">
                                    <icon-caret-down />
                                </span>
                            </button>
                            <ul
                                *hlMenuItems
                                @toggleAnimation
                                class="top-11 grid w-[280px] grid-cols-2 gap-2 !px-2 font-semibold text-dark ltr:-right-14 rtl:-left-14 dark:text-white-dark dark:text-white-light/90 sm:ltr:-right-2 sm:rtl:-left-2"
                            >
                                <li *ngFor="let item of store.languageList;">
                                    <button
                                        *hlMenuItem="let menuItem"
                                        type="button"
                                        class="w-full hover:text-primary"
                                        [ngClass]="{ 'bg-primary/10 text-primary': translate.currentLang === item.code }"
                                        (click)="changeLanguage(item)"
                                    >
                                        <img
                                            class="h-5 w-5 rounded-full object-cover"
                                            [src]="item.code === 'es' ? '/assets/images/flags/CO.svg' : '/assets/images/flags/' + item.code.toUpperCase() + '.svg'"
                                            alt=""
                                        />
                                        <span class="ltr:ml-3 rtl:mr-3">{{ item.name }}</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="w-full max-w-[440px] lg:mt-16">
                    <div class="mb-10">
                        <h1 class="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl animate__animated animated__delay-1s animate__zoomInDown">
                            {{ 'REGISTER.TITLE' | translate }}</h1>
                        <p class="text-base font-bold leading-normal text-white-dark animate__animated animated__delay-1s animate__slideInUp">{{ 'REGISTER.SUBTITLE' | translate }} <icon-login class="inline-block text-primary animate__animated animate__fadeInTopRight animated__delay-1s" /> </p>
                    </div>
                    <form class="space-y-2 dark:text-white">
                        <!-- Nombre y Apellido (50% y 50%) -->
                        <div class="flex space-x-4">
                          <div class="mitad w-1/2">
                            <label for="Name">{{ 'REGISTER.NAME' | translate }}</label>
                            <div class="relative text-white-dark">
                              <input id="Name" type="text" placeholder="{{ 'REGISTER.NAME' | translate }}" class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s" />
                              <span class="absolute start-4 top-1/2 -translate-y-1/2">
                                <icon-user class="text-primary" [fill]="true" />
                              </span>
                            </div>
                          </div>
                          <div class="mitad w-1/2">
                            <label for="LastName">{{ 'REGISTER.LASTNAME' | translate }}</label>
                            <div class="relative text-white-dark">
                              <input id="LastName" type="text" placeholder="{{ 'REGISTER.LASTNAME' | translate }}" class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s" />
                              <span class="absolute start-4 top-1/2 -translate-y-1/2">
                                <icon-users class="text-primary" [fill]="true" />
                              </span>
                            </div>
                          </div>
                        </div>
                      
                        <!-- Email (100%) -->
                        <div>
                          <label for="Email">{{ 'REGISTER.EMAIL' | translate }}</label>
                          <div class="relative text-white-dark">
                            <input id="Email" type="email" placeholder="{{ 'REGISTER.ENTEREMAIL' | translate }}" class="form-input ps-10 placeholder:text-white-dark animate__animated animated__delay-1s animate__fadeInTopLeft" />
                            <span class="absolute start-4 top-1/2 -translate-y-1/2">
                              <icon-mail class="text-primary" [fill]="true" />
                            </span>
                          </div>
                        </div>
                      
                        <!-- Cédula y Teléfono (50% y 50%) -->
                        <div class="flex space-x-4">
                          <div class="mitad w-1/2">
                            <label for="Cedula">{{ 'REGISTER.ID' | translate }}</label>
                            <div class="relative text-white-dark">
                              <input id="Cedula" type="text" placeholder="{{ 'REGISTER.ID' | translate }}" class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s" />
                              <span class="absolute start-4 top-1/2 -translate-y-1/2">
                                <icon-menu-documentation class="text-primary" [fill]="true" />
                              </span>
                            </div>
                          </div>
                          <div class="mitad w-1/2">
                            <label for="Telefono">{{ 'REGISTER.PHONE' | translate }}</label>
                            <div class="relative text-white-dark">
                              <input id="Telefono" type="text" placeholder="{{ 'REGISTER.ENTERPHONE' | translate }}" class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s" />
                              <span class="absolute start-4 top-1/2 -translate-y-1/2">
                                <icon-phone class="text-primary" [fill]="true" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- contrasena y confirmar contrasena (50% y 50%) -->
                        <div class="flex space-x-4">
                          <div class="mitad w-1/2">
                            <label for="Password">{{ 'REGISTER.PASSWORD' | translate }}</label>
                            <div class="relative text-white-dark">
                              <input id="Password" type="password" placeholder="{{ 'REGISTER.ENTERPASSWORD' | translate }}" class="form-input ps-10 placeholder:text-white-dark animate__animated animated__delay-1s animate__fadeInTopLeft" />
                              <span class="absolute start-4 top-1/2 -translate-y-1/2">
                                <icon-lock-dots class="text-primary" [fill]="true" />
                              </span>
                            </div>
                          </div>
                          <div class="mitad w-1/2">
                            <label for="ConfirmPassword">{{ 'REGISTER.CONFIRMPASSWORD' | translate }}</label>
                            <div class="relative text-white-dark">
                              <input id="ConfirmPassword" type="password" placeholder="{{ 'REGISTER.CONFIRMPASSWORD' | translate }}" class="form-input ps-10 placeholder:text-white-dark animate__animated animated__delay-1s animate__fadeInTopLeft" />
                              <span class="absolute start-4 top-1/2 -translate-y-1/2">
                                <icon-lock-dots class="text-primary" [fill]="true" />
                              </span>
                            </div>
                          </div>
                        </div>
                      
                        <!-- Placa del Vehículo (100%) -->
                        <div>
                          <label for="PlacaVehiculo">{{ 'REGISTER.PLATE' | translate }}</label>
                          <div class="relative text-white-dark">
                            <input id="PlacaVehiculo" type="text" placeholder="{{ 'REGISTER.ENTERPLATE' | translate }}" class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s" />
                            <span class="absolute start-4 top-1/2 -translate-y-1/2">
                              <icon-share class="text-primary" [fill]="true" />
                            </span>
                          </div>
                        </div>
                      
                        <!-- Modelo (100%) -->
                        <div>
                          <label for="Modelo">{{ 'REGISTER.MODEL' | translate }}</label>
                          <div class="relative text-white-dark">
                            <input id="Modelo" type="text" placeholder="{{ 'REGISTER.ENTERMODEL' | translate }}" class="form-input ps-10 placeholder:text-white-dark animate__animated animated__delay-1s animate__fadeInTopLeft" />
                            <span class="absolute start-4 top-1/2 -translate-y-1/2">
                              <icon-share class="text-primary" [fill]="true" />
                            </span>
                          </div>
                        </div>
                      
                        <!-- Términos y Condiciones -->
                        <div>
                          <label class="flex cursor-pointer items-center">
                            <input type="checkbox" class="form-checkbox bg-white dark:bg-black" />
                            <span class="text-white-dark">{{ 'REGISTER.CONDITIONS' | translate }}</span>
                          </label>
                        </div>
                      
                        <!-- Botón de Registro -->
                        <button type="submit" (click)="openModal()" class="btn btn-primary !mt-6 w-full border-0 uppercase text-black shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] animate-pulse">
                          {{ 'REGISTER.BUTTON' | translate }}
                        </button>
                      </form>
                      <app-comfirmation-message *ngIf="isModalVisible"></app-comfirmation-message>
                    </div>
                    <div class="relative my-7 text-center md:mb-9">
                        <span class="absolute inset-x-0 top-1/2 h-px w-full -translate-y-1/2 bg-white-light dark:bg-white-dark"></span>
                        <span class="relative bg-white px-2 font-bold uppercase text-white-dark dark:bg-dark dark:text-white-light">{{ 'REGISTER.OR' | translate }}</span>
                    </div>
                    <div class="mb-10 md:mb-[60px]">
                        <ul class="flex justify-center gap-3.5 text-white">
                            <li>
                                <a
                                    href="https://www.instagram.com/innocarconsulting/"
                                    class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110 animate__animated animate__delay-1s animate__zoomInUp"
                                    style="background: linear-gradient(135deg, #ff5801 0%, #00022e 120%)"
                                >
                                    <icon-instagram />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/innocarconsulting"
                                    class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110 animate__animated animate__delay-1s animate__zoomInUp"
                                    style="background: linear-gradient(135deg, #ff5801 0%, #00022e 120%)"                                >
                                    <icon-facebook-circle />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://x.com/Juancamoller"
                                    class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110 animate__animated animate__delay-1s animate__zoomInUp"
                                    style="background: linear-gradient(135deg, #ff5801 0%, #00022e 120%)"                                >
                                    <icon-x [fill]="true" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/innocar-sas/?originalSubdomain=co"
                                    class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110 animate__animated animate__delay-1s animate__zoomInUp"
                                    style="background: linear-gradient(135deg, #ff5801 0%, #00022e 120%)"                                >
                                    <icon-linkedin />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="text-center dark:text-white animate__animated animate__delay-1s animate__flipInY">
                        {{ 'REGISTER.LOGIN' | translate }}
                        <a routerLink="../login" class="uppercase text-primary underline transition hover:text-black dark:hover:text-white animate__animated animate__delay-1s animate__flipInY">
                           {{ 'REGISTER.LINK' | translate }}
                        </a>
                        <p class="w-full text-center pb-5 text-black dark:text-white animate__animated animate__delay-1s animate__flipInY">© {{ currYear }} PITZON.{{ 'HOME.FOOTER' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>