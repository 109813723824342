import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { AnalitycsService } from 'src/app/shared/service/analitycs.service';

@Component({
  selector: 'app-ods',
  templateUrl: './ods.component.html',
  styleUrl: './ods.component.css',
})
export class OdsComponent {
  isDarkMode = false;
  store: any;
  selectedAvatar: string | null = null;

  constructor(
    private router: Router,
    private storeData: Store<any>,
    private analitycs: AnalitycsService,
  ) {
    this.analitycs.logEvent('InitialProfile', {
      view: 'history-vehicle',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    localStorage.setItem('light', 'dark');
    this.storeData
      .select((state) => state.index.theme)
      .subscribe((theme) => {
        this.isDarkMode = theme === 'dark';
      });
  }
  ngOnInit() {
    this.analitycs.logEvent('InitialProfile', {
      view: 'history-vehicle',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.selectedAvatar = localStorage.getItem('selectedAvatar') ?? 'assets/images/juan2.png';
    // const storedAvatar = localStorage.getItem('selectedAvatar'); // Obtiene el avatar guardado
    // if (storedAvatar) {
    //   this.selectedAvatar = storedAvatar; // Si existe, se lo asignamos
    // } else {
    //   this.selectedAvatar = 'assets/images/juan1.png'; // Opción predeterminada (Juan)
    // }
  }

  goToEditProfile() {
    this.analitycs.logEvent('GoToEditProfile', {
      view: 'history-vehicle',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['/edit-profile']);
  }
}
