import { CoordinatesDTO } from './../../shared/dto/common.dto';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { slideDownUp } from 'src/app/shared/animations';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [slideDownUp],
})
export class HomeComponent implements OnInit {
  // @Output() serviceSelected: EventEmitter<string> = new EventEmitter();
  cordenadas: CoordinatesDTO = { lat: 0, lng: 0 };
  isMobile: boolean = false;
  isMapVisible: boolean = false;
  isSmallScreen: boolean = true;
  currYear: number = new Date().getFullYear();
  services: string[] = [];
  isDarkMode: boolean = false;
  store: any;
  loading: boolean = true;
  // storeData: any;
  // counter = signal<number>(0);

  constructor(
    private storeData: Store<any>,
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    this.setTranslatedServices();
    this.checkIfMobile();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const lat = Number(params['lat']); // Convertir directamente a número
      const lng = Number(params['lng']);

      if (!isNaN(lat) && !isNaN(lng)) {
        this.cordenadas = { lat, lng };
        console.log('Posición obtenida:', this.cordenadas);
      } else {
        console.error('Los parámetros no son números válidos.');
      }
    });

    // this.store.dispatch({ type: 'deviceSize', payload: innerWidth });
    localStorage.setItem('screenWidth', innerWidth.toString());
    console.log(innerWidth, innerHeight);
    this.checkIfMobile();
    this.storeData
      .select((state) => state.index.theme)
      .subscribe((theme) => {
        this.isDarkMode = theme === 'dark';
      });
    this.checkScreenSize();
    setTimeout(() => {
      this.loading = false;
    }, 3500);
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 768;
    this.isSmallScreen = window.innerWidth <= 770;
  }
  checkScreenSize(): void {
    // debugger;
    const width = localStorage.getItem('screenWidth') ?? 0;
    this.isSmallScreen = Number(width) <= 900 ? true : false; // Ajusta el valor según el tamaño deseado
    console.log('isSmallScreen:', this.isSmallScreen);
    // this.cdr.detectChanges();
  }

  toggleMap() {
    this.isMapVisible = !this.isMapVisible; // Alterna entre inicio y mapa
  }

  setTranslatedServices() {
    this.translate
      .get([
        'HOME.SERVICES.OIL_CHANGE',
        'HOME.SERVICES.TIRE_CHECK',
        'HOME.SERVICES.SUSPENSION_INSPECTION',
        'HOME.SERVICES.CARBURETOR_ADJUSTMENT',
        'HOME.SERVICES.BRAKE_CHECK',
        'HOME.SERVICES.TIRE_ROTATION',
        'HOME.SERVICES.FLUID_CHECK',
        'HOME.SERVICES.CLUTCH_ADJUSTMENT',
        'HOME.SERVICES.LIGHT_CHECK',
        'HOME.SERVICES.PREVENTIVE_MAINTENANCE',
        'HOME.SERVICES.PAD_CHANGE',
        'HOME.SERVICES.ELECTRONIC_DIAGNOSTIC',
        'HOME.SERVICES.CAR_WASH_WAX',
        'HOME.SERVICES.BATTERY_REPLACEMENT',
        'HOME.SERVICES.ENGINE_REPAIR',
      ])
      .subscribe((translations) => {
        this.services = [
          translations['HOME.SERVICES.OIL_CHANGE'],
          translations['HOME.SERVICES.TIRE_CHECK'],
          translations['HOME.SERVICES.SUSPENSION_INSPECTION'],
          translations['HOME.SERVICES.CARBURETOR_ADJUSTMENT'],
          translations['HOME.SERVICES.BRAKE_CHECK'],
          translations['HOME.SERVICES.TIRE_ROTATION'],
          translations['HOME.SERVICES.FLUID_CHECK'],
          translations['HOME.SERVICES.CLUTCH_ADJUSTMENT'],
          translations['HOME.SERVICES.LIGHT_CHECK'],
          translations['HOME.SERVICES.PREVENTIVE_MAINTENANCE'],
          translations['HOME.SERVICES.PAD_CHANGE'],
          translations['HOME.SERVICES.ELECTRONIC_DIAGNOSTIC'],
          translations['HOME.SERVICES.CAR_WASH_WAX'],
          translations['HOME.SERVICES.BATTERY_REPLACEMENT'],
          translations['HOME.SERVICES.ENGINE_REPAIR'],
        ];
      });
  }
  goToAssistant() {
    this.router.navigate(['/assistant'], {
      queryParams: this.cordenadas.lat === 0 && this.cordenadas.lng === 0 ? {} : { lat: this.cordenadas.lat, lng: this.cordenadas.lng },
    });
  }

  chatAssistant(service: string) {
    if (this.cordenadas.lat === 0 && this.cordenadas.lng === 0) {
      this.router.navigate(['/assistant'], { queryParams: { service } });
    } else {
      this.router.navigate(['/assistant'], { queryParams: { service, lat: this.cordenadas.lat, lng: this.cordenadas.lng } });
    }
  }
}
