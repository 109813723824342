import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private readonly secretKey = environment.secrets.key;
  private readonly ivLength = Number(environment.secrets.ivLength);

  async encrypt(text: string): Promise<string> {
    const iv = window.crypto.getRandomValues(new Uint8Array(this.ivLength));
    const encodedText = new TextEncoder().encode(text);
    const key = await this.getKey();
    const encrypted = await window.crypto.subtle.encrypt(
      {
        name: 'AES-CBC',
        iv: iv,
      },
      key,
      encodedText,
    );

    return this.arrayBufferToHex(iv) + ':' + this.arrayBufferToHex(encrypted);
  }

  async decrypt(token: string): Promise<string> {
    const [ivHex, encryptedHex] = token.split(':');
    const iv = this.hexToArrayBuffer(ivHex);
    const encryptedData = this.hexToArrayBuffer(encryptedHex);
    const key = await this.getKey();

    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: 'AES-CBC',
        iv: iv,
      },
      key,
      encryptedData,
    );

    return new TextDecoder().decode(decrypted);
  }

  private async getKey(): Promise<CryptoKey> {
    const keyBuffer = this.base64ToArrayBuffer(this.secretKey);
    if (keyBuffer.byteLength !== 32) {
      throw new Error('Invalid key length. Key must be 32 bytes for AES-256.');
    }
    return window.crypto.subtle.importKey('raw', keyBuffer, { name: 'AES-CBC' }, false, ['encrypt', 'decrypt']);
  }

  private arrayBufferToHex(buffer: ArrayBuffer): string {
    return Array.from(new Uint8Array(buffer))
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
  }

  private hexToArrayBuffer(hex: string): ArrayBuffer {
    const byteArray = new Uint8Array(hex.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)));
    return byteArray.buffer;
  }

  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
