export const services: string[] = [
  'Cambio de aceite',
  'Revisión de neumáticos',
  'Inspección de suspensión',
  'Ajuste de carburadores',
  'Revisión y cambio de frenos',
  'Rotación de neumáticos',
  'Revisión de líquidos',
  'Ajuste de embrague',
  'Verificación de luces',
  'Mantenimientos preventivos',
  'Cambios de pastillas',
  'Diagnóstico electrónico',
  'Lavado y encerado',
  'Reemplazo de baterías',
  'Reparación de motores',
];

export const UserIconPositionConst: any = {
  url: 'assets/images/icon3.png',
  scaledSize: {
    width: 60,
    height: 60,
  },
};

export const WorkShopIconPositionConst: any = {
  url: 'assets/images/icon1.png',
  scaledSize: {
    width: 60,
    height: 60,
  },
};

export const MapOptionsConstants: any = {
  mapTypeControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};
