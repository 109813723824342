import { Component, ElementRef, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, NavigationEnd } from '@angular/router';
import { AppService } from '../shared/service/app/app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { toggleAnimation } from 'src/app/shared/animations';
import { AnalitycsService } from 'src/app/shared/service/analitycs.service';
import moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'header',
  templateUrl: './header.html',
  animations: [toggleAnimation],
})
export class HeaderComponent {
  store: any;
  search = false;
  isDropdownOpen = false;
  selectedAvatar: string | null = null;

  constructor(
    public translate: TranslateService,
    public storeData: Store<any>, //manejar el estado global
    public router: Router,
    private appSetting: AppService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private analitycs: AnalitycsService,
  ) {
    this.initStore();
  }
  async initStore() {
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }

  ngOnInit() {
    this.analitycs.logEvent('InitialHeader', {
      view: 'header',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    const storedAvatar = localStorage.getItem('selectedAvatar'); // Obtiene el avatar guardado
    if (storedAvatar) {
      this.selectedAvatar = storedAvatar; // Si existe, se lo asignamos
    } else {
      this.selectedAvatar = 'assets/images/juan1.png'; // Opción predeterminada (Juan)
    }
    this.setActiveDropdown();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveDropdown();
      }
    });
  }

  setActiveDropdown() {
    this.analitycs.logEvent('setActiveDropdown', {
      view: 'header',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    const selector = document.querySelector('ul.horizontal-menu a[routerLink="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add('active');
      const all: any = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
      for (let i = 0; i < all.length; i++) {
        all[0]?.classList.remove('active');
      }
      const ul: any = selector.closest('ul.sub-menu');
      if (ul) {
        let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link');
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele?.classList.add('active');
          });
        }
      }
    }
  }
  changeLanguage(item: any) {
    this.analitycs.logEvent('changeLanguage', {
      view: 'header',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.translate.use(item.code);
    this.appSetting.toggleLanguage(item);
    if (this.store.locale?.toLowerCase() === 'ae') {
      this.storeData.dispatch({ type: 'toggleRTL', payload: 'rtl' });
    } else {
      this.storeData.dispatch({ type: 'toggleRTL', payload: 'ltr' });
    }
  }
  toggleDropdown(): void {
    this.analitycs.logEvent('toggleDropdown', {
      view: 'header',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  gotoHome() {
    this.analitycs.logEvent('goToHome', {
      view: 'header',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['/']);
    this.toggleDropdown();
  }
  goToProfile() {
    this.analitycs.logEvent('goToProfile', {
      view: 'header',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['/profile']);
    this.toggleDropdown();
  }
  logout() {
    this.analitycs.logEvent('GoToLogin', {
      view: 'header',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['/login']);
    this.toggleDropdown();
  }
  GoToAssistance() {
    this.analitycs.logEvent('GoToAssistance', {
      view: 'header',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['/assistant']);
    this.toggleDropdown();
  }
  GoToCalendar() {
    this.router.navigate(['/calendar']);
    this.toggleDropdown();
  }
  // Detectar clics fuera del dropdown
  @HostListener('document:click', ['$event.target'])
  onClickOutside(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isDropdownOpen = false;
    }
  }
}
