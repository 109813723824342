import { Injectable } from '@angular/core';
import { WorkShopDTO } from './../../../shared/dto/work-shop.dto';
import { AxiosService } from './../../../core/axios';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private readonly axiosService: AxiosService) {
    // do nothing
  }

  async getAvailableCustomers(): Promise<WorkShopDTO[] | null> {
    console.log('DATA');
    return await this.axiosService.get('/workshops');
  }

  async getIPAddress(): Promise<string> {
    try {
      return new Promise((resolve) => {
        fetch('https://api.ipify.org?format=json')
          .then((response) => response.json())
          .then((data) => {
            resolve(data.ip);
          })
          .catch((error) => {
            console.log('EXCEPTION | getIPAddress - reject - ', error);
            resolve('');
          });
      });
    } catch (error: any) {
      console.error('EXCEPTION | getIPAddress - ', error);
      return '';
    }
  }
}
