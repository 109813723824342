<div>
    <ul class="flex space-x-2 rtl:space-x-reverse">
        <li>
            <a href="javascript:;" class="text-primary hover:underline">Dashboard</a>
        </li>
        <li class="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
            <span>Sales</span>
        </li>
    </ul>
    <div class="panel mt-5 h-[calc(100vh-190px)]">
        <h1>Starter Page</h1>
    </div>
</div>
