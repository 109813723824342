<div>
  <div class="absolute inset-0">
    <img src="../../../assets/images/notification-bg.png" alt="image" class="h-full w-full object-fill" />
  </div>
  <div
    class="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16"
  >
    <img src="../../../assets/images/notification-bg.png" alt="image" class="absolute left-0 top-1/2 h-full max-h-[893px] -translate-y-1/2" />
    <img src="../../../assets/images/notification-bg.png" alt="image" class="absolute left-24 top-0 h-40 md:left-[30%]" />
    <img src="../../../assets/images/notification-bg.png" alt="image" class="absolute right-0 top-0 h-[300px]" />
    <img src="../../../assets/images/notification-bg.png" alt="image" class="absolute bottom-0 end-[28%]" />
    <div
      class="relative flex w-full max-w-[1502px] flex-col justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[500px] lg:flex-row lg:gap-0 xl:gap-0"
    >
      <div
        class="relative hidden w-auto items-center justify-center bg-[linear-gradient(225deg,#00022e_100%)] lg:inline-flex lg:max-w-[835px] xl:-ms-28 ltr:xl:skew-x-[14deg] rtl:xl:skew-x-[-14deg]"
      >
        <div
          class="absolute inset-y-0 w-8 from-primary/100 via-transparent to-transparent ltr:-right-10 ltr:bg-gradient-to-br rtl:-left-10 rtl:bg-gradient-to-l xl:w-16 ltr:xl:-right-20 rtl:xl:-left-20"
        ></div>
        <div class="ltr:xl:-skew-x-[14deg] rtl:xl:skew-x-[14deg]-">
          <!-- <a routerLink="/" class="ms-10 block w-48 lg:w-72">
                        <img src="../../../assets/images/notification-bg.png" alt="Logo" class="w-full" />
                    </a>  -->
          <div class="hidden w-full h-full lg:block">
            <img [src]="currentImage" alt="Cover Image" class="w-full trapezoid animate__animated animate__fadeIn animate__delay-1s" />
            <!-- <img src="../../../../assets/images/pit2inglish.jpg" alt="Cover Image" class="w-full pl-14 trapezoid animate__animated animate__fadeIn animate__delay-1s" /> -->
          </div>
        </div>
      </div>
      <div class="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
        <div class="flex w-full max-w-[440px] items-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
          <a routerLink="/" class="block w-20 lg:hidden">
            <img *ngIf="isDarkMode === false" src="../../../assets/images/logo-22.png" alt="Logo" class="mx-auto w-20" />
            <img *ngIf="isDarkMode === true" src="../../../assets/images/logo-23.png" alt="Logo" class="mx-auto w-20" />
          </a>

          <app-languajes class="dropdown w-full"></app-languajes>
        </div>
        <div class="w-full max-w-[440px] lg:mt-16">
          <div class="mb-10">
            <h1 class="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl animate__animated animated__delay-1s animate__fadeInDown">
              {{ 'LOGIN.TITLE' | translate }}
            </h1>
            <p class="text-base font-bold leading-normal text-white-dark animate__animated animated__delay-1s animate__slideInUp">
              {{ 'LOGIN.SUBTITLE' | translate }} . <icon-login class="inline-block text-primary animate__animated animate__fadeInTopRight animated__delay-1s" />
            </p>
          </div>
          <form [formGroup]="loginForm" class="space-y-5 dark:text-white" (ngSubmit)="onSubmit()">
            <div>
              <label for="Email">{{ 'LOGIN.EMAIL' | translate }}</label>
              <div class="relative text-white-dark">
                <input
                  id="Email"
                  type="email"
                  formControlName="email"
                  placeholder="{{ 'LOGIN.ENTEREMAIL' | translate }}"
                  class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s"
                />
                <span class="absolute start-4 top-1/2 -translate-y-1/2">
                  <icon-mail class="text-primary animate__animated animated__delay-1s animate__fadeInTopLeft" [fill]="true"></icon-mail>
                </span>
              </div>
              <!-- Mensaje de error -->
              <div *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched" class="text-red-500">
                <small *ngIf="loginForm.get('email')?.errors?.['required']">
                  {{ 'LOGIN.EMAILREQUIRED' | translate }}
                </small>
                <small *ngIf="loginForm.get('email')?.errors?.['email']">
                  {{ 'LOGIN.INVALIDEMAIL' | translate }}
                </small>
              </div>
            </div>

            <div>
              <label for="Password">{{ 'REGISTER.PASSWORD' | translate }}</label>
              <div class="relative text-white-dark">
                <input
                  id="Password"
                  type="password"
                  formControlName="password"
                  placeholder="{{ 'REGISTER.ENTERPASSWORD' | translate }}"
                  class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s"
                />
                <span class="absolute start-4 top-1/2 -translate-y-1/2">
                  <icon-lock-dots class="text-primary animate__animated animated__delay-1s animate__fadeInTopLeft" [fill]="true"></icon-lock-dots>
                </span>
              </div>
              <!-- Mensaje de error -->
              <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched" class="text-red-500">
                <small *ngIf="loginForm.get('password')?.errors?.['required']">
                  {{ 'LOGIN.PASSWORDREQUIRED' | translate }}
                </small>
                <small *ngIf="loginForm.get('password')?.errors?.['minlength']">
                  {{ 'LOGIN.PASSWORDMINLENGTH' | translate }}
                </small>
              </div>
            </div>

            <div>
              <label class="flex cursor-pointer items-center">
                <input type="checkbox" formControlName="acceptTerms" class="form-checkbox bg-white dark:bg-black" />
                <span class="text-white-dark">{{ 'LOGIN.CONDITIONS' | translate }}</span>
              </label>
              <!-- Mensaje de error -->
              <div *ngIf="loginForm.get('acceptTerms')?.invalid && loginForm.get('acceptTerms')?.touched" class="text-red-500">
                <small>Debes aceptar los términos y condiciones.</small>
              </div>
            </div>

            <button
              type="submit"
              [disabled]="loginForm.invalid"
              class="btn btn-primary !mt-6 w-full border-0 uppercase text-black shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] animate-pulse"
            >
              {{ 'LOGIN.BUTTON' | translate }}
            </button>
          </form>
          <a
            (click)="modal13.open()"
            class="cursor-pointer text-primary underline transition hover:text-black dark:hover:text-white animate__animated animate__delay-1s animate__flipInY"
          >
            <p class="text-white-dark animate__animated animate__delay-1s animate__flipInY text-center">¿Olvidaste tu contraseña?</p>
          </a>
          <!-- MODAL RECUPERAR PASSWORD -->
          <div>
            <!-- Trigger -->
            <!-- <a type="button" class="btn btn-danger" (click)="modal13.open()">¿Olvidaste tu contraseña?</a> -->

            <!-- Modal -->
            <ngx-custom-modal
              #modal13
              [customClass]="store.rtlClass === 'rtl' ? 'modal-top animate animate-fade-in-right' : 'modal-center animate animate-fade-in-left'"
            >
              <ng-template #modalHeader>
                <div><img class="h-16 w-40" src="../../../../assets/images/logo-24.png" alt="" /> Recupera tu cuenta</div>
              </ng-template>
              <ng-template #modalBody>
                <p>
                  Protege tu contraseña, no la compartas con nadie. Usa combinaciones seguras y cámbiala regularmente. Refuerza tu seguridad con autenticación
                  en dos pasos. 🔒
                </p>
                <ul>
                  <li class="my-2">1. Ingresa tu correo electr&oacute;nico o n&uacute;mero de m&oacute;vil.</li>
                  <li class="my-2">2. Recibir&aacute;s un c&oacute;digo de verificaci&oacute;n.</li>
                  <li class="my-2">3. Ingresa el c&oacute;digo de verificaci&oacute;n en el campo de abajo.</li>
                  <li class="my-2">4. Haz clic en "Recuperar contrase&ntilde;a".</li>
                </ul>
                <input type="text" placeholder="Correo eletronico o número de telefono" class="w-full border-2 border-gray-500 rounded-lg px-4 py-2 mt-4" />
              </ng-template>
              <ng-template #modalFooter>
                <input type="password" placeholder="Codigo de verificacion" class="w-full border-2 border-gray-500 rounded-lg px-4 py-2" />
                <!-- <button type="button" (click)="modal13.close()" class="btn btn-outline-danger">Codigo de verificación</button> -->
                <button type="button" (click)="modal13.close()" class="btn btn-primary ltr:ml-4 rtl:mr-4">Recuperar Contraseña</button>
              </ng-template>
            </ngx-custom-modal>
          </div>
          <app-networks></app-networks>

          <div class="text-center mb-2 dark:text-white animate__animated animate__delay-1s animate__flipInY">
            {{ 'LOGIN.REGISTER' | translate }}
            <a
              (click)="GoToRegister()"
              class="uppercase cursor-pointer text-primary underline transition hover:text-black dark:hover:text-white animate__animated animate__delay-1s animate__flipInY"
            >
              {{ 'LOGIN.LINK' | translate }}
            </a>
          </div>
        </div>
        <p class="absolute bottom-6 w-full text-center dark:text-white animate__animated animate__delay-1s animate__flipInY">
          © {{ currYear }} PITZON. {{ 'HOME.FOOTER' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
