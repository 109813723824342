<div class="bg-azulOscuro">
    <!-- <header class="relative"></header>
      
      <ul class="absolute pointers top-0 left-0 z-50 flex space-x-2 rtl:space-x-reverse p-4">
        <li>
          <a 
            (click)="goBack()" 
            class="text-primary cursor-pointer hover:underline animate__animated animate__bounceInLeft animate__delay-1s">
            {{ 'EDIT-PROFILE.PROFILE' | translate }}
          </a>
        </li>
        <li class="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-white dark:text-white">
          <span class="text-white dark:text-white animate__animated animate__bounceInRight animate__delay-1s">
            {{ 'EDIT-PROFILE.EDIT' | translate }}
          </span>
        </li>
      </ul> -->
      
    <div class="bg-center pt-5 flex items-center justify-center animate__animated animate__fadeIn animate__delay-1s">
    <img *ngIf="isDarkMode === false" src="../../../../assets/images/logo-26.png" alt="img" class=" w-40 flex justify-center items-center animate__animated animate__fadeIn animate__delay-1s">
    <img *ngIf="isDarkMode === true" src="../../../../assets/images/logo-26.png" alt="img" class=" w-40 flex justify-center items-center animate__animated animate__fadeIn animate__delay-1s">
    </div>
    <div class="pt-2">
        <!-- <div class="mt-5 flex items-center justify-between">
            <h5 class="text-lg font-semibold ml-3 text-white animat__animated animate__bounceIn animate__delay-1s"> <span (click)="goBack()" class="text-white cursor-pointer hover:text-azulOscuro hover:underline animate__animated animate__bounceInLeft animate__delay-1s">{{ 'EDIT-PROFILE.gotoprofile' | translate }}</span> / 
                <span class="text-azulOscuro">Editar Perfil</span>
            </h5>
        </div> -->
        <!-- Navs -->
        <div class="mb-5 flex overflow-y-auto whitespace-nowrap border-b text-white border-white font-semibold animate__animated animate__slideInRight animate__delay-1s">
            <a
                (click)="goBack()"
                href="javascript:;"
                class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-azulOscuro hover:text-primary"
            >
                <icon-user />
                {{ 'EDIT-PROFILE.gotoprofile' | translate }}
            </a>
            <a
                href="javascript:;"
                class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-azulOscuro hover:text-primary"
                [ngClass]="{ '!border-white text-white':  activeTab.toLowerCase() === 'home'}"
                (click)="activeTab = 'home'"
            >
                <icon-home />
                {{ 'EDIT-PROFILE.START' | translate }}
            </a>
            <a
                href="javascript:;"
                class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-azulOscuro hover:text-primary"
                [ngClass]="{ '!border-white text-white':  activeTab.toLowerCase() === 'documentation'}"
                (click)="activeTab = 'documentation'"
            >
                <icon-dollar-sign-circle />
                {{ 'EDIT-PROFILE.DOCUMENTATION' | translate }}
            </a>
            <a
                href="javascript:;"
                class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-azulOscuro hover:text-primary"
                [ngClass]="{ '!border-white text-white':  activeTab.toLowerCase() === 'vehicle'}"
                (click)="activeTab = 'vehicle'"
            >
                <icon-checks class="h-5 w-5" />
                {{ 'EDIT-PROFILE.VEHICLE' | translate }}
            </a>
            <!-- <a
                href="javascript:;"
                class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
                [ngClass]="{ '!border-primary text-primary':  activeTab.toLowerCase() === 'danger-zone'}"
                (click)="activeTab = 'danger-zone'"
            >
                <icon-phone />
                Danger Zone
            </a> -->
        </div>
        <!-- End Navs -->
        <div>
            <div *ngIf="activeTab.toLowerCase() === 'home'">
                <app-home-profile></app-home-profile>
            </div>
            <!-- DOCUMENTATION -->
            <div *ngIf="activeTab.toLowerCase() === 'documentation'" class="switch text-black dark:text-white">
                <app-documentation></app-documentation>
              </div>
              
            <!-- VEHICLE -->
            <div *ngIf="activeTab.toLowerCase() === 'vehicle'" class="switch text-black dark:text-white">
                <app-vehicle></app-vehicle>
            </div>

            <!-- OTHER -->
            <div *ngIf="activeTab.toLowerCase() === 'danger-zone'" class="switch">
                <app-other></app-other>
            </div>
        </div>
    </div>
</div>
