<!-- Modal -->
<div>
    <ngx-custom-modal #modal19 customClass="modal-top profile-modal">
        <ng-template #modalBody>
            <div class="pb-2 pt-10 text-center text-white dark:text-white-light">
                <div class="mx-auto mb-7 h-20 w-20 overflow-hidden rounded-full">
                    <img src="../../../../../assets/images/juan1.png" alt="" class="h-full w-full object-cover" />
                </div>
                <h1>¡Hola Juan!</h1>
                <p class="text-sm font-semibold">
                    ¿Te gustaría verificar el pico y placa en tu ciudad?
                </p>
            </div>
        </ng-template>
        <ng-template #modalFooter>
            <!-- <app-platecity></app-platecity> -->
            <button (click)="gotovehicles()" type="button" class="btn dark:btn-dark bg-white text-black">Verificar</button>
        </ng-template>
    </ngx-custom-modal>
</div>
