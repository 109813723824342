import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  constructor() {
    // do nothing
  }

  async getCurrentLocation(): Promise<any> {
    try {
      if (navigator.geolocation) {
        return await this.getCurrentPosition();
      } else {
        throw 'Geolocation is not supported by this browser.';
      }
    } catch (error) {
      console.error('Error getting position:', error);
      return null;
    }
  }

  private getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position);
          },
          (error) => {
            reject(error);
          },
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  getLocationByIp(): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(`${environment.apiUrl}/messages/get_location`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
