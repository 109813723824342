<div class="panel">
    <div class="mb-5 flex items-center justify-between">
      <h5 class="text-lg font-semibold dark:text-white-light">{{ 'PROFILE.DOCUMENTSUPDATE' | translate }}</h5>
    </div>
    <div>
      <div class="border-b border-azulOscuro dark:border-[#1b2e4b]">
        <div class="flex items-center justify-between py-2">
          <h6 class="font-semibold text-white-dark dark:text-white-dark">
            {{ 'PROFILE.VIGENTDOCUMENT' | translate }} 
            <span class="block text-black dark:text-white-light">SOAT</span>
          </h6>
          <div class="flex items-start justify-between ltr:ml-auto rtl:mr-auto">
            <div class="dropdown ltr:ml-4 rtl:mr-4 text-primary">
              <div hlMenu>
                <a href="javascript:;" hlMenuButton class="align-middle" (click)="toggleDropdown('soat')">
                  <icon-horizontal-dots class="opacity-80 hover:opacity-100" />
                </a>
                <ul *ngIf="isDropdownOpen.soat" class="whitespace-nowrap ltr:right-0 rtl:left-0">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem">chevrolet spark</a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem">Ducati</a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem">BMW</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="border-b border-azulOscuro dark:border-[#1b2e4b]">
        <div class="flex items-center justify-between py-2">
          <h6 class="font-semibold text-white-dark dark:text-white-dark">
            {{ 'PROFILE.VIGENTDOCUMENT2' | translate }} 
            <span class="block text-black dark:text-white-light">TECNOMECANICA</span>
          </h6>
          <div class="flex items-start justify-between ltr:ml-auto rtl:mr-auto">
            <div class="dropdown ltr:ml-4 rtl:mr-4 text-primary">
              <div hlMenu>
                <a href="javascript:;" hlMenuButton class="align-middle" (click)="toggleDropdown('tecnomecanica')">
                  <icon-horizontal-dots class="opacity-80 hover:opacity-100" />
                </a>
                <ul *ngIf="isDropdownOpen.tecnomecanica" class="whitespace-nowrap ltr:right-0 rtl:left-0">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem">chevrolet spark</a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem">Ducati</a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem">BMW</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div>
        <div class="flex items-center justify-between py-2">
          <h6 class="font-semibold text-[#515365] dark:text-white-dark">
            <icon-star /><span class="block text-white-dark dark:text-white-light">.....</span>
          </h6>
          <div class="flex items-start justify-between ltr:ml-auto rtl:mr-auto">
            <!-- <p class="font-semibold">90%</p> -->
            <div class="dropdown ltr:ml-4 rtl:mr-4">
              <div hlMenu>
                <a href="javascript:;" hlMenuButton class="align-middle">
                  <!-- <icon-horizontal-dots class="opacity-80 hover:opacity-100" /> -->
                </a>
                <ul *hlMenuItems @toggleAnimation class="whitespace-nowrap ltr:right-0 rtl:left-0">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem">{{ 'PROFILE.INVOICE' | translate }}</a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem">{{ 'PROFILE.Download' | translate }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
