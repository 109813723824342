import * as i0 from '@angular/core';
import { Directive, Input, NgModule, EventEmitter, Output } from '@angular/core';
let id = 1;
const generateId = () => id++;
/* for testing only */
const resetIdCounter = () => id = 1;

/// MENU - Spec: https://www.w3.org/TR/wai-aria-practices-1.2/#menubutton
class MenuDirective {
  constructor(renderer, changeDetection) {
    this.renderer = renderer;
    this.changeDetection = changeDetection;
    this.static = false;
    this.expanded = false;
    this.menuItems = [];
    this.activeItem = null;
    this.searchQuery = '';
    this.searchDebounce = null;
  }
  toggle(focusAfterExpand = null, focusButtonOnClose = true) {
    if (this.expanded) {
      // close items panel
      this.expanded = false;
      this.menuItemsPanel.collapse();
      this.menuButton.element.removeAttribute('aria-controls');
      this.menuButton.element.removeAttribute('expanded');
      this.menuItems = [];
      this.activeItem = null;
      this.windowClickUnlisten();
      if (focusButtonOnClose) {
        this.menuButton.focus();
      }
      this.changeDetection.markForCheck();
    } else {
      // open items panel
      this.expanded = true;
      this.changeDetection.markForCheck();
      setTimeout(() => {
        this.menuItemsPanel.expand();
        this.menuItemsPanel.focus();
        if (this.menuItemsPanel.element != null) {
          this.menuButton.element.setAttribute('aria-controls', this.menuItemsPanel.element.id);
        }
        this.menuButton.element.setAttribute('expanded', 'true');
        this.windowClickUnlisten = this.initListeners();
        if (focusAfterExpand) {
          setTimeout(() => this.focusItem(focusAfterExpand));
        }
      });
    }
  }
  focusItem(focusType) {
    const activeItem = this.calculateFocusedItem(focusType);
    if (activeItem === this.activeItem) {
      return;
    }
    this.activeItem = activeItem;
    this.menuItems.forEach(item => {
      if (this.activeItem) {
        this.menuItemsPanel.element?.setAttribute('aria-activedescendant', this.activeItem.element.id);
      } else {
        this.menuItemsPanel.element?.removeAttribute('aria-activedescendant');
      }
      item.setActive(item === this.activeItem);
    });
  }
  clickActive() {
    this.activeItem?.element.click();
  }
  search(value) {
    if (this.searchDebounce) {
      clearTimeout(this.searchDebounce);
    }
    this.searchDebounce = setTimeout(() => this.searchQuery = '', 350);
    this.searchQuery += value.toLocaleLowerCase();
    const matchingItem = this.menuItems.find(item => {
      const itemText = item.element.textContent?.trim().toLocaleLowerCase();
      return itemText?.startsWith(this.searchQuery) && !item.hlMenuItemDisabled;
    });
    if (matchingItem === undefined || matchingItem === this.activeItem) {
      return;
    }
    this.focusItem({
      kind: 'FocusSpecific',
      item: matchingItem
    });
  }
  calculateFocusedItem(focusType) {
    const enabledItems = this.menuItems.filter(item => !item.hlMenuItemDisabled);
    switch (focusType.kind) {
      case 'FocusSpecific':
        return focusType.item;
      case 'FocusNothing':
        return null;
      case 'FocusFirst':
        return enabledItems[0];
      case 'FocusLast':
        return enabledItems[enabledItems.length - 1];
      case 'FocusNext':
        if (this.activeItem === null) {
          return enabledItems[0];
        } else {
          const nextIndex = Math.min(enabledItems.indexOf(this.activeItem) + 1, enabledItems.length - 1);
          return enabledItems[nextIndex];
        }
      case 'FocusPrevious':
        if (this.activeItem === null) {
          return enabledItems[enabledItems.length - 1];
        } else {
          const previousIndex = Math.max(enabledItems.indexOf(this.activeItem) - 1, 0);
          return enabledItems[previousIndex];
        }
    }
  }
  initListeners() {
    return this.renderer.listen(window, 'click', event => {
      const target = event.target;
      const active = document.activeElement;
      if (this.menuButton.element.contains(target) || this.menuItemsPanel?.element?.contains(target)) {
        return;
      }
      const clickedTargetIsFocusable = active !== document.body && active?.contains(target);
      // do not focus button if the clicked element is itself focusable
      this.toggle(null, !clickedTargetIsFocusable);
    });
  }
}
MenuDirective.ɵfac = function MenuDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
MenuDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuDirective,
  selectors: [["", "hlMenu", ""]],
  inputs: {
    static: "static"
  },
  exportAs: ["hlMenu"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuDirective, [{
    type: Directive,
    args: [{
      selector: '[hlMenu]',
      exportAs: 'hlMenu'
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    static: [{
      type: Input
    }]
  });
})();
// MENU ITEM BUTTON
class MenuButtonDirective {
  constructor(elementRef, menu, renderer) {
    this.menu = menu;
    this.renderer = renderer;
    this.element = elementRef.nativeElement;
    menu.menuButton = this;
  }
  ngOnInit() {
    this.initAttributes(this.element);
    this.renderer.listen(this.element, 'click', () => {
      this.menu.toggle();
    });
    this.renderer.listen(this.element, 'keydown', event => {
      switch (event.key) {
        case ' ': // Space
        case 'Enter':
        case 'ArrowDown':
          event.preventDefault();
          this.menu.toggle({
            kind: 'FocusFirst'
          });
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.menu.toggle({
            kind: 'FocusLast'
          });
          break;
      }
    });
  }
  focus() {
    setTimeout(() => this.element?.focus());
  }
  initAttributes(element) {
    element.id = `headlessui-menu-button-${generateId()}`;
    element.setAttribute('type', 'button');
    element.setAttribute('aria-haspopup', 'true');
  }
}
MenuButtonDirective.ɵfac = function MenuButtonDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(MenuDirective), i0.ɵɵdirectiveInject(i0.Renderer2));
};
MenuButtonDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuButtonDirective,
  selectors: [["", "hlMenuButton", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuButtonDirective, [{
    type: Directive,
    args: [{
      selector: '[hlMenuButton]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: MenuDirective
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
/// MENU ITEMS PANEL
class MenuItemsPanelDirective {
  constructor(templateRef, viewContainerRef, menu, renderer) {
    this.templateRef = templateRef;
    this.viewContainerRef = viewContainerRef;
    this.menu = menu;
    this.renderer = renderer;
    this.element = null;
    this.menu.menuItemsPanel = this;
  }
  ngOnInit() {
    if (this.menu.static) {
      this.expandInternal();
    }
  }
  expand() {
    if (!this.menu.static) {
      this.expandInternal();
    }
  }
  collapse() {
    if (!this.menu.static) {
      this.viewContainerRef.clear();
      this.element = null;
    }
  }
  focus() {
    this.element?.focus({
      preventScroll: true
    });
  }
  expandInternal() {
    const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
    const element = view.rootNodes[0];
    this.initAttributes(element);
    this.initListeners(element);
    this.element = element;
    view.markForCheck();
  }
  initAttributes(element) {
    element.tabIndex = -1;
    element.id = `headlessui-menu-items-${generateId()}`;
    element.setAttribute('role', 'menu');
    element.setAttribute('aria-labelledby', this.menu.menuButton.element.id);
  }
  initListeners(element) {
    this.renderer.listen(element, 'keydown', event => {
      switch (event.key) {
        case ' ':
          // Space
          if (this.menu.searchQuery !== '') {
            event.preventDefault();
            this.menu.search(event.key);
          } else {
            event.preventDefault();
            this.menu.clickActive();
          }
          break;
        case 'Enter':
          event.preventDefault();
          this.menu.clickActive();
          break;
        case 'ArrowDown':
          event.preventDefault();
          this.menu.focusItem({
            kind: 'FocusNext'
          });
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.menu.focusItem({
            kind: 'FocusPrevious'
          });
          break;
        case 'Home':
        case 'PageUp':
          event.preventDefault();
          this.menu.focusItem({
            kind: 'FocusFirst'
          });
          break;
        case 'End':
        case 'PageDown':
          event.preventDefault();
          this.menu.focusItem({
            kind: 'FocusLast'
          });
          break;
        case 'Escape':
          event.preventDefault();
          this.menu.toggle();
          break;
        case 'Tab':
          event.preventDefault();
          break;
        default:
          if (event.key.length === 1) {
            this.menu.search(event.key);
          }
      }
    });
  }
}
MenuItemsPanelDirective.ɵfac = function MenuItemsPanelDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuItemsPanelDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(MenuDirective), i0.ɵɵdirectiveInject(i0.Renderer2));
};
MenuItemsPanelDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuItemsPanelDirective,
  selectors: [["", "hlMenuItems", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuItemsPanelDirective, [{
    type: Directive,
    args: [{
      selector: '[hlMenuItems]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: MenuDirective
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
// MENU ITEM
class MenuItemDirective {
  constructor(templateRef, viewContainerRef, menu, renderer) {
    this.templateRef = templateRef;
    this.viewContainerRef = viewContainerRef;
    this.menu = menu;
    this.renderer = renderer;
    this.hlMenuItemDisabled = false;
    this.context = {
      active: false
    };
    this.menu.menuItems.push(this);
  }
  ngOnInit() {
    this.view = this.viewContainerRef.createEmbeddedView(this.templateRef, {
      $implicit: this.context
    });
    this.element = this.view.rootNodes[0];
    this.initAttributes(this.element);
    this.initListeners(this.element);
  }
  setActive(active) {
    this.context.active = active;
    this.view.markForCheck();
  }
  initAttributes(element) {
    element.id = `headlessui-menu-item-${generateId()}`;
    element.tabIndex = -1;
    element.setAttribute('role', 'menuitem');
    if (this.hlMenuItemDisabled) {
      this.element.setAttribute('aria-disabled', 'true');
    } else {
      this.element.removeAttribute('aria-disabled');
    }
  }
  initListeners(element) {
    this.renderer.listen(element, 'pointermove', () => this.menu.focusItem({
      kind: 'FocusSpecific',
      item: this
    }));
    this.renderer.listen(element, 'pointerleave', () => this.menu.focusItem({
      kind: 'FocusNothing'
    }));
    this.renderer.listen(element, 'click', event => {
      if (this.hlMenuItemDisabled) {
        event.preventDefault();
        return;
      }
      this.menu.toggle();
    });
  }
}
MenuItemDirective.ɵfac = function MenuItemDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuItemDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(MenuDirective), i0.ɵɵdirectiveInject(i0.Renderer2));
};
MenuItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MenuItemDirective,
  selectors: [["", "hlMenuItem", ""]],
  inputs: {
    hlMenuItemDisabled: "hlMenuItemDisabled"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuItemDirective, [{
    type: Directive,
    args: [{
      selector: '[hlMenuItem]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: MenuDirective
    }, {
      type: i0.Renderer2
    }];
  }, {
    hlMenuItemDisabled: [{
      type: Input
    }]
  });
})();
class MenuModule {}
MenuModule.ɵfac = function MenuModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MenuModule)();
};
MenuModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MenuModule
});
MenuModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MenuModule, [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [MenuDirective, MenuButtonDirective, MenuItemsPanelDirective, MenuItemDirective],
      declarations: [MenuDirective, MenuButtonDirective, MenuItemsPanelDirective, MenuItemDirective],
      providers: []
    }]
  }], null, null);
})();

/// LISTBOX - Spec: https://www.w3.org/WAI/ARIA/apg/patterns/listbox/
class ListboxDirective {
  constructor(renderer, changeDetection) {
    this.renderer = renderer;
    this.changeDetection = changeDetection;
    this.static = false;
    this.value = null;
    this.valueChange = new EventEmitter();
    this.expanded = false;
    this.listboxOptions = [];
    this.activeOption = null;
    this.searchQuery = '';
    this.searchDebounce = null;
  }
  toggle(focusAfterExpand = null, focusButtonOnClose = true) {
    if (this.expanded) {
      // close options panel
      this.expanded = false;
      this.listboxOptionsPanel.collapse();
      this.listboxButton.element.removeAttribute('aria-controls');
      this.listboxButton.element.removeAttribute('expanded');
      this.listboxOptions = [];
      this.activeOption = null;
      this.windowClickUnlisten();
      if (focusButtonOnClose) {
        this.listboxButton.focus();
      }
      this.changeDetection.markForCheck();
    } else {
      // open options panel
      this.expanded = true;
      this.changeDetection.markForCheck();
      setTimeout(() => {
        this.listboxOptionsPanel.expand();
        this.listboxOptionsPanel.focus();
        if (this.listboxOptionsPanel.element != null) {
          this.listboxButton.element.setAttribute('aria-controls', this.listboxOptionsPanel.element.id);
        }
        this.listboxButton.element.setAttribute('expanded', 'true');
        this.windowClickUnlisten = this.initListeners();
        if (focusAfterExpand) {
          setTimeout(() => this.focusOption(focusAfterExpand));
        }
      });
    }
  }
  select(value) {
    this.valueChange.emit(value);
    this.listboxOptions.forEach(option => {
      option.select(option.hlListboxOptionValue === value);
    });
  }
  isSelected(value) {
    return this.value === value;
  }
  focusOption(focusType) {
    const activeOption = this.calculateFocusedOption(focusType);
    if (activeOption === this.activeOption) {
      return;
    }
    this.activeOption = activeOption;
    this.listboxOptions.forEach(option => {
      if (this.activeOption) {
        this.listboxOptionsPanel.element?.setAttribute('aria-activedescendant', this.activeOption.element.id);
      } else {
        this.listboxOptionsPanel.element?.removeAttribute('aria-activedescendant');
      }
      option.setActive(option === this.activeOption);
    });
  }
  clickActive() {
    this.activeOption?.element.click();
  }
  search(value) {
    if (this.searchDebounce) {
      clearTimeout(this.searchDebounce);
    }
    this.searchDebounce = setTimeout(() => this.searchQuery = '', 350);
    this.searchQuery += value.toLocaleLowerCase();
    const matchingOption = this.listboxOptions.find(option => {
      const optionText = option.element.textContent?.trim().toLocaleLowerCase();
      return optionText?.startsWith(this.searchQuery) && !option.hlListboxOptionDisabled;
    });
    if (matchingOption === undefined || matchingOption === this.activeOption) {
      return;
    }
    this.focusOption({
      kind: 'FocusSpecific',
      option: matchingOption
    });
  }
  calculateFocusedOption(focusType) {
    const enabledOptions = this.listboxOptions.filter(option => !option.hlListboxOptionDisabled);
    switch (focusType.kind) {
      case 'FocusSpecific':
        return focusType.option;
      case 'FocusValue':
        const option = this.listboxOptions.find(o => o.hlListboxOptionValue === focusType.value);
        if (option) {
          return option;
        }
        return null;
      case 'FocusNothing':
        return null;
      case 'FocusFirst':
        return enabledOptions[0];
      case 'FocusLast':
        return enabledOptions[enabledOptions.length - 1];
      case 'FocusNext':
        if (this.activeOption === null) {
          return enabledOptions[0];
        } else {
          const nextIndex = Math.min(enabledOptions.indexOf(this.activeOption) + 1, enabledOptions.length - 1);
          return enabledOptions[nextIndex];
        }
      case 'FocusPrevious':
        if (this.activeOption === null) {
          return enabledOptions[enabledOptions.length - 1];
        } else {
          const previousIndex = Math.max(enabledOptions.indexOf(this.activeOption) - 1, 0);
          return enabledOptions[previousIndex];
        }
    }
  }
  initListeners() {
    return this.renderer.listen(window, 'click', event => {
      const target = event.target;
      const active = document.activeElement;
      if (this.listboxButton.element.contains(target) || this.listboxOptionsPanel?.element?.contains(target)) {
        return;
      }
      const clickedTargetIsFocusable = active !== document.body && active?.contains(target);
      // do not focus button if the clicked element is itself focusable
      this.toggle(null, !clickedTargetIsFocusable);
    });
  }
}
ListboxDirective.ɵfac = function ListboxDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListboxDirective)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
ListboxDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ListboxDirective,
  selectors: [["", "hlListbox", ""]],
  inputs: {
    static: "static",
    value: "value"
  },
  outputs: {
    valueChange: "valueChange"
  },
  exportAs: ["[hlListbox]"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListboxDirective, [{
    type: Directive,
    args: [{
      selector: '[hlListbox]',
      exportAs: '[hlListbox]'
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    static: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }]
  });
})();
// LISTBOX OPTION BUTTON
class ListboxButtonDirective {
  constructor(elementRef, listbox, renderer) {
    this.listbox = listbox;
    this.renderer = renderer;
    this.element = elementRef.nativeElement;
    listbox.listboxButton = this;
  }
  ngOnInit() {
    this.initAttributes(this.element);
    this.renderer.listen(this.element, 'click', () => {
      this.listbox.toggle();
    });
    this.renderer.listen(this.element, 'keydown', event => {
      switch (event.key) {
        case ' ': // Space
        case 'Enter':
        case 'ArrowDown':
          event.preventDefault();
          if (this.listbox.value) {
            this.listbox.toggle({
              kind: 'FocusValue',
              value: this.listbox.value
            });
          } else {
            this.listbox.toggle({
              kind: 'FocusFirst'
            });
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (this.listbox.value) {
            this.listbox.toggle({
              kind: 'FocusValue',
              value: this.listbox.value
            });
          } else {
            this.listbox.toggle({
              kind: 'FocusPrevious'
            });
          }
          break;
      }
    });
  }
  focus() {
    setTimeout(() => this.element?.focus());
  }
  initAttributes(element) {
    element.id = `headlessui-listbox-button-${generateId()}`;
    element.setAttribute('type', 'button');
    element.setAttribute('aria-haspopup', 'true');
  }
}
ListboxButtonDirective.ɵfac = function ListboxButtonDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListboxButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(ListboxDirective), i0.ɵɵdirectiveInject(i0.Renderer2));
};
ListboxButtonDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ListboxButtonDirective,
  selectors: [["", "hlListboxButton", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListboxButtonDirective, [{
    type: Directive,
    args: [{
      selector: '[hlListboxButton]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: ListboxDirective
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
/// LISTBOX OPTIONS PANEL
class ListboxOptionsPanelDirective {
  constructor(templateRef, viewContainerRef, listbox, renderer) {
    this.templateRef = templateRef;
    this.viewContainerRef = viewContainerRef;
    this.listbox = listbox;
    this.renderer = renderer;
    this.element = null;
    this.listbox.listboxOptionsPanel = this;
  }
  ngOnInit() {
    if (this.listbox.static) {
      this.expandInternal();
    }
  }
  expand() {
    if (!this.listbox.static) {
      this.expandInternal();
    }
  }
  collapse() {
    if (!this.listbox.static) {
      this.viewContainerRef.clear();
      this.element = null;
    }
  }
  focus() {
    this.element?.focus({
      preventScroll: true
    });
  }
  expandInternal() {
    const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
    const element = view.rootNodes[0];
    this.initAttributes(element);
    this.initListeners(element);
    this.element = element;
    view.markForCheck();
  }
  initAttributes(element) {
    element.tabIndex = -1;
    element.id = `headlessui-listbox-options-${generateId()}`;
    element.setAttribute('role', 'listbox');
    element.setAttribute('aria-labelledby', this.listbox.listboxButton.element.id);
  }
  initListeners(element) {
    this.renderer.listen(element, 'keydown', event => {
      switch (event.key) {
        case ' ':
          // Space
          if (this.listbox.searchQuery !== '') {
            event.preventDefault();
            this.listbox.search(event.key);
          } else {
            event.preventDefault();
            this.listbox.clickActive();
          }
          break;
        case 'Enter':
          event.preventDefault();
          this.listbox.clickActive();
          break;
        case 'ArrowDown':
          event.preventDefault();
          this.listbox.focusOption({
            kind: 'FocusNext'
          });
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.listbox.focusOption({
            kind: 'FocusPrevious'
          });
          break;
        case 'Home':
        case 'PageUp':
          event.preventDefault();
          this.listbox.focusOption({
            kind: 'FocusFirst'
          });
          break;
        case 'End':
        case 'PageDown':
          event.preventDefault();
          this.listbox.focusOption({
            kind: 'FocusLast'
          });
          break;
        case 'Escape':
          event.preventDefault();
          this.listbox.toggle();
          break;
        case 'Tab':
          event.preventDefault();
          break;
        default:
          if (event.key.length === 1) {
            this.listbox.search(event.key);
          }
      }
    });
  }
}
ListboxOptionsPanelDirective.ɵfac = function ListboxOptionsPanelDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListboxOptionsPanelDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(ListboxDirective), i0.ɵɵdirectiveInject(i0.Renderer2));
};
ListboxOptionsPanelDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ListboxOptionsPanelDirective,
  selectors: [["", "hlListboxOptions", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListboxOptionsPanelDirective, [{
    type: Directive,
    args: [{
      selector: '[hlListboxOptions]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: ListboxDirective
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();
// LISTBOX OPTION
class ListboxOptionDirective {
  constructor(templateRef, viewContainerRef, listbox, renderer) {
    this.templateRef = templateRef;
    this.viewContainerRef = viewContainerRef;
    this.listbox = listbox;
    this.renderer = renderer;
    this.hlListboxOptionDisabled = false;
    this.hlListboxOptionValue = null;
    this.context = {
      active: false,
      selected: false
    };
    this.listbox.listboxOptions.push(this);
  }
  ngOnInit() {
    this.context.selected = this.listbox.isSelected(this.hlListboxOptionValue);
    this.view = this.viewContainerRef.createEmbeddedView(this.templateRef, {
      $implicit: this.context
    });
    this.element = this.view.rootNodes[0];
    this.initAttributes(this.element);
    this.initListeners(this.element);
  }
  setActive(active) {
    this.context.active = active;
    this.view.markForCheck();
  }
  select(selected) {
    this.context.selected = selected;
    this.view.markForCheck();
  }
  initAttributes(element) {
    element.id = `headlessui-listbox-option-${generateId()}`;
    element.tabIndex = -1;
    element.setAttribute('role', 'listboxoption');
    if (this.hlListboxOptionDisabled) {
      this.element.setAttribute('aria-disabled', 'true');
    } else {
      this.element.removeAttribute('aria-disabled');
    }
  }
  initListeners(element) {
    this.renderer.listen(element, 'pointermove', () => this.listbox.focusOption({
      kind: 'FocusSpecific',
      option: this
    }));
    this.renderer.listen(element, 'pointerleave', () => this.listbox.focusOption({
      kind: 'FocusNothing'
    }));
    this.renderer.listen(element, 'click', event => {
      if (this.hlListboxOptionDisabled) {
        event.preventDefault();
        return;
      }
      this.listbox.select(this.hlListboxOptionValue);
      this.listbox.toggle();
    });
  }
}
ListboxOptionDirective.ɵfac = function ListboxOptionDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListboxOptionDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(ListboxDirective), i0.ɵɵdirectiveInject(i0.Renderer2));
};
ListboxOptionDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ListboxOptionDirective,
  selectors: [["", "hlListboxOption", ""]],
  inputs: {
    hlListboxOptionDisabled: "hlListboxOptionDisabled",
    hlListboxOptionValue: "hlListboxOptionValue"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListboxOptionDirective, [{
    type: Directive,
    args: [{
      selector: '[hlListboxOption]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: ListboxDirective
    }, {
      type: i0.Renderer2
    }];
  }, {
    hlListboxOptionDisabled: [{
      type: Input
    }],
    hlListboxOptionValue: [{
      type: Input
    }]
  });
})();
class ListboxModule {}
ListboxModule.ɵfac = function ListboxModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ListboxModule)();
};
ListboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ListboxModule
});
ListboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ListboxModule, [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [ListboxDirective, ListboxButtonDirective, ListboxOptionsPanelDirective, ListboxOptionDirective],
      declarations: [ListboxDirective, ListboxButtonDirective, ListboxOptionsPanelDirective, ListboxOptionDirective],
      providers: []
    }]
  }], null, null);
})();
class TransitionDirective {
  constructor(viewContainer, templateRef, changeDetection) {
    this.viewContainer = viewContainer;
    this.templateRef = templateRef;
    this.changeDetection = changeDetection;
    this.viewRef = null;
    this.cancelLeaveAnimation = true;
    this.enterClasses = [];
    this.enterFromClasses = [];
    this.enterToClasses = [];
    this.leaveClasses = [];
    this.leaveFromClasses = [];
    this.leaveToClasses = [];
    this.initial = true;
  }
  set hlTransitionEnter(classes) {
    this.enterClasses = splitClasses(classes);
  }
  set hlTransitionEnterFrom(classes) {
    this.enterFromClasses = splitClasses(classes);
  }
  set hlTransitionEnterTo(classes) {
    this.enterToClasses = splitClasses(classes);
  }
  set hlTransitionLeave(classes) {
    this.leaveClasses = splitClasses(classes);
  }
  set hlTransitionLeaveFrom(classes) {
    this.leaveFromClasses = splitClasses(classes);
  }
  set hlTransitionLeaveTo(classes) {
    this.leaveToClasses = splitClasses(classes);
  }
  set hlTransition(show) {
    if (show) {
      this.cancelLeaveAnimation = true;
      if (this.viewRef) {
        // element not removed because leave animation is still running
        const element = this.viewRef.rootNodes[0];
        element.classList.remove(...this.leaveClasses, ...this.leaveFromClasses, ...this.leaveToClasses);
      } else {
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        if (this.initial) {
          this.initial = false;
          return;
        }
      }
      this.changeDetection.markForCheck();
      const element = this.viewRef.rootNodes[0];
      // prepare animation
      element.classList.add(...this.enterFromClasses);
      requestAnimationFrame(() => {
        // start animation
        element.classList.remove(...this.enterFromClasses);
        element.classList.add(...this.enterClasses, ...this.enterToClasses);
      });
    } else {
      if (this.initial) {
        this.initial = false;
        return;
      }
      if (!this.viewRef) {
        console.error('viewRef not set');
        return;
      }
      this.cancelLeaveAnimation = false;
      const element = this.viewRef.rootNodes[0];
      // prepare animation by removing enter-classes and add leave- and leaveFrom-classes.
      element.classList.remove(...this.enterClasses, ...this.enterToClasses);
      element.classList.add(...this.leaveClasses, ...this.leaveFromClasses);
      const duration = getDuration(element);
      requestAnimationFrame(() => {
        // start animation by removing from- and add to-classes
        element.classList.remove(...this.leaveFromClasses);
        element.classList.add(...this.leaveToClasses);
        // start timeout to remove element after animation finished
        setTimeout(() => {
          if (this.cancelLeaveAnimation) {
            return;
          }
          this.viewContainer.clear();
          this.viewRef = null;
        }, duration);
      });
    }
  }
}
TransitionDirective.ɵfac = function TransitionDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TransitionDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
TransitionDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TransitionDirective,
  selectors: [["", "hlTransition", ""]],
  inputs: {
    hlTransitionEnter: "hlTransitionEnter",
    hlTransitionEnterFrom: "hlTransitionEnterFrom",
    hlTransitionEnterTo: "hlTransitionEnterTo",
    hlTransitionLeave: "hlTransitionLeave",
    hlTransitionLeaveFrom: "hlTransitionLeaveFrom",
    hlTransitionLeaveTo: "hlTransitionLeaveTo",
    hlTransition: "hlTransition"
  },
  exportAs: ["hlTransition"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TransitionDirective, [{
    type: Directive,
    args: [{
      selector: '[hlTransition]',
      exportAs: 'hlTransition'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }, {
      type: i0.TemplateRef
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    hlTransitionEnter: [{
      type: Input
    }],
    hlTransitionEnterFrom: [{
      type: Input
    }],
    hlTransitionEnterTo: [{
      type: Input
    }],
    hlTransitionLeave: [{
      type: Input
    }],
    hlTransitionLeaveFrom: [{
      type: Input
    }],
    hlTransitionLeaveTo: [{
      type: Input
    }],
    hlTransition: [{
      type: Input
    }]
  });
})();
class TransitionModule {}
TransitionModule.ɵfac = function TransitionModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TransitionModule)();
};
TransitionModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TransitionModule
});
TransitionModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TransitionModule, [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [TransitionDirective],
      declarations: [TransitionDirective],
      providers: []
    }]
  }], null, null);
})();
function splitClasses(classes) {
  return classes.split(' ').filter(className => className.trim().length > 1);
}
function getDuration(element) {
  // Safari returns a comma separated list of values, so let's sort them and take the highest value.
  let {
    transitionDuration,
    transitionDelay
  } = getComputedStyle(element);
  let [durationMs, delayMs] = [transitionDuration, transitionDelay].map(value => {
    let [resolvedValue = 0] = value.split(',')
    // Remove falsy we can't work with
    .filter(Boolean)
    // Values are returned as `0.3s` or `75ms`
    .map(v => v.includes('ms') ? parseFloat(v) : parseFloat(v) * 1000).sort((a, z) => z - a);
    return resolvedValue;
  });
  return durationMs + delayMs;
}

/**
 * Generated bundle index. Do not edit.
 */

export { ListboxButtonDirective, ListboxDirective, ListboxModule, ListboxOptionDirective, ListboxOptionsPanelDirective, MenuButtonDirective, MenuDirective, MenuItemDirective, MenuItemsPanelDirective, MenuModule, TransitionDirective, TransitionModule };
