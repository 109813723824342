import { Injectable } from '@angular/core';
import axios from 'axios';
import type { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { environment } from './../../environments/environment';
import { EncryptionService } from './../shared/service/common/encryption.service';

@Injectable({
  providedIn: 'root',
})
export class AxiosService {
  private axiosInstance: AxiosInstance | null = null;
  private axiosInitialized: Promise<void>;

  constructor(private readonly encryptService: EncryptionService) {
    this.axiosInitialized = this.initAxios();
  }

  private async initAxios(): Promise<void> {
    const cryptFactor = await this.encryptService.encrypt(`${environment.secrets.appCode}`);
    this.axiosInstance = axios.create({
      baseURL: environment.apiUrl,
      timeout: 500000,
      headers: {
        'Content-Type': 'application/json',
        factor: cryptFactor,
      },
    });

    this.axiosInstance.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        return config;
      },
      (error: any) => Promise.reject(error),
    );

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.status >= 200 && response.status <= 300) {
          return response;
        } else {
          throw new Error('API request failed');
        }
      },
      (error: any) => Promise.reject(error),
    );
  }

  private async ensureAxiosInitialized(): Promise<void> {
    if (!this.axiosInstance) {
      await this.axiosInitialized;
    }
  }

  async get<T>(url: string, config?: InternalAxiosRequestConfig): Promise<T> {
    await this.ensureAxiosInitialized();
    try {
      const response: AxiosResponse<T> = await this.axiosInstance!.get<T>(url, config);
      return response.data;
    } catch (error) {
      console.error('ERROR');
      this.handleError(error);
      throw error;
    }
  }

  async post<T>(url: string, data: any, config?: InternalAxiosRequestConfig): Promise<T> {
    await this.ensureAxiosInitialized();
    try {
      const response: AxiosResponse<T> = await this.axiosInstance!.post<T>(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async put<T>(url: string, data: any, config?: InternalAxiosRequestConfig): Promise<T> {
    await this.ensureAxiosInitialized();
    try {
      const response: AxiosResponse<T> = await this.axiosInstance!.put<T>(url, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  async delete<T>(url: string, config?: InternalAxiosRequestConfig): Promise<T> {
    await this.ensureAxiosInitialized();
    try {
      const response: AxiosResponse<T> = await this.axiosInstance!.delete<T>(url, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  private handleError(error: any): void {
    console.error('Error occurred:', error);
  }
}
