import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnalitycsService } from 'src/app/shared/service/analitycs.service';
import moment from 'moment';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrl: './vehicle.component.css',
})
export class VehicleComponent {
  vehicle: any[] = [
    { name: 'Chevrolet Spark', km: '10.000' },
    { name: 'Ducatti', km: '17.000' },
    { name: 'BMW', km: '20.000' },
  ];
  plate: any[] = [{ day: 'Martes' }, { day: 'Jueves' }, { day: 'Viernes' }];

  constructor(
    private router: Router,
    private analitycs: AnalitycsService,
  ) {
    this.analitycs.logEvent('INIT_VEHICLE', {
      view: 'VEHICLE',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
  }
  Plate() {
    this.analitycs.logEvent('SEE_PLATE', {
      view: 'VEHICLE',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['/plate']);
  }
  openPlateCityModal(): void {
    const modal = document.getElementById('platecityModal');
    if (modal) {
      modal.classList.remove('hidden'); // Mostrar el modal
    }
  }
  closePlateCityModal(): void {
    const modal = document.getElementById('platecityModal');
    if (modal) {
      modal.classList.add('hidden'); // Ocultar el modal
    }
  }
}
