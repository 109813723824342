<div>
  <div class="absolute inset-0">
    <img src="../../../../assets/images/notification-bg.png" alt="image" class="h-full w-full object-cover" />
  </div>
  <div
    class="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16"
  >
    <img src="../../../../assets/images/notification-bg.png" alt="image" class="absolute left-0 top-1/2 h-full max-h-[893px] -translate-y-1/2" />
    <img src="../../../../assets/images/notification-bg.png" alt="image" class="absolute left-24 top-0 h-40 md:left-[30%]" />
    <img src="../../../../assets/images/notification-bg.png" alt="image" class="absolute right-0 top-0 h-[300px]" />
    <img src="../../../../assets/images/notification-bg.png" alt="image" class="absolute bottom-0 end-[28%]" />
    <div
      class="relative flex w-full max-w-[1502px] flex-col justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[500px] lg:flex-row lg:gap-10 xl:gap-0"
    >
      <div
        class="relative hidden w-full items-center justify-center bg-[linear-gradient(225deg,#00022e_100%)] lg:inline-flex lg:max-w-[835px] xl:-ms-28 ltr:xl:skew-x-[14deg] rtl:xl:skew-x-[-14deg]"
      >
        <div
          class="absolute inset-y-0 w-8 from-primary/100 via-transparent to-transparent ltr:-right-10 ltr:bg-gradient-to-br rtl:-left-10 rtl:bg-gradient-to-l xl:w-16 ltr:xl:-right-20 rtl:xl:-left-20"
        ></div>
        <div class="ltr:xl:-skew-x-[13deg] rtl:xl:skew-x-[14deg] w-full h-full">
          <!-- <img src="../../../../assets/images/logo-22.png" alt="Logo" class="absolute z-10 w-6/12 pl-36 animate__animated animate__fadeIn animate__delay-1s" /> -->

          <div class="hidden w-full h-full lg:block pl-14">
            <img [src]="currentImage" class="w-full pl-16 trapezoid animate__animated animate__fadeIn animate__delay-1s" alt="Logo idioma" />
            <!-- <img src="../../../../assets/images/pit1inglish.jpg" alt="Cover Image" class="w-full pl-16 trapezoid animate__animated animate__fadeIn animate__delay-1s" /> -->
          </div>
        </div>
      </div>
      <div class="relative flex w-full flex-col items-center justify-center px-4 sm:px-6 lg:max-w-[667px]">
        <div class="flex w-full max-w-[440px] items-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
          <a routerLink="/" class="block w-20 lg:hidden">
            <img *ngIf="isDarkMode === false" src="../../../assets/images/logo-22.png" alt="Logo" class="mx-auto w-20 flex left-0" />
            <img *ngIf="isDarkMode === true" src="../../../assets/images/logo-23.png" alt="Logo" class="mx-auto w-20 flex left-0" />
          </a>

          <app-languajes class="dropdown ms-auto w-max"></app-languajes>
        </div>
        <div class="w-full max-w-[440px] lg:mt-16">
          <div class="mb-10">
            <h1 class="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl animate__animated animated__delay-1s animate__zoomInDown">
              {{ 'REGISTER.TITLE' | translate }}
            </h1>
            <p class="text-base font-bold leading-normal text-white-dark animate__animated animated__delay-1s animate__slideInUp">
              {{ 'REGISTER.SUBTITLE' | translate }}
              <icon-login class="inline-block text-primary animate__animated animate__fadeInTopRight animated__delay-1s" />
            </p>
          </div>
          <form [formGroup]="registerForm" class="space-y-5 dark:text-white" (ngSubmit)="openModal()">
            <!-- Name and Last Name (50% y 50%) -->
            <div class="flex flex-wrap md:flex-nowrap gap-4">
              <div class="half w-full md:w-1/2">
                <label for="Name">{{ 'REGISTER.NAME' | translate }}</label>
                <div class="relative text-white-dark">
                  <input
                    id="Name"
                    type="text"
                    placeholder="{{ 'REGISTER.NAME' | translate }}"
                    class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s"
                    formControlName="firstName"
                  />
                  <span class="absolute start-4 top-1/2 -translate-y-1/2">
                    <icon-user class="text-primary" [fill]="true" />
                  </span>
                </div>
                <div *ngIf="registerForm.get('firstName')?.invalid && registerForm.get('firstName')?.touched" class="text-red-500">
                  <small *ngIf="registerForm.get('firstName')?.errors?.['required']">
                    {{ 'REGISTER.NAMEREQUIRED' | translate }}
                  </small>
                </div>
              </div>
              <div class="half w-full md:w-1/2">
                <label for="LastName">{{ 'REGISTER.LASTNAME' | translate }}</label>
                <div class="relative text-white-dark">
                  <input
                    id="LastName"
                    type="text"
                    placeholder="{{ 'REGISTER.LASTNAME' | translate }}"
                    class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s"
                    formControlName="lastName"
                  />
                  <span class="absolute start-4 top-1/2 -translate-y-1/2">
                    <icon-users class="text-primary" [fill]="true" />
                  </span>
                </div>
                <div *ngIf="registerForm.get('lastName')?.invalid && registerForm.get('lastName')?.touched" class="text-red-500">
                  <small *ngIf="registerForm.get('lastName')?.errors?.['required']">
                    {{ 'REGISTER.LASTNAMEREQUIRED' | translate }}
                  </small>
                </div>
              </div>
            </div>

            <!-- Email (100%) -->
            <div>
              <label for="Email">{{ 'REGISTER.EMAIL' | translate }}</label>
              <div class="relative text-white-dark">
                <input
                  id="Email"
                  type="email"
                  placeholder="{{ 'REGISTER.ENTEREMAIL' | translate }}"
                  class="form-input ps-10 placeholder:text-white-dark animate__animated animated__delay-1s animate__fadeInTopLeft"
                  formControlName="email"
                />
                <span class="absolute start-4 top-1/2 -translate-y-1/2">
                  <icon-mail class="text-primary" [fill]="true" />
                </span>
              </div>
              <!-- Error messages -->
              <div *ngIf="registerForm.get('email')?.invalid && registerForm.get('email')?.touched" class="text-red-500">
                <small *ngIf="registerForm.get('email')?.errors?.['required']">
                  {{ 'REGISTER.EMAILREQUIRED' | translate }}
                </small>
                <small *ngIf="registerForm.get('email')?.errors?.['email']">
                  {{ 'REGISTER.INVALIDEMAIL' | translate }}
                </small>
              </div>
            </div>

            <!-- type of id and id (50% y 50%) -->
            <div class="flex flex-wrap md:flex-nowrap gap-4">
              <div class="half w-full md:w-1/2">
                <label for="documentType" class="block mb-2">{{ 'REGISTER.TYPEOFID' | translate }}</label>
                <div class="relative text-white-dark">
                  <select id="documentType" class="form-input ps-10 placeholder:text-white-dark mb-4" formControlName="documentType">
                    <option value="" disabled selected>{{ 'REGISTER.SELECTTYPE' | translate }}</option>
                    <option value="CC">Cedula de Ciudadania</option>
                    <option value="TI">Tarjeta de Identidad</option>
                    <option value="PP">Pasaporte</option>
                    <option value="PP">Cedula de Extranjeria</option>
                  </select>
                  <span class="absolute pb-4 start-4 top-1/2 -translate-y-1/2">
                    <icon-menu-documentation class="text-primary" [fill]="true" />
                  </span>
                </div>
                <!-- Error messages -->
                <div *ngIf="registerForm.get('documentType')?.invalid && registerForm.get('documentType')?.touched" class="text-red-500">
                  <small>{{ 'REGISTER.TYPEOFIDREQUIRED' | translate }}</small>
                </div>
              </div>
              <div class="half w-full md:w-1/2">
                <label for="documentNumber" class="block mb-2">{{ 'REGISTER.ID' | translate }}</label>
                <div class="relative text-white-dark">
                  <input
                    id="documentNumber"
                    type="text"
                    placeholder="{{ 'REGISTER.ENTERID' | translate }}"
                    class="form-input ps-10 placeholder:text-white-dark"
                    formControlName="documentNumber"
                  />
                  <span class="absolute start-4 top-1/2 -translate-y-1/2">
                    <icon-menu-documentation class="text-primary" [fill]="true" />
                  </span>
                </div>
                <!-- Error messages -->
                <div *ngIf="registerForm.get('documentNumber')?.invalid && registerForm.get('documentNumber')?.touched" class="text-red-500">
                  <small *ngIf="registerForm.get('documentNumber')?.errors?.['required']">
                    {{ 'REGISTER.IDREQUIRED' | translate }}
                  </small>
                  <small *ngIf="registerForm.get('documentNumber')?.errors?.['pattern']">
                    {{ 'REGISTER.INVALIDFORMAT' | translate }}
                  </small>
                </div>
              </div>
            </div>

            <!-- code contry and city (50% y 50%) -->
            <div class="flex flex-wrap md:flex-nowrap gap-4">
              <div class="half w-full md:w-1/2">
                <label for="country_code">{{ 'REGISTER.COUNTRY' | translate }}</label>
                <select
                  name="country_code"
                  id="country_code"
                  class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s"
                  formControlName="code"
                >
                  <option value="" disabled selected>{{ 'REGISTER.SELECTCOUNTRY' | translate }}</option>
                  <option value="57" selected>Colombia</option>
                  <option value="56" selected>Argentina</option>
                  <option value="57" selected>Venezuela</option>
                  <option value="58" selected>Panáma</option>
                  <option value="59" selected>Estados Unidos</option>
                </select>
                <!-- <span class="absolute start-4 top-1/2 -translate-y-1/2">
                  <icon-message class="text-primary" [fill]="true" />
                </span> -->
                <!-- Error messages -->
                <div *ngIf="registerForm.get('code')?.invalid && registerForm.get('code')?.touched" class="text-red-500">
                  <small>{{ 'REGISTER.COUNTRYREQUIRED' | translate }}</small>
                </div>
              </div>
              <div class="half w-full md:w-1/2" formControlName="city">
                <label for="Telefono">{{ 'REGISTER.CITY' | translate }}</label>
                <div class="relative text-white-dark">
                  <input
                    id="Telefono"
                    type="text"
                    placeholder="{{ 'REGISTER.ENTERCITY' | translate }}"
                    class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s"
                    formControlName="city"
                  />
                  <span class="absolute start-4 top-1/2 -translate-y-1/2">
                    <icon-message class="text-primary" [fill]="true" />
                  </span>
                </div>
                <div *ngIf="registerForm.get('city')?.invalid && registerForm.get('city')?.touched" class="text-red-500">
                  <small *ngIf="registerForm.get('city')?.errors?.['required']">
                    {{ 'REGISTER.CITYREQUIRED' | translate }}
                  </small>
                </div>
              </div>
            </div>

            <!-- department (100%) -->
            <div>
              <label for="Modelo">Departamento</label>
              <div class="relative text-white-dark">
                <input
                  id="Modelo"
                  type="text"
                  placeholder="{{ 'REGISTER.ENTERMODEL' | translate }}"
                  class="form-input ps-10 placeholder:text-white-dark animate__animated animated__delay-1s animate__fadeInTopLeft"
                  formControlName="vehicleModel"
                />
                <span class="absolute start-4 top-1/2 -translate-y-1/2">
                  <icon-share class="text-primary" [fill]="true" />
                </span>
              </div>
              <div *ngIf="registerForm.get('vehicleModel')?.invalid && registerForm.get('vehicleModel')?.touched" class="text-red-500">
                <small *ngIf="registerForm.get('vehicleModel')?.errors?.['required']">
                  {{ 'REGISTER.MODELREQUIRED' | translate }}
                </small>
              </div>
            </div>

            <!-- PHONE -->
            <div>
              <div class="relative" formControlName="phone">
                <label for="Telefono">{{ 'REGISTER.PHONE' | translate }}</label>
                <div class="relative text-white-dark">
                  <input
                    id="Telefono"
                    type="number"
                    placeholder="{{ 'REGISTER.ENTERPHONE' | translate }}"
                    class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s"
                    formControlName="phone"
                  />
                  <span class="absolute start-4 top-1/2 -translate-y-1/2">
                    <icon-phone class="text-primary" [fill]="true" />
                  </span>
                </div>
                <div *ngIf="registerForm.get('phone')?.invalid && registerForm.get('phone')?.touched" class="text-red-500">
                  <small *ngIf="registerForm.get('phone')?.errors?.['required']">
                    {{ 'REGISTER.PHONEREQUIRED' | translate }}
                  </small>
                </div>
              </div>
            </div>

            <!-- Password (50% y 50%) -->
            <div class="flex flex-wrap md:flex-nowrap gap-4">
              <div class="half w-full md:w-1/2">
                <label for="Password">{{ 'REGISTER.PASSWORD' | translate }}</label>
                <div class="relative text-white-dark">
                  <input
                    id="Password"
                    type="password"
                    placeholder="{{ 'REGISTER.ENTERPASSWORD' | translate }}"
                    class="form-input ps-10 placeholder:text-white-dark animate__animated animated__delay-1s animate__fadeInTopLeft"
                    formControlName="password"
                  />
                  <span class="absolute start-4 top-1/2 -translate-y-1/2">
                    <icon-lock-dots class="text-primary" [fill]="true"></icon-lock-dots>
                  </span>
                </div>
                <!--  Error messages -->
                <div *ngIf="registerForm.get('password')?.invalid && registerForm.get('password')?.touched" class="text-red-500">
                  <small *ngIf="registerForm.get('password')?.errors?.['required']">
                    {{ 'REGISTER.PASSWORDREQUIRED' | translate }}
                  </small>
                  <small *ngIf="registerForm.get('password')?.errors?.['minlength']">
                    {{ 'REGISTER.PASSWORDMINLENGTH' | translate }}
                  </small>
                </div>
              </div>
              <div class="half w-full md:w-1/2">
                <label for="ConfirmPassword">{{ 'REGISTER.CONFIRMPASSWORD' | translate }}</label>
                <div class="relative text-white-dark">
                  <input
                    id="ConfirmPassword"
                    type="password"
                    placeholder="{{ 'REGISTER.CONFIRMPASSWORD' | translate }}"
                    class="form-input ps-10 placeholder:text-white-dark animate__animated animated__delay-1s animate__fadeInTopLeft"
                    formControlName="confirmPassword"
                  />
                  <span class="absolute start-4 top-1/2 -translate-y-1/2">
                    <icon-lock-dots class="text-primary" [fill]="true" />
                  </span>
                </div>
                <!-- messages error -->
                <div *ngIf="registerForm.get('confirmPassword')?.invalid && registerForm.get('confirmPassword')?.touched" class="text-red-500">
                  <small *ngIf="registerForm.get('confirmPassword')?.errors?.['required']">
                    {{ 'REGISTER.CONFIRMPASSWORD' | translate }}
                  </small>
                </div>
              </div>
            </div>

            <!-- plate of vehicle (100%) -->
            <div>
              <label for="PlacaVehiculo">{{ 'REGISTER.PLATE' | translate }}</label>
              <div class="relative text-white-dark">
                <input
                  id="PlacaVehiculo"
                  type="text"
                  placeholder="{{ 'REGISTER.ENTERPLATE' | translate }}"
                  class="form-input ps-10 placeholder:text-white-dark animate__animated animate__fadeInTopRight animated__delay-1s"
                  formControlName="vehiclePlate"
                  maxlength="6"
                  (input)="onInputChange($event)"
                />
                <span class="absolute start-4 top-1/2 -translate-y-1/2">
                  <icon-share class="text-primary" [fill]="true" />
                </span>
              </div>
              <div *ngIf="registerForm.get('vehiclePlate')?.invalid && registerForm.get('vehiclePlate')?.touched" class="text-red-500">
                <small *ngIf="registerForm.get('vehiclePlate')?.errors?.['required']">
                  {{ 'REGISTER.PLACEREQUIRED' | translate }}
                </small>
                <small *ngIf="registerForm.get('vehiclePlate')?.errors?.['minlength']">
                  {{ 'REGISTER.PLACEMINLENGTH' | translate }}
                </small>
                <small *ngIf="registerForm.get('vehiclePlate')?.errors?.['pattern']">
                  {{ 'REGISTER.PLACEREGEX' | translate }}
                </small>
              </div>
            </div>

            <!-- terms and conditions -->
            <div>
              <label class="flex cursor-pointer items-center">
                <input type="checkbox" class="form-checkbox bg-white dark:bg-black" formControlName="termsAccepted" />
                <span class="text-white-dark">{{ 'REGISTER.CONDITIONS' | translate }}</span>
              </label>
              <div *ngIf="registerForm.get('termsAccepted')?.invalid && registerForm.get('termsAccepted')?.touched" class="text-red-500">
                <small *ngIf="registerForm.get('termsAccepted')?.errors?.['required']">
                  {{ 'REGISTER.CONDITIONSREQUIRED' | translate }}
                </small>
              </div>
            </div>

            <!-- button -->
            <button
              type="submit"
              (click)="openModal()"
              class="btn btn-primary !mt-6 w-full border-0 uppercase text-black shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] animate-pulse"
            >
              {{ 'REGISTER.BUTTON' | translate }}
            </button>
          </form>
          <app-networks></app-networks>
          <app-comfirmation-message *ngIf="isModalVisible" [user]="user"></app-comfirmation-message>
        </div>
        <div class="text-center dark:text-white animate__animated animate__delay-1s animate__flipInY">
          {{ 'REGISTER.LOGIN' | translate }}
          <a
            (click)="GoToLogin()"
            class="uppercase cursor-pointer text-primary underline transition hover:text-black dark:hover:text-white animate__animated animate__delay-1s animate__flipInY"
          >
            {{ 'REGISTER.LINK' | translate }}
          </a>
          <p class="w-full text-center pb-5 text-black dark:text-white animate__animated animate__delay-1s animate__flipInY">
            © {{ currYear }} PITZON.{{ 'HOME.FOOTER' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
