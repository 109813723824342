<div class="panel">
    <div class="mb-5 flex items-center justify-between">
      <h5 class="text-lg font-semibold dark:text-white-light">{{ 'PROFILE.VEHICLE' | translate }}</h5>
    </div>
    <div>
      <div class="border-b border-azulOscuro dark:border-[#1b2e4b]">
        <div class="flex items-center justify-between py-2">
          <div class="flex-none">
            <img class="w-10" src="../../../../../assets/images/car1.png" alt="" />
          </div>
          <div class="flex flex-auto items-center justify-between ltr:ml-4 rtl:mr-4">
            <h6 class="font-semibold text-[#515365] dark:text-white-dark">
              chevrolet spark <span class="block text-black dark:text-white-light">{{ 'PROFILE.Automobile' | translate }} 12/07/2013</span>
            </h6>
            <span class="badge bg-green-400 ltr:ml-auto rtl:mr-auto">Color</span>
          </div>
        </div>
      </div>
      <div class="border-b border-azulOscuro dark:border-[#1b2e4b]">
        <div class="flex items-center justify-between py-2">
          <div class="flex-none">
            <img class="w-10" src="https://images.ctfassets.net/x7j9qwvpvr5s/3nNxSK1xQLESBdlyU5Eur0/89de1e6ada14da2f854efeed352b6ca7/Ducati-Unica-Model-Preview-1050x650.png" alt="" />
          </div>
          <div class="flex flex-auto items-center justify-between ltr:ml-4 rtl:mr-4">
            <h6 class="font-semibold text-[#515365] dark:text-white-dark">
              Ducati <span class="block text-black dark:text-white-light">{{ 'PROFILE.MOTORCYCLE' | translate }} 03/05/2025</span>
            </h6>
            <span class="badge bg-blue-800 ltr:ml-auto rtl:mr-auto">Color</span>
          </div>
        </div>
      </div>
      <div>
        <div class="flex items-center justify-between py-2">
          <div class="flex-none">
            <img class="w-10" src="../../../../../assets/images/car2.png" alt="" />
          </div>
          <div class="flex flex-auto items-center justify-between ltr:ml-4 rtl:mr-4">
            <h6 class="font-semibold text-[#515365] dark:text-white-dark">
              BMW <span class="block text-black dark:text-white-light">Royce 10/02/2024</span>
            </h6>
            <span class="badge bg-red-600 ltr:ml-auto rtl:mr-auto">Color</span>
          </div>
        </div>
      </div>
    </div>
  </div>