import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from '../shared/service/app/app.service';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { slideDownUp } from '../shared/animations';

@Component({
  selector: 'app-root',
  templateUrl: './home-layout.html',
  animations: [slideDownUp],
})
export class HomeLayout {
  isMobile: boolean = false;
  showMap: boolean = true;
  store: any;
  showTopButton = false;
  constructor(
    public translate: TranslateService,
    public storeData: Store<any>,
    private service: AppService,
    private router: Router,
  ) {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    this.initStore();
  }
  headerClass = '';
  ngOnInit() {
    this.initAnimation();
    this.toggleLoader();
    window.addEventListener('scroll', () => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        this.showTopButton = true;
      } else {
        this.showTopButton = false;
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', () => {
      // do nothing
    });
  }

  initAnimation() {
    this.service.changeAnimation();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.service.changeAnimation();
      }
    });

    const ele: any = document.querySelector('.animation');
    ele.addEventListener('animationend', () => {
      this.service.changeAnimation('remove');
    });
  }

  toggleLoader() {
    this.storeData.dispatch({ type: 'toggleMainLoader', payload: true });
    setTimeout(() => {
      this.storeData.dispatch({ type: 'toggleMainLoader', payload: false });
    }, 500);
  }

  async initStore() {
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }

  goToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
