import { Component, ViewChild } from '@angular/core';
import { toggleAnimation } from 'src/app/shared/animations';
import Swal from 'sweetalert2';
import { NgxCustomModalComponent } from 'ngx-custom-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import { Store } from '@ngrx/store';

@Component({
  templateUrl: './calendar.html',
  animations: [toggleAnimation],
  styleUrl: './calendar.css',
})
export class CalendarComponent {
  store: any;
  isLoading = true;
  @ViewChild('isAddEventModal') isAddEventModal!: NgxCustomModalComponent;
  @ViewChild('calendar') calendar!: FullCalendarComponent;
  defaultParams = {
    id: null,
    title: '',
    start: '',
    end: '',
    description: '',
    type: 'primary',
  };
  params!: FormGroup;
  minStartDate: any = '';
  minEndDate: any = '';
  isDarkMode = false;
  events: any = [];
  calendarOptions: any;
  constructor(
    public fb: FormBuilder,
    public storeData: Store<any>,
  ) {
    localStorage.setItem('light', 'dark');
    this.storeData
      .select((state) => state.index.theme)
      .subscribe((theme) => {
        this.isDarkMode = theme === 'dark';
      });
    this.initStore();
    this.isLoading = false;
  }
  async initStore() {
    this.storeData
      .select((d: any) => d.index)
      .subscribe((d: any) => {
        const hasChangeLayout = this.store?.layout !== d?.layout;

        const hasChangeMenu = this.store?.menu !== d?.menu;
        const hasChangeSidebar = this.store?.sidebar !== d?.sidebar;

        this.store = d;

        if (hasChangeLayout || hasChangeMenu || hasChangeSidebar) {
          if (this.isLoading) {
            this.initCalendar();
            this.calendarOptions.events = [];
          } else {
            setTimeout(() => {
              this.initCalendar();
            }, 300);
          }
        }
      });
  }

  initCalendar() {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: esLocale,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
        icon: '../../../../assets/images/icon1.png',
      },
      editable: true,
      dayMaxEvents: true,
      selectable: true,
      droppable: true,
      eventClick: (event: any) => {
        this.editEvent(event);
      },
      select: (event: any) => {
        this.editDate(event);
      },
    };
  }

  ngOnInit() {
    this.getEvents();
  }

  initForm() {
    this.params = this.fb.group({
      id: null,
      title: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      description: [''],
      type: ['primary'],
    });
  }

  getEvents() {
    const now = new Date();
    //data
    this.events = [
      {
        id: 0,
        title: 'Hoy',
        start: now.toISOString().split('T')[0] + 'T14:30:00', // Fecha actual con hora específica
        end: now.toISOString().split('T')[0] + 'T16:30:00', // Fecha actual con otra hora
        icon: '../../../../assets/images/icon1.png', // Ruta del ícono
        // className: 'custom-event',
        className: 'primary',
        description: 'Dia de hoy.',
      },
      {
        id: 1,
        title: 'Llevar el vehiculo al taller MotoGP',
        start: now.getFullYear() + '-' + this.getMonth(now) + '-01T14:30:00',
        end: now.getFullYear() + '-' + this.getMonth(now) + '-02T14:30:00',
        // className: 'danger',
        className: 'primary',
        description: 'Asegurate de llevar el vehiculo al taller.',
      },
      {
        id: 2,
        title: 'Cambio de aceite',
        start: now.getFullYear() + '-' + this.getMonth(now) + '-07T19:30:00',
        end: now.getFullYear() + '-' + this.getMonth(now) + '-08T14:30:00',
        className: 'primary',
        description: 'Recuerda cada 2 meses debes cambiar el aceite.',
      },
      {
        id: 3,
        title: 'Vence SOAT',
        start: now.getFullYear() + '-' + this.getMonth(now) + '-17T14:30:00',
        end: now.getFullYear() + '-' + this.getMonth(now) + '-18T14:30:00',
        className: 'primary',
        // className: 'info',
        description: 'Vence SOAT.',
      },
      {
        id: 4,
        title: 'Pico & Placa de la moto',
        start: now.getFullYear() + '-' + this.getMonth(now) + '-12T10:30:00',
        end: now.getFullYear() + '-' + this.getMonth(now) + '-13T10:30:00',
        className: 'primary',
        // className: 'danger',
        description: 'Pico & Placa.',
      },
      {
        id: 5,
        title: 'Tecnico mecanica',
        start: now.getFullYear() + '-' + this.getMonth(now) + '-12T15:00:00',
        end: now.getFullYear() + '-' + this.getMonth(now) + '-13T15:00:00',
        className: 'primary',
        // className: 'info',
        description: 'Pronto debes hacer la tecnico mecanica a tu vehiculo.',
      },
      {
        id: 6,
        title: 'Cambio de llantas traseras',
        start: now.getFullYear() + '-' + this.getMonth(now) + '-22T21:30:00',
        end: now.getFullYear() + '-' + this.getMonth(now) + '-22T21:30:00',
        className: 'primary',
        // className: 'success',
        description: 'Ya se cumplio el cambio de llantas.',
      },
      {
        id: 7,
        title: 'Llevar el vehiculo al taller MotoGP',
        start: now.getFullYear() + '-' + this.getMonth(now) + '-14T05:30:00',
        end: now.getFullYear() + '-' + this.getMonth(now) + '-14T05:30:00',
        className: 'primary',
        // className: 'info',
        description: ' Asegurate de llevar el vehiculo al taller.',
      },
      {
        id: 8,
        title: 'Llevar el vehiculo al taller lavado',
        start: now.getFullYear() + '-' + this.getMonth(now) + '-31T20:00:00',
        end: now.getFullYear() + '-' + this.getMonth(now) + '-31T20:00:00',
        className: 'primary',
        // className: 'danger',
        description: 'Lavar mi vehiculo.',
      },
      {
        id: 9,
        title: 'Cambio de frenos',
        start: now.getFullYear() + '-' + this.getMonth(now) + '-27T20:00:00',
        end: now.getFullYear() + '-' + this.getMonth(now) + '-28T20:00:00',
        className: 'primary',
        // className: 'success',
        description: 'Ya es tiempo de hacer el cambio de frenos.',
      },
      {
        id: 10,
        title: 'Pico y placa',
        start: now.getFullYear() + '-' + this.getMonth(now, 1) + '-24T08:12:14',
        end: now.getFullYear() + '-' + this.getMonth(now, 1) + '-27T22:20:20',
        className: 'primary',
        // className: 'danger',
        description: 'El dia de hoy tu moto tiene pico y placa.',
      },
      {
        id: 11,
        title: 'Reunion con el tecnico de motoGp',
        start: now.getFullYear() + '-' + this.getMonth(now, -1) + '-13T08:12:14',
        end: now.getFullYear() + '-' + this.getMonth(now, -1) + '-16T22:20:20',
        className: 'primary',
        description: 'Reunion el tecnico',
      },
      {
        id: 13,
        title: 'Cambio de llantas delanteras',
        start: now.getFullYear() + '-' + this.getMonth(now, 1) + '-15T08:12:14',
        end: now.getFullYear() + '-' + this.getMonth(now, 1) + '-18T22:20:20',
        className: 'primary',
        description: 'Cambio de llantas delanteras.',
      },
    ];
    this.calendarOptions.events = this.events;
  }

  getMonth(dt: Date, add: number = 0) {
    const month = dt.getMonth() + 1 + add;
    const str = (month < 10 ? '0' + month : month).toString();
    return str;
  }

  editEvent(data: any = null) {
    this.params = JSON.parse(JSON.stringify(this.defaultParams));
    this.isAddEventModal.open();
    this.initForm();
    if (data) {
      const obj = JSON.parse(JSON.stringify(data.event));
      this.params.setValue({
        id: obj.id ? obj.id : null,
        title: obj.title ? obj.title : null,
        start: this.dateFormat(obj.start),
        end: this.dateFormat(obj.end),
        type: obj.classNames ? obj.classNames[0] : 'primary',
        description: obj.extendedProps ? obj.extendedProps.description : '',
      });
      this.minStartDate = new Date();
      this.minEndDate = this.dateFormat(obj.start);
    } else {
      this.minStartDate = new Date();
      this.minEndDate = new Date();
    }
  }

  editDate(data: any) {
    const obj = {
      event: {
        start: data.start,
        end: data.end,
      },
    };
    this.editEvent(obj);
  }

  dateFormat(dt: any) {
    dt = new Date(dt);
    const month = dt.getMonth() + 1 < 10 ? '0' + (dt.getMonth() + 1) : dt.getMonth() + 1;
    const date = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
    const hours = dt.getHours() < 10 ? '0' + dt.getHours() : dt.getHours();
    const mins = dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes();
    dt = dt.getFullYear() + '-' + month + '-' + date + 'T' + hours + ':' + mins;
    return dt;
  }

  saveEvent() {
    if (!this.params.value.title) {
      return;
    }
    if (!this.params.value.start) {
      return;
    }
    if (!this.params.value.end) {
      return;
    }

    if (this.params.value.id) {
      //update event
      const event = this.events.find((d: any) => d.id === this.params.value.id);
      event.title = this.params.value.title;
      event.start = this.params.value.start;
      event.end = this.params.value.end;
      event.description = this.params.value.description;
      event.className = this.params.value.type;
    } else {
      //add event
      let maxEventId = 0;
      if (this.events) {
        maxEventId = this.events.reduce((max: number, character: any) => (character.id > max ? character.id : max), this.events[0].id);
      }

      const event = {
        id: maxEventId + 1,
        title: this.params.value.title,
        start: this.params.value.start,
        end: this.params.value.end,
        description: this.params.value.description,
        className: this.params.value.type,
      };
      this.events.push(event);
    }
    this.calendar.getApi(); //refresh Calendar
    this.showMessage('El evento se ha guardado correctamente.');
    this.isAddEventModal.close();
  }

  startDateChange(event: any) {
    const dateStr = event.target;
    if (dateStr) {
      this.minEndDate = this.dateFormat(dateStr);
      this.params.patchValue({ end: '' });
    }
  }

  showMessage(msg = '', type = 'success') {
    const toast: any = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: 'toast' },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: '10px 20px',
    });
  }
}
