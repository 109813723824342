// import { AppConfig } from './../../config';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CoreService } from '../../shared/service/app/core.service';
import { MessageChatDTO } from './../../shared/dto/message-chat.dto';
import { v4 as uuidv4 } from 'uuid';
import { CommonService } from './../../shared/service/common/common.service';
import moment from 'moment';
import { AssistantService } from './service/assistant.service';
import { initialMessage } from './../../shared/constants/message.constants';
import { WorkShopDTO } from 'src/app/shared/dto/work-shop.dto';
import { Router } from '@angular/router';

const slideDownUp = trigger('slideDownUp', [
  state('void', style({ opacity: 0, transform: 'translateY(-20px)' })),
  state('*', style({ opacity: 1, transform: 'translateY(0)' })),
  transition('void => *', animate('200ms ease-in')),
  transition('* => void', animate('200ms ease-out')),
]);

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.css'],
  animations: [slideDownUp],
})
export class AssistantComponent implements OnInit {
  @ViewChild('scrollable') scrollable!: NgScrollbar;
  fechaActual: string = moment().format('YYYY-MM-DD');
  isMobile: boolean = false;
  isMapVisible: boolean = false;
  isSmallScreen: boolean = false;
  textMessage: string = '';
  loadingConversation: boolean = false;
  messages: MessageChatDTO[] = [];
  chatId: string = '';
  myIp: string = '';
  message: MessageChatDTO | undefined;
  ip: string = '';
  workShop: WorkShopDTO | null = null;
  openAiData = {
    id: '',
    assistant_id: '',
    run_id: '',
    thread_id: '',
  };

  constructor(
    public core: CoreService,
    public commonService: CommonService,
    private router: Router,
    private assistantService: AssistantService,
  ) {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.startConversation();
    this.checkScreenSize();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkScreenSize(); // Actualiza el estado si cambia el tamaño de la pantalla
  }

  checkScreenSize() {
    this.isSmallScreen = window.innerWidth <= 770; // Ajusta el valor según el tamaño deseado
  }

  toggleMap() {
    this.isMapVisible = !this.isMapVisible; // Alterna entre chat y mapa
  }
  async startConversation() {
    this.loadingConversation = true;
    try {
      const senderLocal = localStorage.getItem('sender');
      if (senderLocal) {
        this.openAiData = JSON.parse(senderLocal);
        this.chatId = localStorage.getItem('chat') || '';
      } else {
        this.chatId = uuidv4();
        this.ip = await this.commonService.getIPAddress();
        if (this.chatId && this.chatId !== '') {
          this.buildMessage('', this.ip);
          const sender = await this.assistantService.createThread(this.message!);
          this.openAiData!.id = sender.id;
          this.openAiData!.assistant_id = sender.assistant_id;
          this.openAiData!.run_id = sender.run_id;
          this.openAiData!.thread_id = sender.thread_id;
          // localStorage.setItem('sender', JSON.parse(JSON.stringify(this.openAiData)));
          // localStorage.setItem('chat', this.chatId);
          this.messages.push(sender);
        }
      }
    } catch (error: any) {
      console.error('Error starting conversation:', error);
      this.buildMessage('Existe un error al contactar con el asistente, por favor intrentelo de nuevo mas tarde.', '');
      if (this.message) {
        this.messages.push(this.message);
      }
    }
    this.loadingConversation = false;
  }

  private buildMessage(message: string, ip: string) {
    this.message = JSON.parse(JSON.stringify(initialMessage));
    if (this.message) {
      this.message.session_id = this.chatId;
      this.message.created_at = moment().utc().unix();
      this.message.metadata!.source = 'fe-web';
      this.message.metadata!.ip = ip;
      this.message.metadata!.userAgent = navigator.userAgent;
      this.message.id = this.openAiData!.id;
      this.message.assistant_id = this.openAiData!.assistant_id;
      this.message.run_id = this.openAiData!.run_id;
      this.message.thread_id = this.openAiData!.thread_id;

      if (message !== '') {
        this.message.content[0].text.value = message;
      }
    }
  }

  async sendMessage() {
    try {
      if (this.textMessage.trim() === '') {
        return;
      }
      this.buildMessage(this.textMessage, this.ip);
      this.messages.push({ ...this.message! });
      const myMessage = JSON.parse(JSON.stringify(this.message));
      this.textMessage = '';
      this.scrollToBottom();
      this.loadingConversation = true;
      console.log(this.workShop);
      console.log('agregar', myMessage!.content[0].text.value.includes('Quiero agendar una cita'));
      if (myMessage!.content[0].text.value.includes('Quiero agendar una cita')) {
        myMessage!.content[0].text.value += ` (Ten en cuenta el workShopName=${this.workShop?.companyName}, workShopId=${this.workShop?._id}, workShopTuulAppId=${this.workShop?.id_tuulap})`;
        console.log('se envia ', myMessage!.content[0].text.value);
      }
      const resultSend = await this.assistantService.sendMessage(myMessage!);
      this.messages.push(resultSend);
      this.scrollToBottom();
    } catch (error) {
      console.error('Error starting conversation:', error);
      this.buildMessage('Existe un error al contactar con el asistente, por favor intrentelo de nuevo mas tarde.', '');
      if (this.message) {
        this.messages.push(this.message);
      }
    }
    this.loadingConversation = false;
  }

  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.scrollable.scrollTo({ bottom: 0, duration: 200 });
      } catch (error) {
        throw error;
      }
    }, 300);
  }

  assistantSchedule(workShop: WorkShopDTO | null) {
    this.workShop = workShop!;
    this.textMessage = `Quiero agendar una cita en ${workShop!.companyName}`;
    this.sendMessage();

    // Change to assistant
    this.core.toggleToMap(false);
  }
  backToHome() {
    this.router.navigate(['']);
  }
}
