import { DataDocumentsComponent } from './shared/Components/profile/data-documents/data-documents.component';
import { DataVehicleComponent } from './shared/Components/profile/data-vehicle/data-vehicle.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { GoogleMapsModule } from '@angular/google-maps';
import { GlobalErrorHandler } from './core/global-error-handler';
import { ErrorHandler } from '@angular/core';
import { CountUpModule } from 'ngx-countup';
// Routes
import { routes } from './app.route';

import { AppComponent } from './app.component';

// Store
import { StoreModule } from '@ngrx/store';
import { indexReducer } from './store/index.reducer';

// Shared module
import { SharedModule } from 'src/shared.module';
// i18n
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AOT compilation support
export function HttpLoaderFactory(httpHandler: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpHandler));
}

// Dashboard
import { IndexComponent } from './index';

// Layouts
import { HomeLayout } from './layouts/home-layout';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { HeaderComponent } from './layouts/header';
import { FooterComponent } from './layouts/footer';
import { SidebarComponent } from './layouts/sidebar';
import { ThemeCustomizerComponent } from './layouts/theme-customizer';

// Components module
import { ComponentsModule } from './components/components.module';

//error 404
import { Error404Component } from './components/error404/error404.component';

// Services
import { GeolocationService } from './shared/service/geolocation/geolocation.service';
import { AuthService } from './shared/service/auth/auth.service';
import { CommonService } from './shared/service/common/common.service';
import { AlertService } from './shared/service/common/alert.service';
import { CoverLoginComponent } from './core/auth/login/login.component';
import { AssistantComponent } from './components/assistant/assistant.component';
import { environment } from '../environments/environment';
import { CoverRegisterComponent } from './core/auth/register/register.component';
import { ConfirmationmessageComponent } from './shared/Components/comfirmation-message/comfirmation-message.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAnalytics, provideAnalytics, ScreenTrackingService } from '@angular/fire/analytics';
import { LanguajesComponent } from './shared/Components/languajes/languajes.component';
// import { NetworksComponent } from './shared/Components/networks/networks.component';
import { ProfileComponent } from './shared/Components/profile/profile.component';
import { EditProfileComponent } from './shared/Components/edit-profile/edit-profile.component';
import { DocumentationComponent } from './shared/Components/edit-profile/documentation/documentation/documentation.component';
import { VehicleComponent } from './shared/Components/edit-profile/vehicle/vehicle/vehicle.component';
import { OtherComponent } from './shared/Components/edit-profile/otherr/other/other.component';
import { HomeProfileComponent } from './shared/Components/edit-profile/home/home-profile/home-profile.component';
import { FinesComponent } from './shared/Components/profile/fines/fines.component';
import { HomeComponent } from './components/home/home.component';
import { TestimonyComponent } from './shared/Components/testimony/testimony.component';
import { CalendarComponent } from './shared/Components/calendar/calendar';
// modal
import { NgxCustomModalComponent } from 'ngx-custom-modal';

// fullcalendar
import { FullCalendarModule } from '@fullcalendar/angular';
import { CounterComponent } from './shared/Components/counter/counter.component';
import { AlertsComponent } from './shared/Components/alerts/alerts/alerts.component';
import { HomeAlertsComponent } from './shared/Components/alerts/home/home.component';
import { PlatecityComponent } from './shared/Components/alerts/platecity/platecity/platecity.component';
import { OdsComponent } from './shared/Components/profile/ods/ods.component';

const config: SocketIoConfig = { url: environment.socketUrl, options: {} };

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
    HttpClientModule,
    NgScrollbarModule,
    CountUpModule,
    GoogleMapsModule,
    FullCalendarModule,
    NgxCustomModalComponent,
    SocketIoModule.forRoot(config),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    StoreModule.forRoot({ index: indexReducer }),
    SharedModule.forRoot(),
  ],
  declarations: [
    HomeComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ThemeCustomizerComponent,
    IndexComponent,
    HomeLayout,
    CoverLoginComponent,
    CoverRegisterComponent,
    AssistantComponent,
    Error404Component,
    ConfirmationmessageComponent,
    CounterComponent,
    // NetworksComponent,
    TestimonyComponent,
    LanguajesComponent,
    ProfileComponent,
    EditProfileComponent,
    DocumentationComponent,
    VehicleComponent,
    OtherComponent,
    HomeProfileComponent,
    OdsComponent,
    FinesComponent,
    DataVehicleComponent,
    DataDocumentsComponent,
    CalendarComponent,
    AlertsComponent,
    HomeAlertsComponent,
    PlatecityComponent,
  ],
  providers: [
    Title,
    GeolocationService,
    AuthService,
    CommonService,
    AlertService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    provideFirebaseApp(() => initializeApp(environment.firabase)),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
  ],
  bootstrap: [AppComponent],
  exports: [SharedModule, FullCalendarModule, NgxCustomModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
