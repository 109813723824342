import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AppService } from '../../../shared/service/app/app.service';
import { AlertService } from './../../service/common/alert.service';
import { CommonService } from './../../service/common/common.service';

import { UserSendCodeDTO } from './../../dto/user.dto';
import { Codes } from './../../constants/messages/codes';

@Component({
  selector: 'app-comfirmation-message',
  templateUrl: './comfirmation-message.component.html',
  styleUrl: './comfirmation-message.component.css',
})
export class ConfirmationmessageComponent {
  @Input() user: UserSendCodeDTO | null = null;

  showmodal: boolean = true;
  countdown: number = 5;
  verifycode: string = '';
  code1: string = 'x';
  code2: string = 'x';
  code3: string = 'x';
  code4: string = 'x';
  code5: string = 'x';
  code6: string = 'x';
  interval: any;
  store: any;
  failedAttempts: number = 0;
  maxAttempts: number = 5;
  currYear: number = new Date().getFullYear();

  constructor(
    public translate: TranslateService,
    public storeData: Store<any>,
    public router: Router,
    private appSetting: AppService,
    private alertService: AlertService,
    private commonService: CommonService,
  ) {
    this.initStore();
  }
  onRegister() {
    // Muestra el modal
    this.showmodal = true;
    this.startCountdown();
  }
  ngOnInit() {
    this.startCountdown(); // Inicia el contador al abrir el modal
  }

  async initStore() {
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }
  async verifyCode() {
    const enteredCode = `${this.code1}${this.code2}${this.code3}${this.code4}${this.code5}${this.code6}`;
    if (this.user) {
      const rsp = await this.commonService.validateSmsCode({ ...this.user, code: enteredCode });
      if (rsp.code.code === Codes.PTZ0000) {
        this.alertService.success(rsp.code.message['es']);
        this.router.navigate(['/']);
      } else {
        this.failedAttempts++;
        this.alertService.error(rsp.code.message['es']);
      }
    }
  }

  startCountdown() {
    this.countdown = 5;
    this.interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  resendCode() {
    if (this.failedAttempts >= this.maxAttempts) {
      // Si el usuario ha alcanzado el número máximo de intentos fallidos
      this.alertService.error('Has alcanzado el número máximo de intentos. Por favor, inténtalo más tarde.');
    } else {
      // Si aún no ha superado el límite de intentos, se inicia el contador y se puede reenviar
      this.startCountdown();
      // Aquí puedes agregar la lógica para reenviar el código al usuario
      console.log('Código reenviado');
    }
  }

  closeModal() {
    const data = this.alertService.confirm('Are you sure you want to close');
    console.log(data);
    this.showmodal = false;
    clearInterval(this.interval);
  }

  changeLanguage(item: any) {
    this.translate.use(item.code);
    this.appSetting.toggleLanguage(item);
    if (this.store.locale?.toLowerCase() === 'ae') {
      this.storeData.dispatch({ type: 'toggleRTL', payload: 'rtl' });
    } else {
      this.storeData.dispatch({ type: 'toggleRTL', payload: 'ltr' });
    }
    // window.location.reload();
  }
}
