import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AvataresService {
  constructor(private http: HttpClient) {
    // do nothing
  }

  getAvatares() {
    return this.http
      .get<any>(environment.apiUrl + '/avatares')
      .pipe(map((avatares) => avatares.map((avatar: any) => ({ ...avatar, img_avatar: avatar.img_avatar }))));
  }
}
