export const initialMessage = {
  user_id: '',
  session_id: '',
  role: 'user',
  context: {
    intent: '',
    entities: [],
    previousMessages: [],
    locale: '',
  },
  id: '',
  object: '',
  created_at: 0,
  assistant_id: '',
  thread_id: '',
  run_id: '',
  content: [
    {
      type: 'text',
      text: {
        value: 'Hola, podríamos iniciar una conversación. ¿Dime Quien eres y cómo puedes ayudarme?',
        annotations: [],
      },
    },
  ],
  attachments: [],
  metadata: {
    source: '',
    userAgent: '',
    ip: '',
  },
};
