import { AfterViewInit, Component, Input } from '@angular/core';
import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

@Component({
  selector: 'app-workshop-photos',
  templateUrl: './workshop-photos.component.html',
  styleUrl: './workshop-photos.component.css',
})
export class WorkshopPhotosComponent implements AfterViewInit {
  @Input() items: string[] | undefined = [];
  swiper2: any;
  // items = ['https://rmservicosautomotivos.com.br/imagens/noticias/56_1_1.jpg', 'https://tse3.mm.bing.net/th?id=OIP.oevHw_XbzeC1xgO1vQqbggHaE6&pid=Api&P=0&h=18.jpg', 'https://tse4.mm.bing.net/th?id=OIP.ooynGjMXNga_mZuw9QoMdgHaEK&pid=Api&P=0&h=180'];
  ngAfterViewInit() {
    this.swiper2 = new Swiper('#slider2', {
      modules: [Navigation, Autoplay],
      navigation: { nextEl: '.swiper-button-next-ex2', prevEl: '.swiper-button-prev-ex2' },
      autoplay: { delay: 2000 },
    });
  }
}
