<div>
    <img
    *ngIf="isDarkMode === false"
    src="../../../../assets/images/logo-22.png"
    alt="img"
    class="mx-auto w-36 animate__animated animate__backInDown animate__delay-1s"
  />
  <img
    *ngIf="isDarkMode === true"
    src="../../../../assets/images/logo-23.png"
    alt="img"
    class="mx-auto w-36 animate__animated animate__backInDown animate__delay-1s"
  />
    <div class="panel text-black dark:text-white">
        <div class="mb-5">
            <div class="mb-4 flex flex-col items-center justify-center sm:flex-row sm:justify-between animate__animated animate__backInDown animate__delay-1s">
                <div class="mb-4 sm:mb-0">
                    <div class="text-center text-lg font-semibold ltr:sm:text-left rtl:sm:text-right">Calendario</div>
                    <!-- <div class="mt-2 flex flex-wrap items-center justify-center sm:justify-start">
                        <div class="flex items-center ltr:mr-4 rtl:ml-4">
                            <div class="h-2.5 w-2.5 rounded-sm text-black dark:text-white bg-primary ltr:mr-2 rtl:ml-2"></div>
                            <div>Tareas</div>
                        </div>
                        <div class="flex items-center ltr:mr-4 rtl:ml-4">
                            <div class="h-2.5 w-2.5 rounded-sm bg-info ltr:mr-2 rtl:ml-2"></div>
                            <div>Citas</div>
                        </div>
                        <div class="flex items-center ltr:mr-4 rtl:ml-4">
                            <div class="h-2.5 w-2.5 rounded-sm bg-success ltr:mr-2 rtl:ml-2"></div>
                            <div>Personal</div>
                        </div>
                        <div class="flex items-center">
                            <div class="h-2.5 w-2.5 rounded-sm bg-green-900 ltr:mr-2 rtl:ml-2"></div>
                            <div>Importante</div>
                        </div>
                    </div> -->
                </div>
                <button type="button" class="btn btn-primary" (click)="editEvent()">
                    <icon-plus class="ltr:mr-2 rtl:ml-2" />
                    Crear Evento
                </button>
            </div>
            <div class="calendar-wrapper">
                <full-calendar #calendar [options]="calendarOptions" [deepChangeDetection]="true">
                    <ng-template #eventContent let-arg>
                        <div class="fc-event-main-frame flex items-center px-1 py-0.5 text-white animate__animated animate__backInDown animate__delay-1s">
                            
                            @if (!arg.event.extendedProps.icon) {
                                <div class="fc-event-time px-0.5 font-semibold">{{ arg.timeText }}</div>
                            }
    
                            <div class="fc-event-title-container flex items-center">
                                @if (arg.event.extendedProps.icon) {
                                    <img [src]="arg.event.extendedProps.icon" alt="icono" class=" w-12 h-12 ml-16" />
                                }
                                @if (!arg.event.extendedProps.icon) {
                                    <div class="fc-event-title fc-sticky px-0.5 !font-medium">{{ arg.event.title }}</div>
                                }
                            </div>
                        </div>
                    </ng-template>
                </full-calendar>
                
            </div>
        </div>
    </div>
    <!-- modal -->
    <ngx-custom-modal #isAddEventModal class="no-footer ">
        <ng-template #modalHeader>
            <div class="">{{ params.value.id ? 'Edit Event' : 'agregar evento' }}</div>
        </ng-template>
        <ng-template #modalBody>
            <form [formGroup]="params" class="text-sm w-full p-5 border rounded-md bg-center bg-azulOscuro text-white dark:text-white" (submit)="saveEvent()" ngNativeValidate>
                <div class="mb-5 animate__animated animate__flipInY">
                    <label for="title">Título del evento :</label>
                    <input id="title" type="text" name="title" class="form-input" placeholder="Ingresar Título" formControlName="title" required />
                    <div class="mt-2 text-danger" id="titleErr"></div>
                </div>

                <div class="mb-5 animate__animated animate__flipInY">
                    <label for="dateStart">De :</label>
                    <input
                        id="dateStart"
                        type="datetime-local"
                        name="start"
                        class="form-input"
                        placeholder="Event Start Date"
                        formControlName="start"
                        [min]="minStartDate"
                        (change)="startDateChange($event)"
                        required
                    />
                    <div class="mt-2 text-danger" id="startDateErr"></div>
                </div>
                <div class="mb-5 animate__animated animate__flipInY">
                    <label for="dateEnd">A :</label>
                    <input
                        id="dateEnd"
                        type="datetime-local"
                        name="end"
                        class="form-input"
                        placeholder="Event End Date"
                        formControlName="end"
                        [min]="minEndDate"
                        required
                    />
                    <div class="mt-2 text-danger" id="endDateErr"></div>
                </div>
                <div class="mb-5 animate__animated animate__flipInY">
                    <label for="description">Descripción del evento :</label>
                    <textarea
                        id="description"
                        name="description"
                        class="form-textarea min-h-[130px]"
                        placeholder="Enter Event Description"
                        formControlName="description"
                    ></textarea>
                </div>
                <!-- <div class="mb-5 animate__animated animate__flipInY">
                    <label>Tipo de Evento</label>
                    <div class="mt-3">
                        <label class="inline-flex cursor-pointer ltr:mr-3 rtl:ml-3">
                            <input type="radio" class="form-radio" name="badge" value="primary" formControlName="type" name="type" />
                            <span class="ltr:pl-2 rtl:pr-2">Tareas</span>
                        </label>
                        <label class="inline-flex cursor-pointer ltr:mr-3 rtl:ml-3">
                            <input type="radio" class="form-radio text-info" name="badge" value="info" formControlName="type" name="type" />
                            <span class="ltr:pl-2 rtl:pr-2">Citas</span>
                        </label>
                        <label class="inline-flex cursor-pointer ltr:mr-3 rtl:ml-3">
                            <input type="radio" class="form-radio text-success" name="badge" value="success" formControlName="type" name="type" />
                            <span class="ltr:pl-2 rtl:pr-2">Personal</span>
                        </label>
                        <label class="inline-flex cursor-pointer">
                            <input type="radio" class="form-radio text-green-900" name="badge" value="danger" formControlName="type" name="type" />
                            <span class="ltr:pl-2 rtl:pr-2">Importante</span>
                        </label>
                    </div>
                </div> -->
                <div class="mt-8 flex items-center justify-end">
                    <button type="button" class="btn btn-outline-danger" (click)="isAddEventModal.close()">Cancelar</button>
                    <button type="submit" class="btn btn-primary ltr:ml-4 rtl:mr-4">{{ params.value.id ? 'Update Event' : 'Crear Evento' }}</button>
                </div>
            </form>
        </ng-template>
    </ngx-custom-modal>
</div>
