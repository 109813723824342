<div class="flex flex-wrap h-full sm:h-full md:h-full lg:h-screen xl:h-screen">
    <div *ngIf="isMapVisible" class="absolute top-3 right-5 z-50">
  <button (click)="toggleMap()" class="bg-primary block sm:inline-block font-bold text-white px-4 py-2 rounded">
    Volver al chat
  </button>
</div>

<!-- Assistant -->
<div *ngIf="!isMapVisible || !isSmallScreen" @slideDownUp class="h-full w-full md:w-6/12 relative">
    
    <ng-container>
        <div class="relative h-full pb-[68px]">
            
            <div class="flex items-center justify-between pt-4 pl-4">
                <div class="flex items-center space-x-2 rtl:space-x-reverse text-2xl animate__animated animate__rollIn animate__delay-1s">
                        <div id="bot" class="neutral">
                            <div id="head">
                                <div id="left-ear">
                                    <div id="left-ear-inner"></div>
                                </div>
                                <div id="face">
                                    <div id="eyes">
                                        <div id="left-eye"></div>
                                        <div id="right-eye"></div>
                                    </div>
                                    <div id="mouth"></div>
                                </div>
                                <div id="right-ear">
                                    <div id="right-ear-inner"></div>
                                </div>
                            </div>
                        </div>
                        <span class="white-color text-black dark:text-white">{{ 'ASSISTANT.TITLE' | translate }}</span>
                        <!-- SWITCH -->
                        <label class="w-12 h-6 relative" *ngIf="isSmallScreen">
                            <p class="absolute top-1/2 left-1/2 pb-10 animate-pulse transform -translate-x-1/2 -translate-y-1/2 text-[10px] text-black dark:text-white">mapa</p>
                            <input type="checkbox" alt="Map" placeholder="Mapa" class="custom_switch absolute w-full h-full opacity-0 z-10 cursor-pointer peer" id="custom_switch_checkbox6" (change)="toggleMap()" [checked]="isMapVisible" />
                            <span for="custom_switch_checkbox6" class="outline_checkbox border-2 border-dark dark:border-white-dark block h-full rounded-full before:absolute before:left-1 before:bg-dark dark:before:bg-white-light before:bottom-1 before:w-4 before:h-4 before:rounded-full peer-checked:before:left-7 peer-checked:border-primary peer-checked:before:bg-primary before:transition-all before:duration-300"></span>
                        </label>
                    </div>
                </div>
                <!--  -->

                <div class="flex items-center justify-between pl-4">
                    <div class="flex items-center space-x-2 rtl:space-x-reverse text-black dark:text-white animate__animated animate__fadeInTopRight animated__delay-1s">
                        <p>{{   'ASSISTANT.SUBTITLE' | translate    }}</p>
                    </div>
                </div>

                <ng-scrollbar
                    #scrollable
                    class="chat-conversation-box relative !h-[calc(100vh_-_289px)] min-h-[300px] sm:!h-[calc(100vh_-_300px)]"
                    visibility="native"
                >
                    <div class="space-y-5 p-4">
                        <div class="m-6 mt-0 block">
                            <h4 class="relative border-b border-[#6208ff] text-center text-xs dark:border-gray-800">
                                <span class="relative top-2 text-white bg-black px-3 dark:bg-[#0e1726]">{{ fechaActual | date:'mediumDate' }}</span>
                            </h4>
                        </div>
                        <ng-container *ngIf="messages">

                            <div *ngFor="let message of messages">
                                <div class="flex items-start gap-3" [ngClass]="{ 'justify-end': message.role === 'user' }">
                                    <div class="flex-none" [ngClass]="message.role === 'user' ? 'order-2 thumb-user' : 'thumb-assistant'">
                                        <ng-container *ngIf="message.role !== 'user'">
                                            <icon-ai></icon-ai>
                                        </ng-container>
                                        <ng-container *ngIf="message.role === 'user'">
                                            <icon-user></icon-user>
                                        </ng-container>
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex items-center gap-3">
                                            <div
                                                class="rounded-md bg-black/10 p-4 py-2 text-white dark:bg-gray-800"
                                                [ngClass]="message.role === 'user' ? 'ltr:rounded-br-none rtl:rounded-bl-none ' : 'ltr:rounded-bl-none rtl:rounded-br-none !bg-primary text-dark'"
                                            >
                                                {{ message.content[0].text.value }}
                                            </div>
                                        </div>
                                        <div class="text-xs text-white-dark" [ngClass]="{ 'ltr:text-right rtl:text-left': message.role === 'user' }">
                                            {{ message.created_at ? core.formatChatTimestamp(message.created_at) : '' }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Loading points -->
                            <div *ngIf="loadingConversation" style="text-align: center;">
                              <span class="animate-spin border-4 border-warning border-l-transparent rounded-full w-10 h-10 inline-block align-middle m-auto mb-10"></span>
                            </div>

                        </ng-container>
                    </div>
                </ng-scrollbar>
                <div class="absolute bottom-0 left-0 w-full p-4">
                    <div class="w-full items-center space-x-3 rtl:space-x-reverse sm:flex">
                        <div class="relative flex-1">
                            <input
                              [(ngModel)]="textMessage"
                              class="form-input rounded-full border-0 bg-[#f4f4f4] px-6 py-2 focus:outline-none"
                              placeholder="{{loadingConversation ? 'El asistente está escribiendo...' : 'Escribe aquí...'}}"
                              name="textMessage"
                              (keyup.enter)="sendMessage()"
                              [disabled]="loadingConversation"
                              />
                            <button
                              type="button"
                              class="absolute top-1/2 -translate-y-1/2 hover:text-primary ltr:right-4 rtl:left-4"
                              (click)="sendMessage()"
                            >
                              <icon-send />
                            </button>
                        </div>                       
                    </div>
                </div>
            </div>
        </ng-container>        
        
    </div>
    <!-- Map -->
    <div *ngIf="isMapVisible || !isSmallScreen" 
    class="h-full w-full md:w-6/12 relative animate__animated animate__fadeInTopRight animated__delay-1s"
    [ngClass]="{ 'h-screen': isSmallScreen, 'md:w-6/12': !isSmallScreen }">
    <app-map (assistantSchedule)="assistantSchedule($event)"></app-map>
</div>
<!-- Map -->
</div>


<div class="flex">
    <button type="button" (click)="backToHome()" class="btn btn-dark text-black dark:text-white bg-primary w-full">VOLVER</button>
</div>
<div class="mt-1">
    <p class="copy w-full text-center text-black dark:text-white animated__animated animate__backInDown animated__delay-1s">
        &copy; 2024 PITZON. {{ 'HOME.FOOTER' | translate }}
    </p>
</div>