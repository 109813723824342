import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  // Método genérico para mostrar una alerta personalizada
  showAlert(options: SweetAlertOptions): Promise<any> {
    return Swal.fire(options);
  }

  // Método para una alerta de éxito
  success(message: string, title: string = 'Success'): void {
    Swal.fire({
      title: title,
      text: message,
      icon: 'success',
      confirmButtonText: 'OK',
    });
  }

  // Método para una alerta de error
  error(message: string, title: string = 'Error'): void {
    Swal.fire({
      title: title,
      text: message,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  }

  // Método para una alerta de confirmación
  confirm(message: string, title: string = 'Are you sure?', confirmButtonText: string = 'Yes', cancelButtonText: string = 'No'): Promise<any> {
    return Swal.fire({
      title: title,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    });
  }
}
