<div class="flex justify-center">
  <div class="space-y-8 pt-5">
    <!-- <div class="panel flex overflow-x-auto whitespace-nowrap p-3 text-primary"> -->
      <!-- <span class="text-center bg-black h-20 flex justify-center">
        <icon-bell />
      </span> -->
      <div class="flex items-center space-y-5">
        <div class="panel bg-white dark:bg-azulOscuro">
          <div class="mb-5">
            <h2 class="text-center text-primary text-4xl font-extrabold">Los números hablan por nosotros</h2>
          </div>
          <div class="mx-auto mb-5 grid max-w-[990px] grid-cols-3 justify-items-center gap-10">
            <div>
              <div
                class="flex h-[90px] w-[90px] flex-col justify-center rounded-full border border-[#e0e6ed] shadow-[1px_2px_12px_0_rgba(31,45,61,0.10)] dark:border-[#1b2e4b] sm:h-[100px] sm:w-[100px]"
              >
                <div class="text-center text-xl text-primary sm:text-3xl" [countUp]="105" [options]="{ startVal: 0, duration: 7 }"></div>
              </div>
              <h4 class="mt-4 text-center text-xs font-semibold text-[#3b3f5c] dark:text-white-dark sm:text-[15px]">
                <icon-users class="mx-auto mb-2 text-primary sm:h-6 sm:w-6" />
                Clients
              </h4>
            </div>
            <div>
              <div
                class="flex h-[90px] w-[90px] flex-col justify-center rounded-full border border-[#e0e6ed] shadow-[1px_2px_12px_0_rgba(31,45,61,0.10)] dark:border-[#1b2e4b] sm:h-[100px] sm:w-[100px]"
              >
                <div class="text-center text-xl text-primary sm:text-3xl" [countUp]="90" [options]="{ startVal: 0, duration: 7 }"></div>
              </div>
              <h4 class="mt-4 text-center text-xs font-semibold text-[#3b3f5c] dark:text-white-dark sm:text-[15px]">
                <icon-cloud-download class="mx-auto mb-2 text-primary sm:h-6 sm:w-6" />
                Downloads
              </h4>
            </div>
            <div>
              <div
                class="flex h-[90px] w-[90px] flex-col justify-center rounded-full border border-[#e0e6ed] shadow-[1px_2px_12px_0_rgba(31,45,61,0.10)] dark:border-[#1b2e4b] sm:h-[100px] sm:w-[100px]"
              >
                <div class="text-center text-xl text-primary sm:text-3xl" [countUp]="58" [options]="{ startVal: 0, duration: 7 }"></div>
              </div>
              <h4 class="mt-4 text-center text-xs font-semibold text-[#3b3f5c] dark:text-white-dark sm:text-[15px]">
                <icon-award class="mx-auto mb-2 text-primary sm:h-6 sm:w-6" />
                Ciudades
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

