<div class="flex flex-wrap h-full sm:h-full md:h-full lg:h-screen xl:h-screen">

  <!-- Home Content -->
  <div class="w-full md:w-6/12 relative flex flex-col items-center justify-center p-4">
    <!-- <h1 class="text-6xl text-primary font-extrabold primary-color animate__animated animate__zoomInUp animate__delay-1s text-center whitespace-nowrap">
      PITZ<span><img src="../../assets/images/logop.png" class="w-12 inline" alt=""></span>N.<span class="text-primary dark:text-white font-bold">IO</span>
    </h1>     -->
     <h1 class="text-6xl text-primary font-extrabold primary-color animate__animated animate__zoomInUp animate__delay-1s text-center whitespace-nowrap">
      <!-- Imagen para el modo claro -->
      <img *ngIf="isDarkMode === false"
           src="../../assets/images/logo-22.png"
           class="w-72 inline"
           alt="Logo oscuro" />
    
      <!-- Imagen para el modo oscuro -->
      <img *ngIf="isDarkMode === true"
           src="../../assets/images/logo-23.png"
           class="w-72 inline"
           alt="Logo claro" />
    </h1>
    
    <p class="text-xl mt-2 pt-5 pb-2 animate__animated animate__bounce font-bold text-center 
    text-black dark:text-white">
  {{ 'HOME.SLOGAN' | translate }}
</p>
<p class="text-xl mt-6 animate__animated animate__bounce font-bold text-center 
    text-black dark:text-white">
  {{ 'HOME.DESCRIPTION' | translate }}
</p>

    <!-- Botón traducido -->
    <div class="mt-6">
      <button (click)="goToAssistant()" class="mt-6 bg-primary text-black text-xl font-bold py-2 px-3 rounded-full animate-pulse">
        {{ 'HOME.BUTTON' | translate }}
      </button>
    </div>

    <!-- Servicios -->
    <div class="mt-6 block animate__animated animate__zoomInDown animated__delay-2s text-center">
      <span class="bg-dark p-1 rounded-full text-white text-xs m-1 inline-block" *ngFor="let ser of services">
        {{ser}}
      </span>
    </div>

    <div class="mt-4 pb-16 text-center animate__animated animate__jackInTheBox animate__delay-1s">
      <p>
        {{ 'HOME.ACCEPTANCE_TEXT' | translate }}
        <a href="#" class="text-black dark:text-white">{{ 'HOME.TERMS_CONDITIONS' | translate }}</a>
        {{ 'HOME.AND' | translate }}
        <a href="#" class="text-black dark:text-white">{{ 'HOME.PRIVACY_POLICY' | translate }}</a>.
      </p>
    </div>
  </div>

   <!-- Map -->
   <div
   class="h-full w-full md:w-6/12 relative animate__animated animate__fadeInTopRight animated__delay-1s"
   [ngClass]="{ 'h-screen': isSmallScreen, 'md:w-6/12': !isSmallScreen }">
   <app-map (assistantSchedule)="($event)"></app-map>
   </div>
   <!-- Map -->

  <div class="flex items-center justify-center mt-1">
    <p class="copy text-center text-black pl-10 dark:text-white animate__animated animate__backInDown animate__delay-1s">
      &copy; 2024 PITZON. {{ 'HOME.FOOTER' | translate }}
    </p>
</div>
</div>
