import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { toggleAnimation } from 'src/app/shared/animations';
import { Store } from '@ngrx/store';
import { AnalitycsService } from 'src/app/shared/service/analitycs.service';
import moment from 'moment';

@Component({
  selector: 'app-profile',
  animations: [toggleAnimation],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css',
})
export class ProfileComponent {
  isDarkMode = false;
  store: any;
  selectedAvatar: string | null = null;

  constructor(
    private router: Router,
    private storeData: Store<any>,
    private analitycs: AnalitycsService,
  ) {
    this.analitycs.logEvent('InitialProfile', {
      view: 'profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    localStorage.setItem('light', 'dark');
    this.storeData
      .select((state) => state.index.theme)
      .subscribe((theme) => {
        this.isDarkMode = theme === 'dark';
      });
  }
  ngOnInit() {
    this.analitycs.logEvent('InitialProfile', {
      view: 'profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.selectedAvatar = localStorage.getItem('selectedAvatar') ?? 'assets/images/juan2.png';
    // const storedAvatar = localStorage.getItem('selectedAvatar'); // Obtiene el avatar guardado
    // if (storedAvatar) {
    //   this.selectedAvatar = storedAvatar; // Si existe, se lo asignamos
    // } else {
    //   this.selectedAvatar = 'assets/images/juan1.png'; // Opción predeterminada (Juan)
    // }
  }

  goToEditProfile() {
    this.analitycs.logEvent('GoToEditProfile', {
      view: 'profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['/edit-profile']);
  }
  goBack() {
    this.analitycs.logEvent('backToHome', {
      view: 'profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['/']);
  }
}
