<div [ngClass]="{ 'dark text-white-dark': store.semidark }">
    <nav class="sidebar fixed bottom-0 top-0 z-50 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300">
        <div class="h-full bg-white dark:bg-[#0e1726]">
            <div class="flex items-center justify-between px-4 py-3">
                <a routerLink="/" class="main-logo flex shrink-0 items-center">
                    <img class="ml-[5px] w-8 flex-none" src="../../assets/images/logop.png" alt="" />
                    <span class="block align-middle text-2xl font-bold transition-all duration-300 ltr:ml-1.5 rtl:mr-1.5 dark:text-primary md:inline animate__animated animate__delay-1s animate__lightSpeedInRight">
                        PITZON.<span class="text-white">IO</span>
                    </span></a>
                <a
                    href="javascript:;"
                    class="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 hover:text-primary rtl:rotate-180 dark:text-white-light dark:hover:bg-dark-light/10"
                    (click)="storeData.dispatch({ type: 'toggleSidebar'})"
                >
                    <icon-carets-down class="m-auto rotate-90" />
                </a>
            </div>
            <ng-scrollbar class="relative !h-[calc(100vh-80px)]" appearance="compact">
                <ul class="relative space-y-0.5 p-4 py-0 font-semibold">
                   
                    <h2 class="-mx-4 mb-1 flex items-center bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
                        <icon-minus class="hidden h-5 w-4 flex-none" />
                        <span>{{ ('user_and_pages' | translate) || '&nbsp;' }}</span>
                    </h2>

                    <li class="accordion menu nav-item">
                        <button
                            type="button"
                            class="nav-link group w-full"
                            [ngClass]="{ active: activeDropdown.includes('authentication') }"
                            (click)="toggleAccordion('authentication')"
                        >
                            <div class="flex items-center">
                                <icon-menu-authentication class="shrink-0 group-hover:!text-primary" />

                                <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark"
                                    >{{ 'authentication' | translate }}</span
                                >
                            </div>
                            <div [ngClass]="{ 'rtl:rotate-90 -rotate-90': !activeDropdown.includes('authentication') }">
                                <icon-caret-down />
                            </div>
                        </button>
                        
                    </li>

                </ul>
            </ng-scrollbar>
        </div>
    </nav>
</div>
