// auth.service.ts
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { Session } from '../../../utils/session.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {
    // Get the session
  }
  getSession() {
    const session = localStorage.getItem('session');
    if (session) {
      try {
        return JSON.parse(session);
      } catch (error) {
        throw error;
      }
    }
    return null;
  }

  // Set session
  setSession(dataSession: any, isTemporal: boolean = false) {
    const localSession: Session = {
      idClient: dataSession.DBmessage.idClient,
      idTemporaly: dataSession.DBmessage.idTemporaly,
      name: '',
      lastname: '',
      email: '',
      lastLogin: moment().format('YYYY-MM-DD HH:mm:ss'),
      isTemporary: isTemporal,
      token: dataSession.token,
    };
    localStorage.setItem('session', JSON.stringify(localSession));
  }

  // Get token
  getToken() {
    const session: any = localStorage.getItem('session');
    if (session) {
      try {
        const __session = JSON.parse(session);
        return __session.token;
      } catch (error) {
        throw error;
      }
    }
    return null;
  }
}
