import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnalitycsService } from 'src/app/shared/service/analitycs.service';
import moment from 'moment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.css',
})
export class EditProfileComponent {
  activeTab = 'vehicle';
  isDarkMode = false;
  store: any;

  constructor(
    private router: Router,
    private storeData: Store<any>,
    private analitycs: AnalitycsService,
  ) {
    this.analitycs.logEvent('localStorage', {
      view: 'edit-profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    localStorage.setItem('light', 'dark');
    this.storeData
      .select((state) => state.index.theme)
      .subscribe((theme) => {
        this.isDarkMode = theme === 'dark';
      });
  }
  goBack() {
    this.analitycs.logEvent('GoToProfile', {
      view: 'edit-profile',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['/profile']);
  }
}
