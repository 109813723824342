import { Component, OnInit } from '@angular/core';
import { toggleAnimation } from 'src/app/shared/animations';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../shared/service/app/app.service';
import { AnalitycsService } from 'src/app/shared/service/analitycs.service';
import moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [toggleAnimation],
})
export class CoverLoginComponent implements OnInit {
  spanish: boolean = true;
  isDarkMode: boolean = false;
  currentImage: string = '';
  loginForm: FormGroup;
  store: any;
  currYear: number = new Date().getFullYear();
  constructor(
    public translate: TranslateService,
    public storeData: Store<any>,
    public router: Router,
    private appSetting: AppService,
    private analitycs: AnalitycsService,
    private fb: FormBuilder,
  ) {
    this.initStore();
    //Reactive-Form
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      acceptTerms: [false, Validators.requiredTrue],
    });
  }

  onSubmit() {
    this.analitycs.logEvent('login', {
      view: 'login',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    if (this.loginForm.valid) {
      console.log('Formulario enviado:', this.loginForm.value);
    } else {
      console.log('Formulario inválido');
    }
    this.router.navigate(['/']);
  }

  ngOnInit() {
    this.storeData
      .select((state) => state.index.locale)
      .subscribe((locale) => {
        this.spanish = locale === 'es';

        // Actualizar la imagen según el idioma
        this.currentImage = locale === 'en' ? '../../assets/images/pit2inglish.jpg' : '../../assets/images/pit2.jpg';
      });
    localStorage.setItem('light', 'dark');
    this.storeData
      .select((state) => state.index.theme)
      .subscribe((theme) => {
        this.isDarkMode = theme === 'dark';
      });
  }

  async initStore() {
    this.analitycs.logEvent('init login', {
      view: 'login',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }
  networks() {
    this.analitycs.logEvent('networks', {
      view: 'login',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
  }
  GoToRegister() {
    this.analitycs.logEvent('GoToRegister', {
      view: 'login',
      action: 'click',
      at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    this.router.navigate(['../register']);
  }
  forgotPassword() {
    this.router.navigate(['../forgot-password']);
  }
}
