import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.css'],
})
export class Error404Component {
  // Otros códigos...

  constructor(
    private router: Router,
    private translate: TranslateService,
  ) {
    // do nothing
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  // Otros métodos...
}
