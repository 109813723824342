import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxCustomModalComponent } from 'ngx-custom-modal';

@Component({
  selector: 'app-homealerts',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeAlertsComponent implements OnInit, OnDestroy {
  @ViewChild('modal19') modal19!: NgxCustomModalComponent;
  private navigationSubscription!: Subscription;
  private modalTimeout!: ReturnType<typeof setTimeout>;
  isModalOpen = false; // Estado del modal

  constructor(private router: Router) {
    // do nothing
  }

  ngOnInit(): void {
    // Verificar si el modal ya fue mostrado
    const isModalShown = localStorage.getItem('modalShown');
    if (!isModalShown) {
      this.openModalAfterDelay();
    }

    // Suscribirse a los eventos de navegación
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Cancelar el temporizador si hay navegación
        clearTimeout(this.modalTimeout);
        if (this.isModalOpen) {
          this.closeModal();
        }
      }
    });
  }

  openModalAfterDelay(): void {
    this.modalTimeout = setTimeout(() => {
      this.modal19.open();
      this.isModalOpen = true; // Actualizar el estado
      localStorage.setItem('modalShown', 'true'); // Marcar como mostrado
    }, 6000); // 6 segundos de retraso
  }

  closeModal(): void {
    this.modal19.close();
    this.isModalOpen = false; // Actualizar el estado
  }

  gotovehicles(): void {
    this.router.navigate(['/edit-profile']);
  }

  ngOnDestroy(): void {
    // Limpiar: cancelar temporizador y desuscribirse
    clearTimeout(this.modalTimeout);
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}
