import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { signal, Component, ContentChild, Input, HostListener } from '@angular/core';
const _c0 = ["modalHeader"];
const _c1 = ["modalBody"];
const _c2 = ["modalFooter"];
function NgxCustomModalComponent_Conditional_0_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgxCustomModalComponent_Conditional_0_button_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function NgxCustomModalComponent_Conditional_0_button_5_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.close());
    });
    i0.ɵɵtext(1, " \xD7 ");
    i0.ɵɵelementEnd();
  }
}
function NgxCustomModalComponent_Conditional_0_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgxCustomModalComponent_Conditional_0_ng_container_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgxCustomModalComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "div", 3)(3, "div", 4);
    i0.ɵɵtemplate(4, NgxCustomModalComponent_Conditional_0_ng_container_4_Template, 1, 0, "ng-container", 5)(5, NgxCustomModalComponent_Conditional_0_button_5_Template, 2, 0, "button", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 7);
    i0.ɵɵtemplate(7, NgxCustomModalComponent_Conditional_0_ng_container_7_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "div", 8);
    i0.ɵɵtemplate(9, NgxCustomModalComponent_Conditional_0_ng_container_9_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd()()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("in", ctx_r1.visibleAnimate());
    i0.ɵɵproperty("ngClass", ctx_r1.getCustomClass());
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.header);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.shouldHideCloseButton());
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.body);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.footer);
  }
}
class NgxCustomModalComponent {
  constructor(elementRef, changeDetectorRef, renderer) {
    this.elementRef = elementRef;
    this.changeDetectorRef = changeDetectorRef;
    this.renderer = renderer;
    this.header = null;
    this.body = null;
    this.footer = null;
    // Controls whether the modal closes when clicking outside of it
    this.closeOnOutsideClick = true;
    // Controls whether the modal closes when pressing the escape key
    this.closeOnEscape = true;
    // Option to hide the close button
    this.hideCloseButton = false;
    // Modal options
    this.options = {};
    this.visible = signal(false);
    this.visibleAnimate = signal(false);
  }
  ngOnDestroy() {
    // Ensures the modal closes and cleans up resources when the component is destroyed
    this.close();
  }
  /**
   * Opens the modal
   */
  open() {
    this.renderer.addClass(document.body, 'modal-open');
    this.visible.set(true);
    setTimeout(() => this.visibleAnimate.set(true));
  }
  /**
   * Closes the modal
   */
  close() {
    this.renderer.removeClass(document.body, 'modal-open');
    this.visibleAnimate.set(false);
    setTimeout(() => {
      this.visible.set(false);
      this.changeDetectorRef.markForCheck();
    }, 200);
  }
  /**
   * Event handler for clicking on the modal container.
   * Closes the modal if clicked outside the modal content.
   */
  onContainerClicked(event) {
    const closeOnOutsideClick = this.options.closeOnOutsideClick ?? this.closeOnOutsideClick;
    if (event.target.classList.contains('modal') && this.isTopMost() && closeOnOutsideClick) {
      this.close();
    }
  }
  /**
   * Keyboard event handler to close the modal with the Escape key.
   */
  onKeyDownHandler(event) {
    const closeOnEscape = this.options.closeOnEscape ?? this.closeOnEscape;
    if (event.key === 'Escape' && this.isTopMost() && closeOnEscape) {
      this.close();
    }
  }
  /**
   * Determines if this modal is the topmost in the stack of modals.
   *
   * @returns {boolean} True if the modal is the topmost.
   */
  isTopMost() {
    return !this.elementRef.nativeElement.querySelector(':scope app-modal > .modal');
  }
  /**
   * Gets the custom class to be added to the modal.
   *
   * @returns {string} The custom class.
   */
  getCustomClass() {
    return this.customClass ?? this.options.customClass ?? '';
  }
  /**
   * Determines if the close button should be hidden.
   *
   * @returns {boolean} True if the close button should be hidden.
   */
  shouldHideCloseButton() {
    return this.hideCloseButton || this.options.hideCloseButton || false;
  }
  static {
    this.ɵfac = function NgxCustomModalComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxCustomModalComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxCustomModalComponent,
      selectors: [["ngx-custom-modal"]],
      contentQueries: function NgxCustomModalComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c0, 5);
          i0.ɵɵcontentQuery(dirIndex, _c1, 5);
          i0.ɵɵcontentQuery(dirIndex, _c2, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.header = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.body = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footer = _t.first);
        }
      },
      hostBindings: function NgxCustomModalComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function NgxCustomModalComponent_click_HostBindingHandler($event) {
            return ctx.onContainerClicked($event);
          })("keydown", function NgxCustomModalComponent_keydown_HostBindingHandler($event) {
            return ctx.onKeyDownHandler($event);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      inputs: {
        closeOnOutsideClick: "closeOnOutsideClick",
        closeOnEscape: "closeOnEscape",
        customClass: "customClass",
        hideCloseButton: "hideCloseButton",
        options: "options"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [["role", "dialog", "tabindex", "-1", 1, "modal", "fade", 3, "in", "ngClass"], ["role", "dialog", "tabindex", "-1", 1, "modal", "fade", 3, "ngClass"], [1, "modal-dialog"], [1, "modal-content"], [1, "modal-header"], [4, "ngTemplateOutlet"], ["class", "close", "data-dismiss", "modal", "type", "button", "aria-label", "Close", 3, "click", 4, "ngIf"], [1, "modal-body"], [1, "modal-footer"], ["data-dismiss", "modal", "type", "button", "aria-label", "Close", 1, "close", 3, "click"]],
      template: function NgxCustomModalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, NgxCustomModalComponent_Conditional_0_Template, 10, 7, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.visible() ? 0 : -1);
        }
      },
      dependencies: [CommonModule, i1.NgClass, i1.NgIf, i1.NgTemplateOutlet],
      styles: ["[_nghost-%COMP%]     modal .modal{display:flex;flex:1;align-items:center;justify-content:center}[_nghost-%COMP%]     .modal{position:fixed;top:0;left:0;width:100%;min-height:100%;background-color:#00000026;z-index:42}[_nghost-%COMP%]     .modal.in{opacity:1}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCustomModalComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-custom-modal',
      standalone: true,
      imports: [CommonModule],
      template: "@if (visible()) {\n  <div class=\"modal fade\" role=\"dialog\" tabindex=\"-1\" [class.in]=\"visibleAnimate()\" [ngClass]=\"getCustomClass()\">\n    <div class=\"modal-dialog\">\n      <div class=\"modal-content\">\n        <div class=\"modal-header\">\n          <ng-container *ngTemplateOutlet=\"header\"></ng-container>\n          <button\n            *ngIf=\"!shouldHideCloseButton()\"\n            class=\"close\"\n            data-dismiss=\"modal\"\n            type=\"button\"\n            aria-label=\"Close\"\n            (click)=\"close()\"\n          >\n            \u00D7\n          </button>\n        </div>\n        <div class=\"modal-body\">\n          <ng-container *ngTemplateOutlet=\"body\"></ng-container>\n        </div>\n        <div class=\"modal-footer\">\n          <ng-container *ngTemplateOutlet=\"footer\"></ng-container>\n        </div>\n      </div>\n    </div>\n  </div>\n}\n",
      styles: [":host ::ng-deep modal .modal{display:flex;flex:1;align-items:center;justify-content:center}:host ::ng-deep .modal{position:fixed;top:0;left:0;width:100%;min-height:100%;background-color:#00000026;z-index:42}:host ::ng-deep .modal.in{opacity:1}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i0.Renderer2
  }], {
    header: [{
      type: ContentChild,
      args: ['modalHeader']
    }],
    body: [{
      type: ContentChild,
      args: ['modalBody']
    }],
    footer: [{
      type: ContentChild,
      args: ['modalFooter']
    }],
    closeOnOutsideClick: [{
      type: Input
    }],
    closeOnEscape: [{
      type: Input
    }],
    customClass: [{
      type: Input
    }],
    hideCloseButton: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    onContainerClicked: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onKeyDownHandler: [{
      type: HostListener,
      args: ['document:keydown', ['$event']]
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgxCustomModalComponent };
