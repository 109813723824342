import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { slideDownUp } from 'src/app/shared/animations';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [slideDownUp],
})
export class HomeComponent implements OnInit {
  isMobile: boolean = false;
  isMapVisible: boolean = false;
  isSmallScreen: boolean = false;
  currYear: number = new Date().getFullYear();
  services: string[] = [];
  isDarkMode: boolean = false;
  store: any;
  // storeData: any;
  // counter = signal<number>(0);

  constructor(
    private storeData: Store<any>,
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {
    this.setTranslatedServices();
    this.checkIfMobile();
  }

  ngOnInit() {
    this.checkIfMobile();
    this.storeData
      .select((state) => state.index.theme)
      .subscribe((theme) => {
        this.isDarkMode = theme === 'dark';
      });
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 768;
    this.isSmallScreen = window.innerWidth <= 770;
  }

  setTranslatedServices() {
    this.translate
      .get([
        'HOME.SERVICES.OIL_CHANGE',
        'HOME.SERVICES.TIRE_CHECK',
        'HOME.SERVICES.SUSPENSION_INSPECTION',
        'HOME.SERVICES.CARBURETOR_ADJUSTMENT',
        'HOME.SERVICES.BRAKE_CHECK',
        'HOME.SERVICES.TIRE_ROTATION',
        'HOME.SERVICES.FLUID_CHECK',
        'HOME.SERVICES.CLUTCH_ADJUSTMENT',
        'HOME.SERVICES.LIGHT_CHECK',
        'HOME.SERVICES.PREVENTIVE_MAINTENANCE',
        'HOME.SERVICES.PAD_CHANGE',
        'HOME.SERVICES.ELECTRONIC_DIAGNOSTIC',
        'HOME.SERVICES.CAR_WASH_WAX',
        'HOME.SERVICES.BATTERY_REPLACEMENT',
        'HOME.SERVICES.ENGINE_REPAIR',
      ])
      .subscribe((translations) => {
        this.services = [
          translations['HOME.SERVICES.OIL_CHANGE'],
          translations['HOME.SERVICES.TIRE_CHECK'],
          translations['HOME.SERVICES.SUSPENSION_INSPECTION'],
          translations['HOME.SERVICES.CARBURETOR_ADJUSTMENT'],
          translations['HOME.SERVICES.BRAKE_CHECK'],
          translations['HOME.SERVICES.TIRE_ROTATION'],
          translations['HOME.SERVICES.FLUID_CHECK'],
          translations['HOME.SERVICES.CLUTCH_ADJUSTMENT'],
          translations['HOME.SERVICES.LIGHT_CHECK'],
          translations['HOME.SERVICES.PREVENTIVE_MAINTENANCE'],
          translations['HOME.SERVICES.PAD_CHANGE'],
          translations['HOME.SERVICES.ELECTRONIC_DIAGNOSTIC'],
          translations['HOME.SERVICES.CAR_WASH_WAX'],
          translations['HOME.SERVICES.BATTERY_REPLACEMENT'],
          translations['HOME.SERVICES.ENGINE_REPAIR'],
        ];
      });
  }
  goToAssistant() {
    this.router.navigate(['/assistant']);
  }
}
