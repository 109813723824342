<!-- Modal -->
<div *ngIf = "showmodal" class="fixed inset-0 flex items-center justify-center z-50 modal-overlay bg-black bg-opacity-60" style="bottom:20%;">
    <form class="form bg-white rounded-md shadow-lg p-6 w-full max-w-md">
      <div class="flex justify-end">
        <span class="close cursor-pointer bg-red-500 text-white px-2 py-1 rounded hover:bg-primary" (click)="closeModal()">X</span>
      </div>
      <div class="info text-center mb-4">
        <div class="w-20 h-20 mx-auto animate__animated animate__delay-1s animate__bounceIn"><img src="../../../../assets/images/verify.png" alt=""></div>
        <span class="title text-2xl font-bold text-green-500 animate__animated animate__delay-1s animate__zoomInDown"> {{ 'CONFIRMATION.TITLE' | translate }} </span>
        <p class="description mt-2 animate__animated animate__delay-1s animate__zoomIn">{{ 'CONFIRMATION.SUBTITLE' | translate }}</p>
        
      </div>
      <div class="input-fields flex justify-center space-x-2 mb-4">
        <input type="tel" [(ngModel)]="code1" name="code1" maxlength="1" class="form-input text-center w-12 h-12 border rounded-md" />
        <input type="tel" [(ngModel)]="code2" name="code2" maxlength="1" class="form-input text-center w-12 h-12 border rounded-md" />
        <input type="tel" [(ngModel)]="code3" name="code3" maxlength="1" class="form-input text-center w-12 h-12 border rounded-md" />
        <input type="tel" [(ngModel)]="code4" name="code4" maxlength="1" class="form-input text-center w-12 h-12 border rounded-md" />
        <input type="tel" [(ngModel)]="code5" name="code5" maxlength="1" class="form-input text-center w-12 h-12 border rounded-md" />
        <input type="tel" [(ngModel)]="code6" name="code6" maxlength="1" class="form-input text-center w-12 h-12 border rounded-md" />
      </div>

      <p class="flex justify-center pb-6" *ngIf="countdown > 0">{{ countdown }} {{ 'CONFIRMATION.SECONDS' | translate }}</p>
      <p class="flex justify-center pb-6" *ngIf="countdown === 0">
        <a 
        (click)="resendCode()" 
        class="cursor-pointer underline flex text-2xl justify-center pb-4 animate__animated animate__delay-1s animate__zoomIn"
        [class.text-blue-500]="failedAttempts < maxAttempts"
        [class.text-gray-500]="failedAttempts >= maxAttempts"
        [class.cursor-not-allowed]="failedAttempts >= maxAttempts">
        {{ 'CONFIRMATION.RESEND' | translate }} 
      </a>
      </p>

      <div class="action-btns flex justify-center">
        <button (click)="verifyCode()" class="btn btn-primary bg-green-500 text-white py-2 px-4 rounded-md hover:bg-primary animate__animated animate__delay-1s animate__zoomInDown"> {{ 'CONFIRMATION.SUBMIT' | translate }} </button>
      </div>
    </form>
  </div>
  