import { Injectable } from '@angular/core';
import { AxiosService } from './../../../core/axios';
import { MessageChatDTO } from './../../../shared/dto/message-chat.dto';

@Injectable({
  providedIn: 'root',
})
export class AssistantService {
  constructor(private axiosService: AxiosService) {
    // do nothing
  }

  async createThread(message: MessageChatDTO): Promise<MessageChatDTO> {
    return await this.axiosService.post(`/assistant/createThread`, { data: { ...message } });
  }

  // Get the session by id
  getThread(chatId: string) {
    console.info(`chatId :> ${chatId}`);
  }

  // Send a message
  async sendMessage(message: MessageChatDTO): Promise<MessageChatDTO> {
    return this.axiosService.post(`/assistant/sendThread`, { data: { ...message } });
  }
}
