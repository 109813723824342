import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-platecity',
  templateUrl: './platecity.component.html',
  styleUrl: './platecity.component.css',
})
export class PlatecityComponent {
  codeArr: any = [];
  toggleCode = (name: string) => {
    if (this.codeArr.includes(name)) {
      // eslint-disable-next-line eqeqeq
      this.codeArr = this.codeArr.filter((d: string) => d != name);
    } else {
      this.codeArr.push(name);
    }
  };
  constructor(public storeData: Store<any>) {
    this.initStore();
  }
  store: any;
  async initStore() {
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }

  tab1: string = 'medellin';
  swiper1: any;
  items = ['carousel1.jpeg', 'carousel2.jpeg', 'carousel3.jpeg'];
}
