<!-- tabs -->
<div>
  <!-- Trigger -->
  <button type="button" class="btn bg-primary" (click)="modal18.open()">{{ 'VEHICLES.BUTTON' | translate }}</button>

  <!-- Modal -->
  <ngx-custom-modal #modal18 customClass="modal-top absolute" class="bg-azulOscuro dark:bg-white">
    <ng-template #modalHeader> <div>Pico & Placa</div> </ng-template>
    <ng-template #modalBody>
      <div class="flex overflow-x-scroll border-b border-white-light dark:border-[#191e3a]">
        <a
          href="javascript:;"
          class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
          [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'medellin' }"
          (click)="tab1 = 'medellin'"
        >
          Medellín
        </a>
        <a
          href="javascript:;"
          class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
          [ngClass]="{ '!border-white-light !border-b-white text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'bogota' }"
          (click)="tab1 = 'bogota'"
        >
          Bogota
        </a>
        <a
          href="javascript:;"
          class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
          [ngClass]="{ '!border-white-light !border-b-white text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'cali' }"
          (click)="tab1 = 'cali'"
        >
          Cali
        </a>
        <a
          href="javascript:;"
          class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
          [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'pereira' }"
          (click)="tab1 = 'pereira'"
        >
          Pereira
        </a>
        <a
          href="javascript:;"
          class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
          [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'barranquilla' }"
          (click)="tab1 = 'barranquilla'"
        >
          Barranquilla
        </a>
        <a
          href="javascript:;"
          class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
          [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'cartagena' }"
          (click)="tab1 = 'cartagena'"
        >
          Cartagena
        </a>
        <a
          href="javascript:;"
          class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
          [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'Pasto' }"
          (click)="tab1 = 'Pasto'"
        >
          Pasto
        </a>
        <a
          href="javascript:;"
          class="-mb-[1px] block border border-transparent p-3.5 py-2 !outline-none transition duration-300 hover:text-primary dark:hover:border-b-black"
          [ngClass]="{ '!border-white-light !border-b-white  text-primary dark:!border-[#191e3a] dark:!border-b-black': tab1.toLowerCase() === 'palmira' }"
          (click)="tab1 = 'palmira'"
        >
          Palmira
        </a>
        <a href="javascript:;" class="pointer-events-none -mb-[1px] block p-3.5 py-2 text-white-light dark:text-dark">otros</a>
      </div>
      <div class="flex-1 pt-5 text-sm">
        <div *ngIf="tab1.toLowerCase() === 'medellin'">
          <div class="flex items-start">
            <div class="h-20 w-20 flex-none ltr:mr-4 rtl:ml-4">
              <img
                src="../../../../../../assets/images/icon1.png"
                alt=""
                class="m-0 h-20 w-20 rounded-full object-cover ring-2 ring-[#ebedf2] dark:ring-white-dark"
              />
            </div>
            <div class="flex-auto">
              <div class="p-4 bg-azulOscuro dark:bg-white text-white dark:text-black shadow rounded-md">
                <h2 class="text-lg font-bold mb-2">Medellín</h2>
                <p>Hoy es Jueves 30/01/2025</p>
                <p>Horario: 5:00 a.m. a 8:00 p.m.</p>
                <p>Números restringidos: 4 y 8</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab1.toLowerCase() === 'bogota'">
          <div class="flex items-start">
            <div class="h-20 w-20 flex-none ltr:mr-4 rtl:ml-4">
              <img
                src="../../../../../../assets/images/icon1.png"
                alt=""
                class="m-0 h-20 w-20 rounded-full object-cover ring-2 ring-[#ebedf2] dark:ring-white-dark"
              />
            </div>
            <div class="flex-auto">
              <div class="p-4 shadow rounded-md bg-azulOscuro dark:bg-white text-white dark:text-black">
                <h2 class="text-lg font-bold mb-2">Bogota</h2>
                <p>Hoy es Jueves 30/01/2025</p>
                <p>Horario: 6:00 a.m. a 9:00 p.m.</p>
                <p>Números restringidos: 1, 2, 3, 4 y 5</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab1.toLowerCase() === 'cali'">
          <div class="flex items-start">
            <div class="h-20 w-20 flex-none ltr:mr-4 rtl:ml-4">
              <img
                src="../../../../../../assets/images/icon1.png"
                alt=""
                class="m-0 h-20 w-20 rounded-full object-cover ring-2 ring-[#ebedf2] dark:ring-white-dark"
              />
            </div>
            <div class="flex-auto">
              <div class="p-4 bg-azulOscuro dark:bg-white text-white dark:text-black shadow rounded-md">
                <h2 class="text-lg font-bold mb-2">Cali</h2>
                <p>Hoy es Jueves 30/01/2025</p>
                <p>Horario: 6:00 a.m. a 7:00 p.m.</p>
                <p>Números restringidos: 1 y 2</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab1.toLowerCase() === 'pereira'">
          <div class="flex items-start">
            <div class="h-20 w-20 flex-none ltr:mr-4 rtl:ml-4">
              <img
                src="../../../../../../assets/images/icon1.png"
                alt=""
                class="m-0 h-20 w-20 rounded-full object-cover ring-2 ring-[#ebedf2] dark:ring-white-dark"
              />
            </div>
            <div class="flex-auto">
              <div class="p-4 bg-azulOscuro dark:bg-white text-white dark:text-black shadow rounded-md">
                <h2 class="text-lg font-bold mb-2">Pereira</h2>
                <p>Hoy es Jueves 30/01/2025</p>
                <p>Horario: 6:00 a.m. a 8:00 p.m.</p>
                <p>Números restringidos: 6 y 7</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab1.toLowerCase() === 'barranquilla'">
          <div class="flex items-start">
            <div class="h-20 w-20 flex-none ltr:mr-4 rtl:ml-4">
              <img
                src="../../../../../../assets/images/icon1.png"
                alt=""
                class="m-0 h-20 w-20 rounded-full object-cover ring-2 ring-[#ebedf2] dark:ring-white-dark"
              />
            </div>
            <div class="flex-auto">
              <div class="p-4 bg-azulOscuro dark:bg-white text-white dark:text-black shadow rounded-md">
                <h2 class="text-lg font-bold mb-2">Barranquilla</h2>
                <h2 class="text-lg font-bold mb-2">Solo para taxis</h2>
                <p>Hoy es Jueves 30/01/2025</p>
                <p>Horario: 6:00 a.m. a 9:00 p.m.</p>
                <p>Números restringidos: 1 y 2</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab1.toLowerCase() === 'cartagena'">
          <div class="flex items-start">
            <div class="h-20 w-20 flex-none ltr:mr-4 rtl:ml-4">
              <img
                src="../../../../../../assets/images/icon1.png"
                alt=""
                class="m-0 h-20 w-20 rounded-full object-cover ring-2 ring-[#ebedf2] dark:ring-white-dark"
              />
            </div>
            <div class="flex-auto">
              <div class="p-4 bg-azulOscuro dark:bg-white text-white dark:text-black shadow rounded-md">
                <h2 class="text-lg font-bold mb-2">Cartagena</h2>
                <p>Hoy es Jueves 30/01/2025</p>
                <p>Mañana: de 7:00 a.m. a 9:00 a.m.</p>
                <p>Tarde: de 6:00 p.m. a 8:00 p.m.</p>
                <p>Números restringidos: 7 y 8</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab1.toLowerCase() === 'pasto'">
          <div class="flex items-start">
            <div class="h-20 w-20 flex-none ltr:mr-4 rtl:ml-4">
              <img
                src="../../../../../../assets/images/icon1.png"
                alt=""
                class="m-0 h-20 w-20 rounded-full object-cover ring-2 ring-[#ebedf2] dark:ring-white-dark"
              />
            </div>
            <div class="flex-auto">
              <div class="p-4 bg-azulOscuro dark:bg-white text-white dark:text-black shadow rounded-md">
                <h2 class="text-lg font-bold mb-2">Pasto</h2>
                <p>Hoy es Jueves 30/01/2025</p>
                <p>Horario: 7:30 a.m. a 7:00 p.m.</p>
                <p>Números restringidos: <span class="text-red-500">pendiente </span></p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab1.toLowerCase() === 'palmira'">
          <div class="flex items-start">
            <div class="h-20 w-20 flex-none ltr:mr-4 rtl:ml-4">
              <img
                src="../../../../../../assets/images/icon1.png"
                alt=""
                class="m-0 h-20 w-20 rounded-full object-cover ring-2 ring-[#ebedf2] dark:ring-white-dark"
              />
            </div>
            <div class="flex-auto">
              <div class="p-4 bg-azulOscuro dark:bg-white text-white dark:text-black shadow rounded-md">
                <h2 class="text-lg font-bold mb-2">Palmira</h2>
                <p>Hoy es Jueves 30/01/2025</p>
                <p>Horario: 6:00 a.m. a 7:00 p.m.</p>
                <p>Números restringidos: <span class="text-red-600">pendiente </span></p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tab1.toLowerCase() === 'disabled'">Disabled</div>
      </div>
    </ng-template>
    <ng-template #modalFooter>
      <button type="button" class="btn btn-outline-danger" (click)="modal18.close()">cerrar</button>
      <button type="button" class="btn btn-primary ltr:ml-4 rtl:mr-4" (click)="modal18.close()">Guardar</button>
    </ng-template>
  </ngx-custom-modal>
</div>
