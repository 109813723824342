      <div class="panel">
        <div class="mb-5">
          <h5 class="text-lg font-semibold dark:text-white-light animate__animated animate__slideInDown animate__delay-1s">{{ 'PROFILE.FINES' | translate }}</h5>
        </div>
        <div class="space-y-4">
          <div class="rounded border border-[#1b2e4b] dark:border-0  dark:bg-[#1b2e4b]">
            <div class="flex items-center justify-between p-4 py-2">
              <div class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-secondary-light text-secondary dark:bg-secondary dark:text-secondary-light">
                <icon-tether />
              </div>
              <div class="flex flex-auto items-start justify-between font-semibold ltr:ml-4 rtl:mr-4">
                <h6 class="text-[13px] text-white-dark dark:text-white-dark">
                  {{ 'PROFILE.Murder' | translate }} <span class="block text-base text-[#515365] dark:text-white-light">$50.200.000</span>
                </h6>
                <p class="text-secondary ltr:ml-auto rtl:mr-auto">90%</p>
              </div>
            </div>
          </div>
          <div class="rounded border border-[#1b2e4b] dark:border-0 dark:bg-[#1b2e4b]">
            <div class="flex items-center justify-between p-4 py-2">
              <div class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-info-light text-info dark:bg-info dark:text-info-light">
                <icon-tag />
              </div>
              <div class="flex flex-auto items-start justify-between font-semibold ltr:ml-4 rtl:mr-4">
                <h6 class="text-[13px] text-white-dark dark:text-white-dark">
                  {{ 'PROFILE.traffic' | translate }} <span class="block text-base text-[#515365] dark:text-white-light">$1.000.000</span>
                </h6>
                <p class="text-info ltr:ml-auto rtl:mr-auto">65%</p>
              </div>
            </div>
          </div>
          <div class="rounded border border-[#1b2e4b] dark:border-0 dark:bg-[#1b2e4b]">
            <div class="flex items-center justify-between p-4 py-2">
              <div class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-warning-light text-warning dark:bg-warning dark:text-warning-light">
                <icon-credit-card />
              </div>
              <div class="flex flex-auto items-start justify-between font-semibold ltr:ml-4 rtl:mr-4">
                <h6 class="text-[13px] text-white-dark dark:text-white-dark">
                  {{ 'PROFILE.DOCUMENTS' | translate }} <span class="block text-base text-[#515365] dark:text-white-light">$3.000.000</span>
                </h6>
                <p class="text-warning ltr:ml-auto rtl:mr-auto">80%</p>
              </div>
            </div>
          </div>
        </div>
        