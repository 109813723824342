import { Component } from '@angular/core';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrl: './counter.component.css',
})
export class CounterComponent {
  codeArr: any = [];
  toggleCode = (name: string) => {
    if (this.codeArr.includes(name)) {
      // eslint-disable-next-line eqeqeq
      this.codeArr = this.codeArr.filter((d: string) => d != name);
    } else {
      this.codeArr.push(name);
    }
  };

  constructor() {
    // do nothing
  }
}
