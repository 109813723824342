import { ErrorHandler, Injectable } from '@angular/core';
import axios from 'axios';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (error instanceof ErrorEvent) {
      console.error('[ErrorEvent] - Client-side error:', error.message);
    } else if (error instanceof TypeError) {
      console.error('[TypeError]');
    } else if (error instanceof RangeError) {
      console.error('[RangeError] - ', error.message);
    } else if (error instanceof EvalError) {
    } else if (typeof error === 'string') {
      console.error('[ERROR] - ', error);
    } else if (axios.isAxiosError(error)) {
      console.error('[AxiosError] - Server-side error:', error.message);
    } else {
      console.error('[UndefinedError]:', error);
    }
    // TODO: send error to log storage o cloud
  }
}
